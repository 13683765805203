import React from 'react'
import {
  ActionButton,
  Box,
  Flex,
  HStack,
  IconButton,
  Tag,
  Text,
  Token,
} from '@revolut/ui-kit'
import { PublishingStatuses } from '@src/interfaces/jobPosting'
import { useOnPublishClick } from '@src/pages/OnboardingChecklistV2/Jobs/utils'
import { getLocationDescriptor } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'
import { Link } from 'react-router-dom'
import { pathToUrl } from '@src/utils/router'

type Props = {
  title: string
  status?: PublishingStatuses
  onRefresh: () => void
  id?: number
}

export const PreviewBanner = ({ id, title, status, onRefresh }: Props) => {
  const { onPublishClick, publishLoading } = useOnPublishClick(onRefresh)

  return (
    <Box bg={Token.color.black} py="s-20" px="s-40" color={Token.color.white} mb="s-16">
      <Flex justifyContent="space-between" alignItems="center">
        <HStack gap="s-16" align="center">
          <IconButton
            useIcon="Cross"
            onClick={() => {
              window.close()
            }}
          />
          <Text variant="secondary">{title}</Text>

          {status && (
            <>
              {status === PublishingStatuses.unpublished ? (
                <Tag variant="status" useIcon="EyeHide">
                  Unpublished
                </Tag>
              ) : (
                <Tag color={Token.color.green} useIcon="Globe">
                  Published
                </Tag>
              )}
            </>
          )}
        </HStack>

        {id && (
          <HStack gap="s-16">
            <ActionButton
              variant="white"
              useIcon="Pencil"
              use={Link}
              // @ts-expect-error object works fine here, but UI kit expects string
              to={getLocationDescriptor(
                pathToUrl(ROUTES.ONBOARDING_CHECKLIST_V2.JOBS_EDIT, { id }),
              )}
            >
              Edit
            </ActionButton>
            {status === PublishingStatuses.unpublished ? (
              <ActionButton
                variant="accent"
                useIcon="EyeShow"
                pending={publishLoading}
                onClick={() => onPublishClick([id], PublishingStatuses.fully_published)}
              >
                Publish
              </ActionButton>
            ) : (
              <ActionButton
                variant="negative"
                useIcon="EyeHide"
                pending={publishLoading}
                onClick={() => onPublishClick([id], PublishingStatuses.unpublished)}
              >
                Unpublish
              </ActionButton>
            )}
          </HStack>
        )}
      </Flex>
    </Box>
  )
}
