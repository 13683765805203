import React from 'react'
import { connect } from 'lape'
import Form from '@src/features/Form/Form'
import { ROUTES } from '@src/constants/routes'
import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import OfferTemplateGeneral from './General'
import { Route, Switch } from 'react-router-dom'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { OfferTemplateInterface } from '@src/interfaces/offerTemplates'
import { offerTemplateRequest } from '@src/api/offerTemplates'

const OfferTemplate = () => {
  const { values } = useLapeContext<OfferTemplateInterface>()
  const backUrl = ROUTES.APPS.OFFERS.OFFER_TEMPLATES

  return (
    <PageWrapper>
      <PageHeader
        pb="s-8"
        title={values.id ? values.name : 'Create a new offer template'}
        backUrl={backUrl}
      />
      <Switch>
        <Route exact path={ROUTES.FORMS.OFFER_TEMPLATE.GENERAL}>
          <OfferTemplateGeneral />
        </Route>
      </Switch>
    </PageWrapper>
  )
}

export default connect(() => (
  <Form api={offerTemplateRequest}>
    <OfferTemplate />
  </Form>
))
