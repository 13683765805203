import React, { useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import {
  Box,
  Color,
  Flex,
  IconButton,
  IconButtonProps,
  Text,
  TextSkeleton,
  Tooltip,
  useTooltip,
} from '@revolut/ui-kit'
import { Controls, Retry } from '@revolut/icons'
import pluralize from 'pluralize'

import { ColumnCellInterface, RowInterface, Stats } from '@src/interfaces/data'
import { useStoredTableSettings, useTableReturnType } from './hooks'
import HeaderCell, { HEADER_CELL_WRAPPER, HeaderCellWrapper } from './HeaderCell'
import { ROUTES } from '@src/constants/routes'
import Row from './Row'
import { bulkNormalizeWidth, generateKey } from '@src/utils/table'
import { colorGetter } from '@src/styles/colors'
import { Virtuoso } from 'react-virtuoso'
import SortableList from '@components/SortableList/SortableList'
import VirtualList from '@components/VirtualList/VirtualList'
import { RowHeight, TableTypes } from '@src/interfaces/table'
import { FormError } from '@src/features/Form/LapeForm'
import TableLoader from '@components/Table/TableLoader'
import { SelectAllCell } from '@components/Table/AdvancedCells/SelectCell/SelectCell'
import { cellWrapperRole } from './Cell'
import { LocalStorageKeys } from '@src/store/auth/types'
import Icon from '@components/Icon/Icon'
import TableSettings from '@components/Table/TableSettings'
import { TableNames } from '@src/constants/table'
import { getCellsBasedOnSettings } from '@components/Table/utils'
import { TableSettingsInterface } from '@src/interfaces/tableSettings'
import { InternalRedirect } from '@src/components/InternalLink/InternalRedirect'
import { RowSeparator } from '@components/Table/RowSeparator'

export interface TableProps<T, S> extends Partial<useTableReturnType<T, S>> {
  name: TableNames
  onRowClick?: (data: T, parentIndexes: number[]) => void
  row: RowInterface<T>
  noDataMessage?: React.ReactNode
  type?: TableTypes
  count: number
  data: T[]
  noAutoResize?: boolean
  cellErrors?: (FormError<T> | undefined)[]
  stickyHeaderTop?: number
  noReset?: boolean
  width?: number
  useWindowScroll?: boolean
  hiddenCells?: { [key in string]?: boolean }
  orderingMode?: boolean
  onChangeOrder?: (
    ids: (number | string)[],
    activeIndex: number,
    targetIndex: number,
  ) => void
  setSelectedOrderingIds?: (ids: (number | string)[]) => void
  selectedOrderingIds?: (number | string)[]
  activeOrderingRow?: number | null
  idPath?: string
  disabledFiltering?: boolean
  disabledSorting?: boolean
  renderSeparatorContent?: (data: T, index: number) => React.ReactNode
  childrenAlwaysOpen?: boolean
  childrenOpenByDefault?: boolean
  emptyState?: React.ReactNode
  expandableType?: 'chevron' | 'plus'
  /** Locks first column in place and makes text slightly bolder */
  lockFirstColumn?: boolean
  lockLastColumn?: boolean
  rowHeight?: RowHeight
  dataType?: string
  hideCount?: boolean
  selectedCount?: number
  hideCountAndButtonSection?: boolean
  pendingDataType?: boolean
  enableSettings?: boolean
  tableSettings?: TableSettingsInterface
  renderCount?: (count: number, selectedCount?: number) => React.ReactNode
  useFetchedChildren?: boolean
}

const MatrixTableCss = css`
  border: none;
`

const AdjustableCss = css`
  min-height: auto;
  border: none;
`

export const SHOW_LEFT_SHADOW_ATTR = 'data-show-left-shadow'
export const SHOW_RIGHT_SHADOW_ATTR = 'data-show-right-shadow'
export const SHOW_LEFT_LOCK_SHADOW_ATTR = 'data-show-left-lock-shadow'
export const SHOW_RIGHT_LOCK_SHADOW_ATTR = 'data-show-right-lock-shadow'
export const LOCK_FIRST_COLUMN_ATTR = 'data-lock-first-column'
export const LOCK_LAST_COLUMN_ATTR = 'data-lock-last-column'

const getShadowStyle = (direction: 'left' | 'right') => css`
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  ${direction}: 0;
  width: 24px;
  background: linear-gradient(
    ${direction === 'left' ? '270deg' : '90deg'},
    rgba(0, 0, 0, 0) 0%,
    rgba(25, 28, 31, 0.02) 50%,
    rgba(25, 28, 31, 0.04) 75%,
    rgba(25, 28, 31, 0.0625) 100%
  );
  pointer-events: none;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  z-index: ${({ theme }) => theme.zIndex.aboveMain + 2};
`

const TableContainer = styled.div<{ type: TableTypes; filtering?: boolean }>`
  border: 1px solid #e8ebef;
  overflow-x: auto;
  position: relative;
  max-height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  ${({ type }) => type === TableTypes.Adjustable && AdjustableCss};
  ${({ type }) => type === TableTypes.Matrix && MatrixTableCss};
  ${({ filtering }) =>
    filtering &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `};
`

const NoDataRow = styled.div`
  width: 400px;
  min-height: 41px;
`
const NoData = styled.div`
  color: ${colorGetter(Color.GREY_TONE_50)};
  background-color: ${props => props.theme.colors['widget-background']};
  padding: 10px 8px 10px 16px;
  position: absolute;
  width: 100%;
`

const TableButtons = styled(Flex)`
  opacity: 0;
  gap: 8px;
  z-index: ${({ theme }) => theme.zIndex.aboveMain + 1};
  margin-left: auto;
`

const Header = styled.div<{
  useWindowScroll?: boolean
  top?: number
  width?: number
}>`
  overflow-x: hidden;
  width: ${({ width }) => (width ? `${width}px` : undefined)};
  display: flex;
  border: 1px solid ${colorGetter(Color.GREY_TONE_8)};
  z-index: ${({ theme }) => theme.zIndex.aboveMain + 2};
  position: ${({ useWindowScroll }) => (useWindowScroll ? 'sticky' : 'relative')};
  top: ${({ top }) => top ?? 0}px;
  background: ${({ theme }) => theme.colors['grey-tone-2']};
  flex: 0 0 auto;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow-x: auto;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`

const HeaderCellSelector = `.${HEADER_CELL_WRAPPER}`

/**
 * There is some React or browser bug. When you click this button parent state is updated, and the `onMouseLeave`
 * event never fires for the button, so the tooltip does not get removed. This fixes it
 * https://github.com/facebook/react/issues/4492#issuecomment-426356566
 */
const FixOnMouseLeaveCss = css`
  & div {
    pointer-events: none;
  }
`

interface TableButtonProps extends IconButtonProps {
  tooltipText: string
}

const TableButton = ({ color, tooltipText, ...props }: TableButtonProps) => {
  const tooltip = useTooltip()
  const timer = useRef<NodeJS.Timeout>()

  const anchorProps = tooltip.getAnchorProps<HTMLElement>()

  return (
    <>
      <IconButton
        tapArea={22}
        color={color || Color.GREY_50_50}
        {...props}
        {...anchorProps}
        onMouseEnter={() => {
          timer.current = setTimeout(() => {
            anchorProps.onMouseEnter()
          }, 750)
        }}
        onMouseLeave={() => {
          if (timer.current) {
            clearTimeout(timer.current)
          }
          anchorProps.onMouseLeave()
        }}
        css={FixOnMouseLeaveCss}
      />
      <Tooltip {...tooltip.getTargetProps()} placement="top">
        {tooltipText}
      </Tooltip>
    </>
  )
}

const WrapperCss = css`
  isolation: isolate;

  &:hover ${TableButtons} {
    opacity: 1;
  }
`

const TableWrapperCss = css`
  position: relative;

  &[${SHOW_LEFT_SHADOW_ATTR}] {
    &::before {
      ${getShadowStyle('left')}
    }
  }
  &[${SHOW_RIGHT_SHADOW_ATTR}] {
    &::after {
      ${getShadowStyle('right')}
    }
  }

  & [role=${cellWrapperRole}]:first-child {
    border-left: 1px solid ${props => props.theme.colors['grey-tone-8']};
  }

  &[${LOCK_FIRST_COLUMN_ATTR}=true] [role=${cellWrapperRole}]:first-child,
  &[${LOCK_FIRST_COLUMN_ATTR}=true] ${HeaderCellSelector}:first-child {
    font-weight: 500;
    position: sticky;
    left: 0;
    z-index: ${({ theme }) => theme.zIndex.aboveMain + 1};
  }

  &[${LOCK_LAST_COLUMN_ATTR}=true] [role=${cellWrapperRole}]:last-child,
  &[${LOCK_LAST_COLUMN_ATTR}=true] ${HeaderCellSelector}:last-child {
    position: sticky;
    right: 0;
    z-index: ${({ theme }) => theme.zIndex.aboveMain + 1};
  }

  &[${SHOW_LEFT_LOCK_SHADOW_ATTR}] [role=${cellWrapperRole}]:first-child::after,
  &[${SHOW_LEFT_LOCK_SHADOW_ATTR}] ${HeaderCellSelector}:first-child::after {
    ${getShadowStyle('left')}
    right: -24px;
    left: unset;
    border-radius: 0;
  }

  &[${SHOW_RIGHT_LOCK_SHADOW_ATTR}] [role=${cellWrapperRole}]:last-child::before,
  &[${SHOW_RIGHT_LOCK_SHADOW_ATTR}] ${HeaderCellSelector}:last-child::before {
    ${getShadowStyle('right')}
    left: -24px;
    border-radius: 0;
  }
`

const rowHeightToWidthMultiplier = {
  small: 0.7,
  medium: 0.85,
  large: 1,
}

const Table = <T extends { [prop: string]: any; id?: number | string }, S = Stats>({
  name,
  loading,
  nextPageLoading,
  data,
  row,
  onSortChange,
  onFilterChange,
  resetFiltersAndSorting,
  fetchSelectors,
  onRowClick,
  useWindowScroll,
  stickyHeaderTop,
  sortBy,
  filterBy,
  fetchNextPage,
  fetchChildren,
  noDataMessage,
  type = TableTypes.Form,
  width,
  noAutoResize,
  cellErrors,
  noReset,
  count,
  hiddenCells,
  orderingMode,
  onChangeOrder,
  setSelectedOrderingIds,
  selectedOrderingIds,
  activeOrderingRow,
  idPath = 'id',
  disabledFiltering,
  disabledSorting,
  renderSeparatorContent,
  childrenAlwaysOpen = false,
  emptyState,
  expandableType,
  lockFirstColumn = true,
  lockLastColumn = false,
  rowHeight: initialRowHeight,
  dataType = 'Item',
  pendingDataType = false,
  hideCount,
  selectedCount,
  hideCountAndButtonSection,
  enableSettings = true,
  tableSettings: initialTableSettings,
  renderCount,
  useFetchedChildren = false,
  childrenOpenByDefault = false,
}: TableProps<T, S>) => {
  // used to trigger re-render by children
  const [, setForceRender] = useState(0)
  const [openSettings, setOpenSettings] = useState(false)
  const [rowHeight, setRowHeight] = useState(() => {
    const localStorageRowHeight = localStorage.getItem(LocalStorageKeys.TABLE_ROW_HEIGHT)
    const safeLocalStorageRowHeight =
      localStorageRowHeight === 'small' ||
      localStorageRowHeight === 'medium' ||
      localStorageRowHeight === 'large'
        ? localStorageRowHeight
        : 'small'
    return initialRowHeight || safeLocalStorageRowHeight
  })
  const wrapperRef = useRef<HTMLDivElement | null>(null)
  const tableWrapper = useRef<HTMLDivElement | null>(null)
  const headerWrapper = useRef<HTMLDivElement | null>(null)
  let ignoreHeaderScroll = false
  let ignoreTableScroll = false

  const handleTableShadows = () =>
    requestAnimationFrame(() => {
      if (tableWrapper.current) {
        const scroll = tableWrapper.current.scrollLeft

        if (wrapperRef.current) {
          const leftShadowAttr = lockFirstColumn
            ? SHOW_LEFT_LOCK_SHADOW_ATTR
            : SHOW_LEFT_SHADOW_ATTR

          if (scroll > 0) {
            wrapperRef.current.setAttribute(leftShadowAttr, 'true')
          } else {
            wrapperRef.current.removeAttribute(leftShadowAttr)
          }

          const wrapperWidth = wrapperRef?.current?.clientWidth
          const headerWidth = headerWrapper?.current?.scrollWidth

          if (
            headerWidth &&
            wrapperWidth < headerWidth &&
            scroll < headerWidth - wrapperWidth
          ) {
            wrapperRef.current.setAttribute(SHOW_RIGHT_SHADOW_ATTR, 'true')
          } else {
            wrapperRef.current.removeAttribute(SHOW_RIGHT_SHADOW_ATTR)
          }

          if (lockLastColumn && headerWidth) {
            const rightShadowAttr = lockLastColumn
              ? SHOW_RIGHT_LOCK_SHADOW_ATTR
              : SHOW_RIGHT_SHADOW_ATTR

            if (wrapperWidth < headerWidth && scroll < headerWidth - wrapperWidth) {
              wrapperRef.current.setAttribute(rightShadowAttr, 'true')
              wrapperRef.current.removeAttribute(SHOW_RIGHT_SHADOW_ATTR)
            } else {
              wrapperRef.current.removeAttribute(rightShadowAttr)
            }
          }
        }
      }
    })

  const handleTableScroll = () => {
    const ignore = ignoreTableScroll
    ignoreTableScroll = false
    if (headerWrapper.current && tableWrapper.current && !ignore) {
      ignoreHeaderScroll = true
      headerWrapper.current.scrollLeft = tableWrapper.current.scrollLeft
      handleTableShadows()
    }
  }

  const handleHeaderScroll = () => {
    const ignore = ignoreHeaderScroll
    ignoreHeaderScroll = false
    if (headerWrapper.current && tableWrapper.current && !ignore) {
      ignoreTableScroll = true
      tableWrapper.current.scrollLeft = headerWrapper.current.scrollLeft
      handleTableShadows()
    }
  }

  useEffect(() => {
    if (tableWrapper?.current && headerWrapper?.current && !loading) {
      tableWrapper.current.addEventListener('scroll', handleTableScroll)
      headerWrapper.current.addEventListener('scroll', handleHeaderScroll)
      handleTableShadows()
    }
    return () => {
      tableWrapper.current?.removeEventListener('scroll', handleTableScroll)
      headerWrapper.current?.removeEventListener('scroll', handleHeaderScroll)
    }
  }, [tableWrapper, headerWrapper, loading])

  const filteredCells = row.cells.filter(cell => !hiddenCells?.[cell.idPoint])
  const storedTableSettings = useStoredTableSettings(name, initialTableSettings)
  const [tableSettings, setTableSettings] = useState(storedTableSettings)

  const columnSettings = getCellsBasedOnSettings(
    filteredCells,
    tableSettings,
    !enableSettings,
  )

  if (!row?.cells) {
    return <InternalRedirect to={ROUTES.MAIN} />
  }

  const forceRender = () => {
    setForceRender(Math.random())
  }

  const visibleCellsAdjustedWidths = noAutoResize
    ? columnSettings.visible
    : (bulkNormalizeWidth(
        columnSettings.visible.map(cell => ({
          ...cell,
          width: cell.width * rowHeightToWidthMultiplier[rowHeight],
        })),
        type,
        width,
        lockFirstColumn,
      ) as ColumnCellInterface<T>[])

  const changeColumn = (idPoint: string, propName: string, value: any) => {
    const column = row.cells.find(r => r.idPoint === idPoint)
    /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
    column![propName] = value
    forceRender()
  }

  const renderList = () => {
    if (orderingMode && onChangeOrder && selectedOrderingIds) {
      return (
        <SortableList<T>
          count={count}
          onLoadMore={fetchNextPage}
          onRowClick={onRowClick}
          key={`${filterBy?.map(fil => fil.columnName).join()}${sortBy
            ?.map(sort => sort.sortBy + sort.direction)
            .join()}`}
          data={data}
          renderItem={(index, style) => {
            const rowData = data[index]
            if (rowData.isSeparator) {
              return renderSeparatorContent ? (
                <RowSeparator
                  key={`row_${generateKey(data[index], idPath)}`}
                  rowHeight={rowHeight}
                  rowWidth={width}
                  separatorContent={renderSeparatorContent(rowData, index)}
                />
              ) : null
            }
            return (
              <Row<T>
                key={`row_${generateKey(data[index], idPath)}`}
                data={data[index]}
                row={row}
                cellErrors={cellErrors}
                forceRender={forceRender}
                onRowClick={onRowClick}
                visibleCells={visibleCellsAdjustedWidths}
                noChildrenRequest={row?.noChildrenRequest}
                onFilterChange={onFilterChange}
                fetchChildren={fetchChildren}
                parentIndexes={[index]}
                type={type}
                style={style}
                idPath={idPath}
                childrenAlwaysOpen={childrenAlwaysOpen}
                childrenOpenByDefault={childrenOpenByDefault}
                expandableType={expandableType}
                containerWidth={tableWrapper.current?.scrollWidth}
                rowHeight={rowHeight}
              />
            )
          }}
          onUpdate={onChangeOrder}
          selectedIds={selectedOrderingIds}
          onChangeSelected={setSelectedOrderingIds}
          activeOrderingRow={activeOrderingRow}
          useWindowScroll={useWindowScroll}
        />
      )
    }

    if (useWindowScroll) {
      return (
        <Virtuoso<T>
          useWindowScroll
          endReached={fetchNextPage}
          data={data}
          overscan={1000}
          itemContent={(index, rowData) => {
            if (rowData.isSeparator) {
              return renderSeparatorContent ? (
                <RowSeparator
                  key={`row_${generateKey(rowData, idPath)}`}
                  rowHeight={rowHeight}
                  rowWidth={width}
                  separatorContent={renderSeparatorContent(rowData, index)}
                />
              ) : null
            }
            return (
              <Row<T>
                key={`row_${generateKey(rowData, idPath)}`}
                data={rowData}
                row={row}
                cellErrors={cellErrors}
                forceRender={forceRender}
                visibleCells={visibleCellsAdjustedWidths}
                onRowClick={onRowClick}
                noChildrenRequest={row?.noChildrenRequest}
                onFilterChange={onFilterChange}
                fetchChildren={fetchChildren}
                parentIndexes={[index]}
                type={type}
                idPath={idPath}
                childrenAlwaysOpen={childrenAlwaysOpen}
                childrenOpenByDefault={childrenOpenByDefault}
                expandableType={expandableType}
                containerWidth={tableWrapper.current?.scrollWidth}
                rowHeight={rowHeight}
                useFetchedChildren={useFetchedChildren}
              />
            )
          }}
        />
      )
    }

    return (
      <VirtualList
        count={count}
        onLoadMore={fetchNextPage}
        key={`${filterBy?.map(fil => fil.columnName).join()}${sortBy
          ?.map(sort => sort.sortBy + sort.direction)
          .join()}`}
      >
        {data.map((rowData, rowIndex) => (
          <Row<T>
            key={`row_${generateKey(rowData, idPath)}`}
            data={rowData}
            row={row}
            cellErrors={cellErrors}
            forceRender={forceRender}
            visibleCells={visibleCellsAdjustedWidths}
            onRowClick={onRowClick}
            noChildrenRequest={row?.noChildrenRequest}
            onFilterChange={onFilterChange}
            fetchChildren={fetchChildren}
            parentIndexes={[rowIndex]}
            type={type}
            idPath={idPath}
            childrenAlwaysOpen={childrenAlwaysOpen}
            childrenOpenByDefault={childrenOpenByDefault}
            expandableType={expandableType}
            containerWidth={tableWrapper.current?.scrollWidth}
            rowHeight={rowHeight}
            useFetchedChildren={useFetchedChildren}
          />
        ))}
      </VirtualList>
    )
  }

  const renderTableBody = () => {
    if (data?.length) {
      return renderList()
    }

    if (loading) {
      return null
    }

    if (emptyState) {
      return emptyState
    }

    return (
      <NoDataRow>
        <NoData>{noDataMessage || 'No data'}</NoData>
      </NoDataRow>
    )
  }

  const onRowHeightChange = (height: RowHeight) => {
    localStorage.setItem(LocalStorageKeys.TABLE_ROW_HEIGHT, height)
    setRowHeight(height)
    handleTableShadows()
  }
  const countLabel = pendingDataType ? (
    <TextSkeleton width="200px" />
  ) : (
    (renderCount && renderCount(count, selectedCount)) || (
      <Text color={Color.GREY_TONE_50}>
        Showing {count} {pluralize(dataType, count)}
        {selectedCount
          ? ` - selected ${selectedCount} ${pluralize(dataType, selectedCount)}`
          : ''}
      </Text>
    )
  )

  return (
    <>
      <Box css={WrapperCss}>
        {hideCountAndButtonSection ? null : (
          <Flex mb="s-8">
            {!hideCount && countLabel}
            <TableButtons>
              <Flex gap="s-4">
                <TableButton
                  useIcon={() => <Icon type="TableLarge" />}
                  aria-label="Large table rows"
                  onClick={() => onRowHeightChange('large')}
                  color={rowHeight === 'large' ? Color.GREY_TONE_50 : undefined}
                  tooltipText="Large rows"
                />
                <TableButton
                  useIcon={() => <Icon type="TableMedium" />}
                  aria-label="Medium table rows"
                  onClick={() => onRowHeightChange('medium')}
                  color={rowHeight === 'medium' ? Color.GREY_TONE_50 : undefined}
                  tooltipText="Medium rows"
                />
                <TableButton
                  useIcon={() => <Icon type="TableSmall" />}
                  aria-label="Small table rows"
                  onClick={() => onRowHeightChange('small')}
                  color={rowHeight === 'small' ? Color.GREY_TONE_50 : undefined}
                  tooltipText="Small rows"
                />
              </Flex>

              {enableSettings ? (
                <TableButton
                  useIcon={Controls}
                  aria-label="Column settings"
                  onClick={() => setOpenSettings(true)}
                  tooltipText="Column settings"
                />
              ) : null}

              {!!(
                sortBy?.length || filterBy?.filter(sort => !sort.nonResettable).length
              ) &&
                !noReset &&
                !orderingMode && (
                  <TableButton
                    aria-label="Reset filters"
                    onClick={() => resetFiltersAndSorting && resetFiltersAndSorting()}
                    useIcon={Retry}
                    tooltipText="Reset filters"
                  />
                )}
            </TableButtons>
          </Flex>
        )}

        <Box
          width="100%"
          css={TableWrapperCss}
          ref={wrapperRef}
          {...{
            [LOCK_FIRST_COLUMN_ATTR]: lockFirstColumn,
            [LOCK_LAST_COLUMN_ATTR]: lockLastColumn,
          }}
        >
          <Header
            width={width}
            ref={headerWrapper}
            useWindowScroll={useWindowScroll}
            top={stickyHeaderTop}
          >
            {visibleCellsAdjustedWidths.map((cell, index) => {
              const sort = sortBy?.find(sor => sor.sortBy === cell.sortKey)
              const filter = filterBy?.find(fil => fil.columnName === cell.filterKey)

              if (cell.renderCustomHeader) {
                return (
                  <HeaderCellWrapper
                    type={type}
                    width={cell.width}
                    rowHeight={rowHeight}
                    key={cell.title + index}
                  >
                    {cell.renderCustomHeader()}
                  </HeaderCellWrapper>
                )
              }
              if (cell.headerType === 'select_all') {
                return (
                  <HeaderCellWrapper
                    type={type}
                    width={cell.width}
                    rowHeight={rowHeight}
                    key={cell.title + index}
                  >
                    <Flex alignItems="center" width="100%" pl="s-16">
                      <SelectAllCell />
                    </Flex>
                  </HeaderCellWrapper>
                )
              }
              return (
                <HeaderCell
                  key={cell.title + index}
                  cell={cell}
                  noResize={cell.noResize}
                  fetchSelectors={fetchSelectors}
                  onSortChange={onSortChange}
                  sortBy={sort && sort.direction}
                  onFilterChange={onFilterChange}
                  filterBy={filter && filter.filters}
                  changeColumn={changeColumn}
                  centered={cell.headerCentered}
                  type={type}
                  disabled={disabledFiltering}
                  disabledSorting={disabledSorting}
                  rowHeight={rowHeight}
                />
              )
            })}
          </Header>
          <TableContainer
            filtering={loading && !nextPageLoading}
            type={type}
            ref={tableWrapper}
          >
            {renderTableBody()}

            <TableLoader
              loading={loading}
              containerWidth={tableWrapper.current?.scrollWidth}
              rowHeight={rowHeight}
            />
          </TableContainer>
        </Box>
      </Box>
      {openSettings ? (
        <TableSettings
          name={name}
          isOpen
          onClose={() => setOpenSettings(false)}
          onSave={settings => setTableSettings(settings)}
          columnSettings={columnSettings}
        />
      ) : null}
    </>
  )
}

export default Table
