import React, { useState } from 'react'
import isUndefined from 'lodash/isUndefined'
import { useGetReviewCycleByOffset } from '@src/api/reviewCycles'
import { useQuery } from '@src/utils/queryParamsHooks'
import { refreshRoadmap } from '@src/api/roadmaps'
import { FilterByInterface, SORT_DIRECTION } from '@src/interfaces/data'
import { DepartmentInterface } from '@src/interfaces/deparment'
import { TabBar } from '@revolut/ui-kit'

import RoadmapDeadlineBanner from '@src/features/Banners/RoadmapDeadlineBanner'
import { EntityTypes } from '@src/constants/api'
import { PermissionTypes } from '@src/store/auth/types'
import { TableNames } from '@src/constants/table'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { RoadmapTab } from '@src/features/Roadmaps/RoadmapTab'
import { useGetRoadmapSettings } from '@src/api/settings'
import { teamNameColumn } from '@src/constants/columns/team'
import {
  roadmapDueDate,
  roadmapEpicUrlColumn,
  roadmapGenericNameColumn,
  roadmapJiraLabelColumn,
  roadmapOwnerColumn,
  roadmapPriorityColumn,
  roadmapProgressColumn,
  roadmapResolutionAtColumn,
  roadmapStartDate,
  roadmapStatusColumn,
} from '@src/constants/columns/roadmap'
import { useIsNewOrgLayout } from '@src/pages/Team/helpers'

export enum RoadmapType {
  Department = 'department',
  Team = 'team',
}

interface StructureProps {
  data: DepartmentInterface
}

interface Tab {
  id: RoadmapType
  title: string
}

export const tabs: Readonly<Tab[]> = [
  {
    id: RoadmapType.Department,
    title: 'Department',
  },
  {
    id: RoadmapType.Team,
    title: 'Teams',
  },
]

export const roadmapSorting = [
  {
    sortBy: 'team__name',
    direction: SORT_DIRECTION.DESC,
  },
  {
    sortBy: 'priority',
    direction: SORT_DIRECTION.DESC,
  },
  {
    sortBy: 'due_date',
    direction: SORT_DIRECTION.DESC,
  },
]

export const getRoadmapFilters = (
  data: DepartmentInterface,
  type?: RoadmapType,
): FilterByInterface[] =>
  [
    {
      filters: [{ name: data.name, id: data.id }],
      columnName:
        type === RoadmapType.Department ? 'department__id' : 'team__department__id',
      nonResettable: true,
    },
    type === RoadmapType.Department
      ? null
      : {
          filters: [{ name: 'active', id: 'active' }],
          columnName: 'team__status',
          nonResettable: true,
        },
    {
      filters: [{ name: `0`, id: 0 }],
      columnName: 'review_cycle__offset',
      nonResettable: true,
    },
    {
      columnName: 'goals_related',
      filters: [{ id: 'false', name: 'false' }],
      nonResettable: true,
    },
  ].filter(Boolean) as FilterByInterface[]

export const Roadmap = ({ data }: StructureProps) => {
  const { query } = useQuery()
  const [selectedTab, setSelectedTab] = useState(tabs[0].id)
  const isNewLayout = useIsNewOrgLayout()

  const cycleOffset = query.review_cycle__offset || 0
  const { data: reviewCycle } = useGetReviewCycleByOffset(cycleOffset)
  const { data: roadmapSettings } = useGetRoadmapSettings()
  const triggerRefresh = async () => {
    await refreshRoadmap(
      `DEPARTMENT-${data.id}`,
      !isUndefined(reviewCycle) ? { id: reviewCycle.id as number } : undefined,
    )
  }
  const row = {
    cells: [
      selectedTab === RoadmapType.Department
        ? null
        : {
            ...teamNameColumn,
            width: 180,
          },
      {
        ...roadmapGenericNameColumn,
        width: 500,
      },
      {
        ...roadmapPriorityColumn,
        width: 100,
      },
      {
        ...roadmapProgressColumn,
        width: 170,
      },
      {
        ...roadmapStatusColumn,
        width: 120,
      },
      {
        ...roadmapStartDate,
        width: 130,
      },
      {
        ...roadmapDueDate,
        width: 130,
      },
      {
        ...roadmapResolutionAtColumn,
        width: 100,
      },
      {
        ...roadmapOwnerColumn,
        width: 160,
      },
      {
        ...roadmapJiraLabelColumn,
        width: 130,
      },
      {
        ...roadmapEpicUrlColumn,
        width: 120,
      },
    ].filter(Boolean),
  }

  const navigation = (
    <TabBar mb="s-16" variant="segmented fit">
      {tabs.map(tab => {
        return (
          <TabBar.Item
            aria-selected={selectedTab === tab.id}
            key={tab.id}
            onClick={() => setSelectedTab(tab.id)}
          >
            {tab.title}
          </TabBar.Item>
        )
      })}
    </TabBar>
  )

  return (
    <>
      {!isNewLayout && navigation}

      <RoadmapDeadlineBanner reviewCycle={reviewCycle || null} mb="s-16" />
      <RoadmapTab
        showTableInfo={selectedTab === RoadmapType.Department}
        allowRefresh={selectedTab === RoadmapType.Department}
        row={row}
        key={selectedTab}
        reviewCycle={reviewCycle}
        tableProps={{ name: TableNames.DepartmentRoadmap }}
        defaultFilters={getRoadmapFilters(data, selectedTab)}
        defaultSorting={roadmapSorting}
        triggerRefresh={triggerRefresh}
        roadmapSettings={roadmapSettings}
        allowEdit={
          !!data.field_options.permissions?.includes(PermissionTypes.CanCreateRoadmaps) &&
          selectedTab === RoadmapType.Department
        }
        addRoadmapProps={{
          entityType: EntityTypes.department,
          id: data.id,
          backUrl: pathToUrl(ROUTES.FORMS.DEPARTMENT.ROADMAP, { id: data.id }),
          name: data.name,
          tags: data?.jira_projects || [],
        }}
        newLayoutNavigation={navigation}
      />
    </>
  )
}

export default Roadmap
