import React, { useMemo } from 'react'
import { RowInterface } from '@src/interfaces/data'
import AdjustableTable from '@components/Table/AdjustableTable'
import { useTable } from '@components/Table/hooks'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { Flex, MoreBar, Bar, Box, FilterButton } from '@revolut/ui-kit'
import { Plus, Pencil } from '@revolut/icons'
import Stat from '@components/Stat/Stat'
import { PermissionTypes } from '@src/store/auth/types'
import { useSelector } from 'react-redux'
import { selectPermissions, selectUser } from '@src/store/auth/selectors'
import { nipsColumn } from '@src/constants/columns/department'
import { roleManagerColumn } from '@src/constants/columns/employee'
import {
  roleFunctionColumn,
  roleGenericNameColumn,
  roleHeadcountColumn,
  roleRequisitionsColumn,
  roleSkillsColumn,
  roleStatusColumn,
} from '@src/constants/columns/role'
import { RoleInterface } from '@src/interfaces/roles'
import { rolesRequests } from '@src/api/roles'
import SearchTable from '@components/Table/SearchTable/SearchTable'
import { Statuses } from '@src/interfaces'
import { useLocalStorage } from '@src/hooks/useLocalStorage'
import { LOCAL_STORAGE } from '@src/constants/api'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { TableNames } from '@src/constants/table'
import { useGetOrganisationSettings } from '@src/api/settings'
import { withFavourites } from '@src/features/FavouritesFilter/withFavourites'
import { useFavouritesFilter } from '@src/features/FavouritesFilter/useFavouritesFilter'
import { defaultRolesTableStatusFilter } from './common'
import { SettingsButton } from '@src/features/SettingsButtons/SettingsButton/SettingsButton'
import { settingsConfig } from '@src/pages/Settings/SettingsLandingPage/constants'

const RolesTable = () => {
  const user = useSelector(selectUser)
  const [showMyRoles, setShowMyRoles] = useLocalStorage(
    LOCAL_STORAGE.SHOW_MY_ROLES,
    false,
  )
  const { FavouritesFilter, initialFilter } = useFavouritesFilter('role')

  const getFilterByManager = (setFilter: boolean) => ({
    filters: setFilter ? [{ name: user.display_name, id: user.id }] : [],
    columnName: 'role_manager__id',
    nonResettable: true,
  })

  const getInitialFilter = () => {
    const filter = []

    filter.push({
      filters: defaultRolesTableStatusFilter,
      columnName: 'status',
    })

    filter.push(getFilterByManager(showMyRoles))

    if (initialFilter) {
      filter.push(initialFilter)
    }

    return filter
  }

  const filterBy = getInitialFilter()
  const table = useTable<RoleInterface>(rolesRequests, filterBy)
  const permissions = useSelector(selectPermissions)

  const canAdd = permissions.includes(PermissionTypes.AddRoles)
  const canManage = permissions.includes(PermissionTypes.ManageRoles)

  const { data: settings } = useGetOrganisationSettings()

  const row = useMemo((): RowInterface<RoleInterface> => {
    return {
      linkToForm: ({ id, status }) => {
        if (status === Statuses.draft) {
          return navigateTo(pathToUrl(ROUTES.FORMS.ROLE.GENERAL, { id }))
        }
        return navigateTo(pathToUrl(ROUTES.FORMS.ROLE.PREVIEW, { id }))
      },
      cells: [
        {
          ...roleGenericNameColumn,
          width: 220,
        },
        {
          ...roleManagerColumn,
          width: 200,
        },
        {
          ...roleFunctionColumn,
          width: 150,
        },
        {
          ...roleHeadcountColumn,
          width: 100,
        },
        {
          ...roleRequisitionsColumn,
          width: 110,
        },
        {
          ...nipsColumn,
          width: 100,
        },
        settings?.enable_roles_approvals
          ? {
              ...roleStatusColumn,
              width: 130,
            }
          : null,
        {
          ...roleSkillsColumn,
          width: 620,
        },
      ].filter(Boolean),
    }
  }, [settings])

  const onToggleMyRoles = () => {
    setShowMyRoles(!showMyRoles)
    table.onFilterChange(getFilterByManager(!showMyRoles))
  }

  return (
    <>
      <Flex flexDirection="column" width="100%">
        <Flex flexWrap="wrap" justifyContent="space-between">
          <Flex mb="s-24">
            <Stat
              label="Total"
              val={table?.loading ? undefined : table?.count}
              mr="s-32"
            />
          </Flex>
          <SearchTable
            placeholder="Search by role and function names"
            mb="s-24"
            onFilter={table.onFilterChange}
          />
        </Flex>
        <Flex mb="s-16" justifyContent="space-between">
          <Box>
            <MoreBar>
              {canAdd && (
                <MoreBar.Action
                  use={InternalLink}
                  to={pathToUrl(ROUTES.FORMS.ROLE.GENERAL)}
                  useIcon={Plus}
                >
                  Add Role
                </MoreBar.Action>
              )}
              {canManage && (
                <MoreBar.Action
                  use={InternalLink}
                  to={pathToUrl(ROUTES.FORMS.UPDATE_ORGANISATION_STRUCTURE.ROLE)}
                  useIcon={Pencil}
                >
                  Edit
                </MoreBar.Action>
              )}
              <SettingsButton
                path={ROUTES.SETTINGS.ROLES.GENERAL}
                canView={settingsConfig.roles.canView}
              />
            </MoreBar>
          </Box>
          <Bar>
            <FilterButton onClick={onToggleMyRoles} active={showMyRoles}>
              My roles
            </FilterButton>
            <FavouritesFilter table={table} />
          </Bar>
        </Flex>
        <Flex style={{ position: 'relative' }} flex="1 0">
          <AdjustableTable<RoleInterface>
            name={TableNames.Roles}
            useWindowScroll
            dataType="Role"
            row={row}
            {...table}
            noDataMessage="Roles will appear here."
          />
        </Flex>
      </Flex>
    </>
  )
}

export default withFavourites(RolesTable)
