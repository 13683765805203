import React from 'react'
import {
  CycleFilter,
  CycleFilterType,
} from '@components/Inputs/Filters/FilterSelect/CycleFilter/CycleFilter'
import { FilterSelectType } from '@components/Inputs/Filters/FilterSelect/FilterSelect'
import { Box, Flex, Group, Widget } from '@revolut/ui-kit'
import SettingsButtons, { ArchiveButton } from '@src/features/SettingsButtons'
import PermissionTransferButton from '@src/pages/PermissionTransfer/Button/PermissionTransferFormButton'
import { ROUTES } from '@src/constants/routes'
import { UpdateButton } from '@src/pages/Forms/FormButtons'
import { EntityPermissions } from '@src/store/auth/types'
import useApproval from '@src/features/ApprovalFlow/useApproval'
import { Stats } from '@src/interfaces/data'
import { useTable } from '@components/Table/hooks'
import { KpiInterface } from '@src/interfaces/kpis'
import { kpisRequests } from '@src/api/kpis'
import Form from '@src/features/Form/Form'
import { PageBody } from '@components/Page/PageBody'
import Stat from '@components/Stat/Stat'
import { getPercentColor } from '@components/ColumnInserts/ColoredPercent/ColoredPercent'
import { formatPercentage } from '@src/utils/format'
import { FormPreview } from '@components/FormPreview/FormPreview'
import { goBack, navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import KPIsSummary from '@src/features/Summary/KPIsSummary'
import { EntityTypes } from '@src/constants/api'
import { TeamRoadmapSummary } from '@src/features/Summary/RoadmapSummary'
import { kpiSorting } from '@src/pages/Forms/TeamForm/KPI'
import HiringSummary from '@src/features/Summary/HiringSummary'
import { getRequisitionsFilter } from '@src/pages/Forms/TeamForm/Requisitions/Requisitions'
import { useTalentTable } from '@src/pages/Forms/CommonTalentTab/Talent'
import { getTalentTypes } from '@src/interfaces/talent/talent'
import TalentSummary from '@src/features/Summary/TalentSummary'
import FindingsSummary, {
  findingsSummaryFilters,
  findingsSummarySorting,
} from '@src/features/Summary/FindingsSummary'
import { AuditInterface } from '@src/interfaces/audit'
import { getAuditTickets } from '@src/api/audit'
import { TeamInterface } from '@src/interfaces/teams'
import {
  silentDeleteTeam,
  teamsRequestsNew,
  updateTeamStatistics,
  useGetTeamApprovals,
} from '@src/api/teams'
import { Statuses } from '@src/interfaces'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import { CommunicationDetails } from '@src/features/CommunicationGroups/CommunicationDetails'
import { CommunicationActions } from '@src/features/CommunicationGroups/CommunicationActions'
import { useGetOrganisationSettings, useGetRoadmapSettings } from '@src/api/settings'
import {
  requisitionSortBy,
  useRequisitionTable,
} from '@src/features/CommonRequisitionTable/CommonRequisitionTable'
import { getBaseKpiFilters } from '@src/features/KPI'
import LapeDeleteOrgUnitButton from '@src/features/SettingsButtons/DeleteOrgUnitButton/LapeDeleteOrgUnitButton'

interface Props {
  data: TeamInterface
  refetch?: () => void
}

const Summary = ({ data, refetch }: Props) => {
  const { data: organisationSettings } = useGetOrganisationSettings()
  const { data: roadmapSettings } = useGetRoadmapSettings()

  const approvalsEnabled = !!organisationSettings?.enable_teams_approvals
  const findingsEnabled = !!organisationSettings?.findings?.enabled

  const { approvalButtons, approvalFlow, refetchApproval } = useApproval({
    data: approvalsEnabled ? data : undefined,
    refetch,
    useGetApprovals: useGetTeamApprovals,
    statusFieldName: 'approval_status',
  })
  const backUrl = ROUTES.ORGANISATION.TEAMS.TEAMS
  const entityType = EntityTypes.team

  const kpiTable = useTable<KpiInterface, Stats>(
    kpisRequests,
    [
      ...getBaseKpiFilters(),
      {
        filters: [{ name: data.id.toString(), id: data.id }],
        columnName: 'team__id',
        nonResettable: true,
        nonInheritable: true,
      },
    ],
    kpiSorting,
  )

  const requisitionsTable = useRequisitionTable({
    filterBy: getRequisitionsFilter(data),
    sortBy: requisitionSortBy,
    statsData: {
      filters: [
        {
          filters: [{ name: data.name, id: data.id }],
          columnName: 'team__id',
        },
      ],
    },
  })

  const talentTable = useTalentTable(getTalentTypes(data.id).team.filter)

  const findingsTable = useTable<AuditInterface>(
    { getItems: getAuditTickets(entityType, data.id) },
    findingsSummaryFilters,
    findingsSummarySorting,
    { disable: !findingsEnabled },
  )

  return (
    /* Form is for approval, archive and other buttons to work */
    <Form api={teamsRequestsNew}>
      <PageBody>
        <Flex flexDirection="column">
          <Box mb="s-16">{approvalFlow}</Box>
          <Widget p="s-16">
            <Flex flexDirection="column">
              <Flex flexDirection="row">
                <CycleFilter
                  type={CycleFilterType.NewUI}
                  onFilterChange={() => {}}
                  columnName="review_cycle__offset"
                  filter={kpiTable.filterBy}
                  filterInputType={FilterSelectType.SingleSelect}
                  disabled
                  label="Current cycle"
                />
                <Stat
                  label="Performance"
                  val={formatPercentage(data.performance)}
                  color={getPercentColor(data.performance * 100)}
                  ml="s-32"
                />
              </Flex>
              <SettingsButtons mt="s-16">
                <CommunicationActions group={data.communication_group} />
                {approvalButtons}
                <UpdateButton update={updateTeamStatistics} id={data.id} />
                <PermissionTransferButton
                  path={ROUTES.FORMS.PERMISSION_MANAGEMENT.ENTITIES.TEAM}
                />
                <ArchiveButton
                  isVisible={!!data?.id}
                  entityPermissions={EntityPermissions.Change}
                  unArchiveStatus={Statuses.active}
                  onAfterArchive={() => {
                    refetch?.()
                    refetchApproval()
                  }}
                />
                {data?.id && (
                  <LapeDeleteOrgUnitButton
                    onAfterDelete={() => goBack(backUrl)}
                    deleteApi={silentDeleteTeam}
                    prefix="team"
                    displayName="team"
                  />
                )}
              </SettingsButtons>
            </Flex>
          </Widget>

          <Box mt="s-16">
            <FormPreview<TeamInterface>
              data={data}
              title="Details"
              onEdit={() =>
                navigateTo(pathToUrl(ROUTES.FORMS.TEAM.SETTINGS, { id: data.id }))
              }
            >
              <Group>
                <FormPreview.Item<TeamInterface> field="name" title="Team name" />
                <FormPreview.Item<TeamInterface>
                  field="team_owner"
                  title="Owner"
                  type="employee"
                />
                <FormPreview.Details<TeamInterface>
                  title="KPI champions"
                  description="These people have increased permissions to help when editing and setting KPIs"
                  insert={d =>
                    d.kpi_champions ? (
                      <Flex flexWrap="wrap">
                        {d.kpi_champions.map(kpiChamp => (
                          <UserWithAvatar
                            height="s-32"
                            mr="s-12"
                            key={kpiChamp.id}
                            {...kpiChamp}
                          />
                        ))}
                      </Flex>
                    ) : (
                      '-'
                    )
                  }
                />
                <FormPreview.Details<TeamInterface> field="mission" title="Mission" />
                <CommunicationDetails
                  jiraProjects={data.jira_projects}
                  group={data.communication_group}
                  linkedGroups={data.linked_communication_groups}
                  jiraEnabled={roadmapSettings?.jira_epics_enabled}
                />
              </Group>
            </FormPreview>
          </Box>
          <Box mt="s-16">
            <KPIsSummary
              kpiPercent={data.kpi_performance_percent}
              table={kpiTable}
              entityType={entityType}
            />
          </Box>
          {roadmapSettings?.enabled ? (
            <Box mt="s-16">
              <TeamRoadmapSummary data={data} />
            </Box>
          ) : null}
          <Box mt="s-16">
            <HiringSummary table={requisitionsTable} entityType={entityType} />
          </Box>
          <Box mt="s-16">
            <TalentSummary data={data} table={talentTable} entityType={entityType} />
          </Box>
          {findingsEnabled ? (
            <Box mt="s-16">
              <FindingsSummary table={findingsTable} entityType={entityType} />
            </Box>
          ) : null}
        </Flex>
      </PageBody>
    </Form>
  )
}

export default Summary
