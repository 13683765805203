import React from 'react'
import { FilterByInterface, SORT_DIRECTION } from '@src/interfaces/data'
import { initialRequisitionStatusFilter } from '@src/interfaces/requisitions'
import {
  CommonRequisitionTable,
  CommonRequisitionTableWidget,
} from '@src/features/CommonRequisitionTable/CommonRequisitionTable'
import { withFavourites } from '@src/features/FavouritesFilter/withFavourites'
import { useFavouritesFilter } from '@src/features/FavouritesFilter/useFavouritesFilter'

const sortBy = [
  {
    sortBy: 'pipeline_queue_position',
    direction: SORT_DIRECTION.DESC,
  },
  {
    sortBy: 'status',
    direction: SORT_DIRECTION.ASC,
  },
]

const getInitialFilters = (initialFilter?: FilterByInterface): FilterByInterface[] => {
  const filters = []
  if (initialFilter) {
    filters.push(initialFilter)
  }
  return [
    ...filters,
    {
      filters: initialRequisitionStatusFilter,
      columnName: 'status',
      nonResettable: true,
    },
  ]
}

export const RequisitionsTable = withFavourites(() => {
  const { initialFilter } = useFavouritesFilter('requisition')
  return (
    <CommonRequisitionTable
      filterBy={getInitialFilters(initialFilter)}
      sortBy={sortBy}
      type="main"
      enableFavourites
    />
  )
})

export const RequisitionsTableWidget = withFavourites(() => {
  const { initialFilter } = useFavouritesFilter('requisition')
  return (
    <CommonRequisitionTableWidget
      filterBy={getInitialFilters(initialFilter)}
      sortBy={sortBy}
      type="main"
      enableFavourites
    />
  )
})
