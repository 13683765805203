import React, { useState } from 'react'

import {
  GenericEditableTable,
  CommonGenericEditableTableRowOptions,
} from '@src/features/GenericEditableTable/GenericEditableTable'
import { API } from '@src/constants/api'
import { TableNames } from '@src/constants/table'
import { RowInterface } from '@src/interfaces/data'
import { ImportInterface } from '@src/interfaces/bulkDataImport'
import { MoreBar } from '@revolut/ui-kit'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { ROUTES } from '@src/constants/routes'
import {
  onboardingJobsV2DescriptionColumn,
  onboardingJobsV2HeadcountColumn,
  onboardingJobsV2LocationsColumn,
  onboardingJobsV2RecruiterColumn,
  onboardingJobsV2RoleColumn,
  onboardingJobsV2SeniorityColumn,
  onboardingJobsV2TeamColumn,
  onboardingJobsV2TitleColumn,
} from '@src/constants/columns/importJobsV2'
import { RequisitionPostingSimpleInterface } from '@src/interfaces/requisitions'
import {
  BulkUpdateJobPopup,
  BulkUpdateRequisitionJobField,
} from '@src/pages/OnboardingChecklistV2/Jobs/components'

const row = (
  options: CommonGenericEditableTableRowOptions,
): RowInterface<ImportInterface<RequisitionPostingSimpleInterface>> => ({
  cells: [
    {
      ...onboardingJobsV2TitleColumn(options.onChange),
      width: 200,
    },
    {
      ...onboardingJobsV2TeamColumn(options.onChange),
      width: 200,
    },
    {
      ...onboardingJobsV2RoleColumn(options.onChange),
      width: 150,
    },
    {
      ...onboardingJobsV2HeadcountColumn(options.onChange),
      width: 200,
    },
    {
      ...onboardingJobsV2SeniorityColumn(options.onChange),
      width: 200,
    },
    {
      ...onboardingJobsV2LocationsColumn(options.onChange),
      width: 200,
    },
    {
      ...onboardingJobsV2RecruiterColumn(options.onChange),
      width: 200,
    },
    {
      ...onboardingJobsV2DescriptionColumn(options.onChange),
      width: 150,
    },
  ],
})

export const JobsImport = () => {
  const [popupState, setPopupState] = useState<{
    field: BulkUpdateRequisitionJobField
    items: number[]
    refreshTable: () => void
  } | null>(null)

  return (
    <>
      <GenericEditableTable
        apiEndpoint={API.REQUISITION_POSTING_SIMPLE}
        apiVersion="v2"
        tableName={TableNames.ImportJobsV2}
        row={row}
        entity="job"
        variant="existingEntities"
        hiddenColumns={{ action: true }}
        tableActions={props => (
          <MoreBar>
            <MoreBar.Action
              use={InternalLink}
              to={ROUTES.ONBOARDING_CHECKLIST_V2.JOBS.NEW}
              useIcon="Plus"
            >
              Add requisition
            </MoreBar.Action>
            <MoreBar.Action
              use={InternalLink}
              to={ROUTES.ONBOARDING_CHECKLIST_V2.JOBS.UPLOAD.IMPORT}
              useIcon="Upload"
            >
              Import requisitions
            </MoreBar.Action>
            <MoreBar.Action
              useIcon="People"
              disabled={!props.someSelected}
              onClick={() => {
                setPopupState({
                  field: 'team',
                  items: props.getSelectedItems(),
                  refreshTable: props.refreshTableState,
                })
              }}
            >
              Change team
            </MoreBar.Action>
            <MoreBar.Action
              useIcon="RepairTool"
              disabled={!props.someSelected}
              onClick={() => {
                setPopupState({
                  field: 'specialisation',
                  items: props.getSelectedItems(),
                  refreshTable: props.refreshTableState,
                })
              }}
            >
              Change role
            </MoreBar.Action>
            <MoreBar.Action
              useIcon="ArrowRightLeft"
              disabled={!props.someSelected}
              onClick={() => {
                setPopupState({
                  field: 'seniority',
                  items: props.getSelectedItems(),
                  refreshTable: props.refreshTableState,
                })
              }}
            >
              Change seniority
            </MoreBar.Action>
            <MoreBar.Action
              useIcon="MapPoint"
              disabled={!props.someSelected}
              onClick={() => {
                setPopupState({
                  field: 'locations',
                  items: props.getSelectedItems(),
                  refreshTable: props.refreshTableState,
                })
              }}
            >
              Change locations
            </MoreBar.Action>
            <MoreBar.Action
              useIcon="Profile"
              disabled={!props.someSelected}
              onClick={() => {
                setPopupState({
                  field: 'recruiter',
                  items: props.getSelectedItems(),
                  refreshTable: props.refreshTableState,
                })
              }}
            >
              Change recruiter
            </MoreBar.Action>
          </MoreBar>
        )}
      />
      {popupState && (
        <BulkUpdateJobPopup
          open
          selectedItems={popupState.items}
          field={popupState.field}
          onSuccess={() => {
            popupState.refreshTable()
            setPopupState(null)
          }}
          onClose={() => {
            setPopupState(null)
          }}
        />
      )}
    </>
  )
}
