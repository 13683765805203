import React from 'react'

import { CellTypes } from '@src/interfaces/data'
import { selectorKeys } from '../api'
import {
  EmployeeSelectCell,
  GenericEditableTableColumn,
  RadioSelectInputCell,
  TextCell,
} from '@src/features/GenericEditableTable/components'
import {
  CandidateSimpleInterface,
  ImportCandidatesDataInterface,
} from '@src/interfaces/importCandidates'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'

type ImportCandidateColumn = GenericEditableTableColumn<ImportCandidatesDataInterface>

type OnboardingCandidateColumn = GenericEditableTableColumn<CandidateSimpleInterface>

export const importCandidatesV2NameColumn: ImportCandidateColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'full_name',
  dataPoint: 'full_name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Full name',
  insert: ({ data }) => <TextCell data={data} onChange={onChange} field="full_name" />,
})

export const importCandidatesV2EmailColumn: ImportCandidateColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'email',
  dataPoint: 'email',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Email',
  insert: ({ data }) => <TextCell data={data} onChange={onChange} field="email" />,
})

export const importCandidatesV2LinkedinUrlColumn: ImportCandidateColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'linkedin_url',
  dataPoint: 'linkedin_url',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Linkedin url',
  insert: ({ data }) => <TextCell data={data} onChange={onChange} field="linkedin_url" />,
})

export const importCandidatesV2RoleColumn: ImportCandidateColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'specialisation',
  dataPoint: 'specialisation',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Role',
  insert: ({ data }) => (
    <RadioSelectInputCell
      data={data}
      onChange={onChange}
      field="specialisation"
      selector={selectorKeys.specialisations}
      selectorField="name"
      fieldName="role"
    />
  ),
})

export const importCandidatesV2CountryColumn: ImportCandidateColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'country',
  dataPoint: 'country',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Country',
  insert: ({ data }) => (
    <RadioSelectInputCell
      data={data}
      onChange={onChange}
      field="country"
      selector={selectorKeys.countries}
      selectorField="name"
      fieldName="country"
    />
  ),
})

export const importCandidatesV2SeniorityColumn: ImportCandidateColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'expected_seniority',
  dataPoint: 'expected_seniority',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Seniority',
  insert: ({ data }) => (
    <RadioSelectInputCell
      data={data}
      onChange={onChange}
      field="expected_seniority"
      fieldName="seniority"
      selector={selectorKeys.seniority}
      selectorField="name"
    />
  ),
})

export const importCandidatesV2RecruiterColumn: ImportCandidateColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'recruiter',
  dataPoint: 'recruiter',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Recruiter',
  insert: ({ data }) => (
    <EmployeeSelectCell data={data} onChange={onChange} field="recruiter" />
  ),
})

export const importCandidatesV2JobTitleColumn: ImportCandidateColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'job_posting',
  dataPoint: 'job_posting',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Job posting',
  insert: ({ data }) => (
    <RadioSelectInputCell
      data={data}
      onChange={onChange}
      field="job_posting"
      fieldName="Job posting"
      selector={selectorKeys.job_postings}
      selectorField="name"
    />
  ),
})

export const onboardingCandidatesV2NameColumn: OnboardingCandidateColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'full_name',
  dataPoint: 'full_name',
  sortKey: 'full_name',
  filterKey: 'id',
  selectorsKey: selectorKeys.interviews_candidates,
  title: 'Full name',
  insert: ({ data }) => <TextCell data={data} onChange={onChange} field="full_name" />,
})

export const onboardingCandidatesV2EmailColumn: OnboardingCandidateColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'email',
  dataPoint: 'email',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Email',
  insert: ({ data }) => <TextCell data={data} onChange={onChange} field="email" />,
})

export const onboardingCandidatesV2RoleColumn: OnboardingCandidateColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'specialisation',
  dataPoint: 'specialisation',
  sortKey: 'active_specialisation__name',
  filterKey: 'active_specialisation',
  selectorsKey: selectorKeys.specialisations,
  title: 'Role',
  insert: ({ data }) => (
    <RadioSelectInputCell
      data={data}
      onChange={onChange}
      field="specialisation"
      selector={selectorKeys.specialisations}
      fieldName="role"
    />
  ),
})

export const onboardingCandidatesV2CountryColumn: OnboardingCandidateColumn =
  onChange => ({
    type: CellTypes.insert,
    idPoint: 'country',
    dataPoint: 'country',
    sortKey: null,
    filterKey: 'country',
    selectorsKey: selectorKeys.countries,
    title: 'Country',
    insert: ({ data }) => (
      <RadioSelectInputCell
        data={data}
        onChange={onChange}
        field="country"
        selector={selectorKeys.countries}
        fieldName="country"
      />
    ),
  })

export const onboardingCandidatesV2SeniorityColumn: OnboardingCandidateColumn =
  onChange => ({
    type: CellTypes.insert,
    idPoint: 'seniority',
    dataPoint: 'seniority',
    sortKey: 'active_interview_round__seniority__level',
    filterKey: 'active_interview_round__seniority',
    selectorsKey: selectorKeys.seniority,
    title: 'Seniority',
    insert: ({ data }) => (
      <RadioSelectInputCell
        data={data}
        onChange={onChange}
        field="seniority"
        selector={selectorKeys.seniority}
      />
    ),
  })

export const onboardingCandidatesV2RecruiterColumn: OnboardingCandidateColumn =
  onChange => ({
    type: CellTypes.insert,
    idPoint: 'recruiter',
    dataPoint: 'recruiter',
    sortKey: 'recruiter__full_name',
    filterKey: 'recruiter',
    selectorsKey: selectorKeys.employee,
    title: 'Recruiter',
    insert: ({ data }) => (
      <RadioSelectInputCell
        data={data}
        onChange={onChange}
        field="recruiter"
        selector={selectorKeys.employee}
        customLabel={
          data.data.recruiter ? (
            <UserWithAvatar {...data.data.recruiter} disableLink />
          ) : (
            '-'
          )
        }
      />
    ),
  })

export const onboardingCandidatesV2JobTitleColumn: OnboardingCandidateColumn =
  onChange => ({
    type: CellTypes.insert,
    idPoint: 'job_posting',
    dataPoint: 'job_posting',
    sortKey: null,
    filterKey: 'all_job_postings_locations_type',
    selectorsKey: selectorKeys.all_job_postings_locations_type,
    title: 'Job posting',
    insert: ({ data }) => (
      <RadioSelectInputCell
        data={data}
        onChange={onChange}
        field="job_posting"
        selector={selectorKeys.job_postings}
        fieldName="job title"
      />
    ),
  })
