import React from 'react'
import { connect } from 'lape'
import Form from '@src/features/Form/Form'
import OfferPlaceholder from '@src/pages/Forms/OfferPlaceholder/OfferPlaceholder'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { ROUTES } from '@src/constants/routes'
import { PageWrapper } from '@components/Page/Page'
import { offerPlaceholdersFormRequest } from '@src/api/offerTemplates'

const OfferPlaceholderIndex = () => {
  return (
    <PageWrapper>
      <PageHeader
        title="Create new placeholder"
        backUrl={ROUTES.APPS.OFFERS.OFFER_PLACEHOLDERS}
      />
      <Form api={offerPlaceholdersFormRequest}>
        <OfferPlaceholder />
      </Form>
    </PageWrapper>
  )
}

export default connect(OfferPlaceholderIndex)
