import React from 'react'
import { TeamInterface } from '@src/interfaces/teams'
import { refreshRoadmap } from '@src/api/roadmaps'
import { TableNames } from '@src/constants/table'
import { useQuery } from '@src/utils/queryParamsHooks'
import { useGetReviewCycle } from '@src/api/reviewCycles'
import { PermissionTypes } from '@src/store/auth/types'
import { RoadmapTab } from './RoadmapTab/RoadmapTab'
import { useGetRoadmapSettings } from '@src/api/settings'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { getCommonFilters } from './common'

interface StructureProps {
  data: TeamInterface
  cycle: ReviewCyclesInterface
}

export const getTeamRoadmapFilters = (
  { id }: TeamInterface,
  reviewCycle: ReviewCyclesInterface,
) => [
  ...getCommonFilters({ reviewCycle }),
  {
    filters: [{ name: id.toString(), id }],
    columnName: 'team__id',
    nonResettable: true,
  },
]

export const TeamRoadmaps = ({ data, cycle }: StructureProps) => {
  const { query } = useQuery()

  const cycleId = query.review_cycle__id || String(cycle.id)
  const { data: reviewCycle } = useGetReviewCycle(cycleId || String(cycle.id))
  const { data: roadmapSettings } = useGetRoadmapSettings()

  const allowEdit = !!data.field_options.permissions?.includes(
    PermissionTypes.CanCreateRoadmaps,
  )

  return (
    <>
      <RoadmapTab
        showTableInfo={allowEdit}
        allowRefresh
        defaultFilters={getTeamRoadmapFilters(data, cycle)}
        reviewCycle={reviewCycle}
        allowEdit={allowEdit}
        roadmapSettings={roadmapSettings}
        triggerRefresh={async () => {
          await refreshRoadmap(
            `TEAM-${data.id}`,
            reviewCycle?.id ? { id: reviewCycle.id as number } : undefined,
          )
        }}
        tableProps={{ name: TableNames.TeamRoadmap }}
      />
    </>
  )
}
