import React from 'react'
import { CommonRequisitionTableWidget } from '@src/features/CommonRequisitionTable/CommonRequisitionTable'
import { initialRequisitionStatusFilter } from '@src/interfaces/requisitions'
import { Box } from '@revolut/ui-kit'
import { useParams } from 'react-router-dom'

const Candidates = () => {
  const { id } = useParams<{ id: string }>()
  const filterBy = [
    {
      filters: initialRequisitionStatusFilter,
      columnName: 'status',
      nonResettable: true,
    },
    {
      filters: [{ name: id, id }],
      columnName: 'requisition_posting__job_posting__hiring_pipeline',
      nonResettable: true,
    },
  ]
  const statsData = {
    filters: filterBy,
  }
  return (
    <Box mt="s-16">
      <CommonRequisitionTableWidget
        filterBy={filterBy}
        statsData={statsData}
        type="main"
      />
    </Box>
  )
}

export default Candidates
