import React from 'react'
import { Widget, Flex } from '@revolut/ui-kit'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import { Switch, Route } from 'react-router-dom'
import { InternalRedirect } from '@components/InternalLink/InternalRedirect'
import { Subtab } from '@src/interfaces/tabPinning'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'

interface PageWithTabsProps {
  tabs: Subtab[]
}

export const PageWithTabs = ({ tabs }: PageWithTabsProps) => {
  const permissions = useSelector(selectPermissions)

  const filteredTabs = tabs.filter(tab => {
    return tab.permission ? permissions.includes(tab.permission) : true
  })

  if (filteredTabs.length === 0) {
    return null
  }

  return (
    <Widget display="flex" p="s-16" width="100%">
      <Flex flexDirection="column" width="100%">
        {filteredTabs.length > 1 ? (
          <Flex alignItems="flex-start" mb="s-24">
            <TabBarNavigation isSubtab tabs={filteredTabs} />
          </Flex>
        ) : null}
        <Switch>
          {filteredTabs.map(tab => (
            <Route exact path={tab.path} key={tab.path}>
              <tab.component />
            </Route>
          ))}
          {filteredTabs[0].path && <InternalRedirect to={filteredTabs[0].path} />}
        </Switch>
      </Flex>
    </Widget>
  )
}
