import React, { useState } from 'react'
import {
  Fixed,
  Side,
  Text,
  VStack,
  HStack,
  Color,
  MoreBar,
  Item,
  Button,
  MoreBarSkeleton,
  TextButton,
  TextWidgetSkeleton,
  TextSkeleton,
  ItemSkeleton,
} from '@revolut/ui-kit'
import { useTheme } from '@src/styles/theme'
import { TalentPerformanceInterface } from '@src/interfaces/talent/performance'
import { Pagination } from '@src/components/Pagination/Pagination'
import { Check, ChevronDown, ChevronUp, CommentAdd, Cross } from '@revolut/icons'
import { FinalGrade, ReviewCategory } from '@src/interfaces/performance'
import { useGetSelectors } from '@src/api/selectors'
import { selectorKeys } from '@src/constants/api'
import { TalentType } from '@src/interfaces/talent/talent'
import { isDepartmentType, useShowRanking } from '../utils'
import OpenReviewButton from '@src/pages/EmployeeProfile/Preview/Performance/Summary/OpenReviewButton'
import useTabBarSwitcher from '@src/features/TabBarSwitcher/useTabBarSwitcher'
import { useFetchPerformanceSummary } from '@src/pages/EmployeeProfile/Preview/Performance/Summary/hooks'
import { getTalentCommentsAPI } from '@src/api/talent'
import { ChatWidget } from '@components/Chat/ChatWidget'
import { InfoIconWithTooltip } from '@components/Icon/InfoIconWithTooltip'
import { UserCard } from '@components/UserCard/UserCard'
import { PerformanceHistorySection } from './PerformanceHistorySection'
import { ReviewsSection } from './ReviewsSection'
import { GradeSelector } from './GradeSelector'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { getLocationDescriptor } from '@src/actions/RouterActions'
import { useHistory } from 'react-router-dom'
import {
  DelegateCalibrationMoreAction,
  DelegateCalibrationProps,
} from '../DelegateCalibration'
import { getAvatarUrl } from '@src/utils/employees'
import { PerformanceGradeRevamped } from '@components/PerformanceGrade/PerformanceGradeRevamped'
import { SelectorType } from '@src/interfaces/selectors'

interface Props {
  talent: TalentPerformanceInterface
  onClose: () => void
  isOpen: boolean
  currentIndex: number
  total: number
  onPageChanaged: (index: number) => void
  onReviewRequested?: () => void
  onDelegate?: DelegateCalibrationProps['delegate']
  talentType: TalentType
  onGradeSelected: (value: { id: FinalGrade } | null) => Promise<void>
  canChangeGrade: boolean
  selector: SelectorType
}

const SummarySidebar = ({
  talent,
  onClose,
  isOpen,
  currentIndex,
  total,
  onPageChanaged,
  onReviewRequested,
  onDelegate,
  talentType,
  onGradeSelected,
  canChangeGrade,
  selector,
}: Props) => {
  const theme = useTheme()
  const history = useHistory()
  const [pendingGradeUpdate, setPendingGradeUpdate] = useState(false)
  const {
    reviews,
    data: summary,
    isLoading: isLoadingSummary,
    refresh,
    resetReviwersFilters,
  } = useFetchPerformanceSummary(
    String(talent.cycle.id),
    talent.employee.id,
    ReviewCategory.Performance,
  )
  const { data: grades } = useGetSelectors<{ id: FinalGrade; name: string }>(
    selectorKeys.calibration_grades,
  )
  const { tabBar, currentTab } = useTabBarSwitcher({
    tabs: ['Reviews', 'Performance history'],
    defaultTab: 'Reviews',
    highlightSelected: false,
  })
  const commentsApi = getTalentCommentsAPI(talent.id)

  const showRanking = useShowRanking(talentType)

  const acceptedGrade = isDepartmentType(talentType)
    ? talent.department_owner_grade_suggestion
    : talent.function_owner_grade_suggestion

  const recommenedGrade =
    grades?.find(grade => grade.id === talent.performance_extra_grade_suggestion?.id)
      ?.name || '-'

  const gradeDescription = isDepartmentType(talentType)
    ? 'HoD calibrated grade'
    : 'HoF calibrated grade'

  const renderGrade = () => {
    if (pendingGradeUpdate) {
      return <TextSkeleton width="50%" />
    }
    return acceptedGrade ? (
      <PerformanceGradeRevamped grade={acceptedGrade} inverse />
    ) : (
      recommenedGrade
    )
  }

  const renderGradeWidget = () => {
    if (pendingGradeUpdate) {
      return (
        <ItemSkeleton>
          <ItemSkeleton.Content />
          <ItemSkeleton.Side />
        </ItemSkeleton>
      )
    }
    return (
      <Item>
        <Item.Content color={Color.GREY_TONE_50}>
          <Item.Title variant="h5" style={acceptedGrade ? {} : { fontStyle: 'italic' }}>
            {renderGrade()}
          </Item.Title>
          <Item.Description>
            <HStack space="s-8" align="center">
              <Text variant="caption">
                {acceptedGrade ? gradeDescription : 'Recommended grade'}
              </Text>
              {!acceptedGrade && (
                <InfoIconWithTooltip
                  size={16}
                  tooltipProps={{ maxWidth: '280px' }}
                  content="This is the grade recommended by the system based on the feedback and suggestions from the manager reviews."
                />
              )}
            </HStack>
          </Item.Description>
        </Item.Content>
        {canChangeGrade ? (
          <Item.Side>
            <HStack space="s-8">
              {talent.performance_extra_grade_suggestion && !acceptedGrade ? (
                <Button
                  pending={pendingGradeUpdate}
                  size="sm"
                  useIcon={Check}
                  onClick={() =>
                    handleGradeChange({
                      id: talent.performance_extra_grade_suggestion?.id!,
                    })
                  }
                >
                  Accept
                </Button>
              ) : null}
              <GradeSelector
                emptyOptionLabel="Empty"
                selector={selector}
                filter={
                  showRanking
                    ? value =>
                        value.id !== FinalGrade.Poor && value.id !== FinalGrade.Empty
                    : undefined
                }
                renderInput={(open, setOpen, ref) => (
                  <Button
                    pending={pendingGradeUpdate}
                    variant="secondary"
                    size="sm"
                    useIcon={open ? ChevronUp : ChevronDown}
                    onClick={() => setOpen(!open)}
                    ref={ref}
                  >
                    Change
                  </Button>
                )}
                onChange={handleGradeChange}
              />
            </HStack>
          </Item.Side>
        ) : null}
      </Item>
    )
  }

  const handleGradeChange: typeof onGradeSelected = async props => {
    setPendingGradeUpdate(true)
    await onGradeSelected(props)
    setPendingGradeUpdate(false)
  }

  return isOpen && talent ? (
    <Fixed
      right={0}
      top={0}
      zIndex={theme.zIndex.sideBar}
      p="s-16"
      height="100vh"
      overflow="auto"
    >
      <Side open onClose={onClose} variant="wide" height="100%">
        <VStack space="s-16">
          <TextButton aria-label="Close" onClick={onClose} color={Color.FOREGROUND}>
            <Cross size={24} />
          </TextButton>
          <Pagination
            currentPage={currentIndex}
            total={total}
            onPageSelect={page => {
              history.replace({ search: '' })
              resetReviwersFilters()
              onPageChanaged(page)
            }}
            title="Calibration"
          />
          <UserCard
            userLink={getLocationDescriptor(
              pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, { id: talent.employee.id }),
            )}
            avatar={getAvatarUrl(talent.employee.avatar)}
            displayName={talent.employee.display_name || talent.employee.full_name}
            position={`${talent.specialisation?.name} - ${talent.seniority?.name}`}
          />
          <MoreBar style={{ padding: 0, margin: '0 0 16px 0' }}>
            {isLoadingSummary ? (
              <MoreBarSkeleton />
            ) : (
              <>
                {reviews && reviews.length ? (
                  <MoreBar.Action>
                    <OpenReviewButton
                      category={ReviewCategory.Performance}
                      employeeId={talent.employee.id}
                      cycleId={String(talent.cycle.id)}
                      p={0}
                    />
                  </MoreBar.Action>
                ) : null}
                {!!onReviewRequested && (
                  <MoreBar.Action onClick={onReviewRequested} useIcon={CommentAdd}>
                    Request Review
                  </MoreBar.Action>
                )}
                {!!onDelegate && canChangeGrade && (
                  <DelegateCalibrationMoreAction
                    talents={[talent]}
                    delegate={onDelegate}
                  />
                )}
              </>
            )}
          </MoreBar>
          {renderGradeWidget()}
          <VStack space="s-24">
            <ChatWidget
              canEdit={canChangeGrade}
              commentsApi={commentsApi}
              disableTodolistFeature={false}
            />
            {tabBar}
            {isLoadingSummary && <TextWidgetSkeleton />}
            {currentTab === 'Reviews' && !!summary && (
              <ReviewsSection
                data={summary}
                talent={talent}
                onFilterChanged={options => {
                  refresh(options, undefined, true)
                }}
              />
            )}
            {currentTab === 'Performance history' && (
              <PerformanceHistorySection
                employeeId={talent.employee.id}
                cycleId={talent.cycle.id}
              />
            )}
          </VStack>
        </VStack>
      </Side>
    </Fixed>
  ) : null
}

export default SummarySidebar
