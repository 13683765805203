import React, { useState } from 'react'
import pluralize from 'pluralize'
import {
  Box,
  Button,
  Flex,
  HStack,
  List,
  Spinner,
  Subheader,
  Token,
  VStack,
  Widget,
} from '@revolut/ui-kit'
import { Download, InfoOutline } from '@revolut/icons'

import FileUploader from '@components/Inputs/FileUploader/FileUploader'
import { BulkDataImportHistory } from '@src/features/BulkDataImport/BulkDataImportHistory'
import { ImportDataCategory } from '@src/interfaces/bulkDataImport'
import { useImportFile } from '@src/utils/useImportFile'

type HowToUploadGuideProps = {
  importName: string
}

export const HowToUploadGuide = ({ importName }: HowToUploadGuideProps) => {
  return (
    <Box>
      <Subheader>
        <Subheader.Title>How to guide</Subheader.Title>
      </Subheader>
      <List use="ol" variant="compact" color={Token.color.greyTone50}>
        <List.Item useIcon={<>1.</>}>
          Use our {importName} template by clicking “Download template”
        </List.Item>
        <List.Item useIcon={<>2.</>}>
          Fill in mandatory columns, provide optional information if necessary
        </List.Item>
        <List.Item useIcon={<>3.</>}>
          Upload your file by “Drag & drop or Click to attach document”
        </List.Item>
      </List>
    </Box>
  )
}

export interface BulkDataImportUploadFileProps {
  category: ImportDataCategory
  apiEndpoint: string
  name: string
  nextRoute: string
}

export const BulkDataImportUploadFile = ({
  category,
  apiEndpoint,
  name,
  nextRoute,
}: BulkDataImportUploadFileProps) => {
  const {
    importPending,
    downloadTemplatePending,
    onFileUpload,
    onDownloadTemplate,
    error,
  } = useImportFile(apiEndpoint, nextRoute, name, category)
  const importName = pluralize(name)
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)

  return (
    <>
      <VStack space="s-16">
        <HowToUploadGuide importName={importName} />
        <Widget p="s-24">
          <VStack>
            <HStack gap="s-16">
              {!!onDownloadTemplate && (
                <Button
                  onClick={onDownloadTemplate}
                  variant="secondary"
                  pending={downloadTemplatePending}
                  size="sm"
                  mb="s-16"
                  useIcon={Download}
                >
                  Download template
                </Button>
              )}
              <Button
                onClick={() => {
                  setIsSidebarOpen(!isSidebarOpen)
                }}
                variant="secondary"
                size="sm"
                mb="s-16"
                useIcon={InfoOutline}
              >
                Show history
              </Button>
            </HStack>
            {importPending ? (
              <Flex justifyContent="center">
                <Spinner />
              </Flex>
            ) : (
              <FileUploader
                label={`CSV/XLSX attachment`}
                onChange={onFileUpload}
                hideOptional
                name="file"
                error={error}
              />
            )}
          </VStack>
        </Widget>
      </VStack>

      {isSidebarOpen && (
        <BulkDataImportHistory
          endpoint={apiEndpoint}
          name={importName}
          nextRoute={nextRoute}
          isOpen={isSidebarOpen}
          onClose={() => {
            setIsSidebarOpen(false)
          }}
        />
      )}
    </>
  )
}
