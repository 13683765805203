import { useGetPerformanceSelector } from '@src/api/performance'
import { useGetReviewCycles } from '@src/api/reviewCycles'
import CycleSelector, { CycleSelectorProps } from './CycleSelector'
import { getGenericCyclesInfo } from '@src/features/FormTabs/Kpi/KPITargets/common/useAvailableCycles'
import { ReviewCycleStatus, ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import React, { useState } from 'react'

export const GoalTargetCycleSelector = ({
  onSelect,
  isEmployee,
  ownerId,
  initialCycle,
  ...selectorPropsOverride
}: {
  onSelect?: (cycle: ReviewCyclesInterface) => void
  isEmployee: boolean
  ownerId?: number
  initialCycle?: ReviewCyclesInterface
} & Partial<CycleSelectorProps>) => {
  const { data: employeeAvailableCycles } = useGetPerformanceSelector(ownerId)
  const { data: reviewCyclesData } = useGetReviewCycles()
  const [targetCycle, setTargetCycle] = useState<ReviewCyclesInterface | undefined>(
    initialCycle,
  )
  const getAvailableCyclesInfo = (targetIndex: number | null) => {
    const reviewCycles = reviewCyclesData?.results || []
    return getGenericCyclesInfo(reviewCycles, {
      targetIndex,
      allTargets: [],
    })
  }

  const { availableCycles } = getAvailableCyclesInfo(null)
  const employeeFilteredCycles = employeeAvailableCycles?.filter(
    cycle => cycle.status !== ReviewCycleStatus.closed,
  )
  const cycles = isEmployee ? employeeFilteredCycles || availableCycles : availableCycles

  const onChange = (cycle: ReviewCyclesInterface) => {
    onSelect && onSelect(cycle)
    setTargetCycle(cycle)
  }

  return (
    <>
      <CycleSelector
        value={targetCycle}
        reviewCycles={cycles}
        onSelect={onChange}
        {...selectorPropsOverride}
      />
    </>
  )
}
