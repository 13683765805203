import React, { useState } from 'react'
import { FinalGrade } from '@src/interfaces/performance'
import {
  TalentBulkGradeUpdateInterface,
  TalentPerformanceBulkGradeUpdateResponse,
  TalentPerformanceInterface,
} from '@src/interfaces/talent/performance'
import { TalentType } from '@src/interfaces/talent/talent'
import { ChevronDown, ChevronUp } from '@revolut/icons'
import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'
import { MoreBar, StatusPopup, Tooltip, useTooltip } from '@revolut/ui-kit'
import { bulkGradeUpdate } from '@src/api/talent'
import pluralize from 'pluralize'
import { isDepartmentType, useShowRanking } from './utils'
import { GradeSelector } from './SummarySidebar/GradeSelector'
import { useOrgEntity } from '@src/features/OrgEntityProvider/OrgEntityProvider'
import { getCalibrationFilters } from '@src/features/Calibration/getCalibrationFilters'
import { SelectorType } from '@src/interfaces/selectors'

interface BulkGradeAssignProps {
  talents: TalentPerformanceInterface[]
  talentType: TalentType
  onSuccess: (results: TalentPerformanceBulkGradeUpdateResponse[]) => void
  selector: SelectorType
}

export const BulkGradeAssign = ({
  talents,
  talentType,
  onSuccess,
  selector,
}: BulkGradeAssignProps) => {
  const { entity } = useOrgEntity()
  const tooltip = useTooltip()
  const [error, setError] = useState('')
  const [popup, setPopup] = useState<'confirm' | 'loading' | 'success' | 'error' | null>(
    null,
  )
  const closePopup = () => setPopup(null)
  const [payload, setPayload] = useState<TalentBulkGradeUpdateInterface[]>()
  const showRanking = useShowRanking(talentType)

  const preparePayload = (
    selectedGrade: FinalGrade,
  ): TalentBulkGradeUpdateInterface[] => {
    const talentsToUpdate =
      selectedGrade === FinalGrade.Empty
        ? talents.filter(row => !!row.performance_extra_grade_suggestion?.id)
        : talents

    return talentsToUpdate.slice(0, 100).map(row => {
      const newGrade: FinalGrade =
        selectedGrade === FinalGrade.Empty
          ? row.performance_extra_grade_suggestion?.id!
          : selectedGrade

      return isDepartmentType(talentType)
        ? {
            id: row.id,
            department_owner_grade_suggestion: newGrade,
          }
        : {
            id: row.id,
            function_owner_grade_suggestion: newGrade,
          }
    })
  }

  const updateGrade = async () => {
    if (!payload) {
      return
    }
    try {
      setError('')
      setPopup('loading')
      const { data } = await bulkGradeUpdate(payload, getCalibrationFilters(entity))

      if (data.results) {
        setPopup('success')
        onSuccess(data.results)
      } else {
        setPopup('error')
      }
    } catch (err) {
      if (Array.isArray(err?.response?.data) && err.response.data.length) {
        setError(err.response.data.join('. '))
      }
      setPopup('error')
    }
  }
  const onGradeSelected = (selectedGrade: { id: FinalGrade } | null) => {
    if (!selectedGrade) {
      return
    }
    const apiPayload = preparePayload(selectedGrade.id)

    if (!apiPayload.length) {
      return
    }

    setPayload(apiPayload)
    setPopup('confirm')
  }

  const disabled = !talents?.length

  return (
    <>
      <GradeSelector
        onChange={onGradeSelected}
        selector={selector}
        filter={
          showRanking
            ? value => value.id !== FinalGrade.Poor && value.id !== FinalGrade.Empty
            : undefined
        }
        renderInput={(open, setOpen, ref) => (
          <>
            <MoreBar.Action
              aria-disabled={disabled}
              {...tooltip.getAnchorProps({ ref })}
              useIcon={open ? ChevronUp : ChevronDown}
              onClick={() => !disabled && setOpen(!open)}
            >
              Assign grade
            </MoreBar.Action>
            {disabled && (
              <Tooltip {...tooltip.getTargetProps()}>
                Select at least 1 row from the table to bulk assign grades
              </Tooltip>
            )}
          </>
        )}
      />

      <ConfirmationDialog
        variant="compact"
        label={`Assign grades to ${payload?.length || 'all'} selected?`}
        open={popup === 'confirm' || popup === 'loading'}
        body="You can only assign in bulk to max 100 rows per action."
        onClose={closePopup}
        onConfirm={updateGrade}
        onReject={closePopup}
        yesMessage="Submit"
        noMessage="Cancel"
        loading={popup === 'loading'}
      />
      <StatusPopup variant="success" open={popup === 'success'} onClose={closePopup}>
        <StatusPopup.Title>
          {payload?.length || 'All'} {pluralize('grade', payload?.length)} accepted
        </StatusPopup.Title>
      </StatusPopup>
      <StatusPopup variant="error" open={popup === 'error'} onClose={closePopup}>
        <StatusPopup.Title>Failed to assign grades</StatusPopup.Title>
        {error && <StatusPopup.Description>{error}</StatusPopup.Description>}
      </StatusPopup>
    </>
  )
}
