import React from 'react'
import { Group } from '@revolut/ui-kit'
import { connect } from 'lape'
import { useSelector } from 'react-redux'
import { useQueryClient } from 'react-query'
import { FormattedMessage } from 'react-intl'
import { useGlobalSettings } from '@src/api/settings'
import { PageBody } from '@src/components/Page/PageBody'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import AutoStepper from '@src/components/Stepper/AutoStepper'
import NewStepperTitle from '@src/components/Stepper/NewStepperTitle'
import LapeNewSwitch from '@src/components/Inputs/LapeFields/LapeNewSwitch'
import { PageActions } from '@src/components/Page/PageActions'
import { InternalLink } from '@components/InternalLink/InternalLink'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import NewSelectionCard from '@components/NewSelectionCard/NewSelectionCard'
import { API } from '@src/constants/api'
import { ROUTES } from '@src/constants/routes'
import { APPLICATIONS } from '@src/constants/hub'
import { getAppOrTab } from '@src/features/HubAppIcon/utils'
import Form from '@src/features/Form/Form'
import { timeOffSettingsRequests } from '@src/api/settings'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'

const GeneralForm = () => {
  const queryClient = useQueryClient()
  const {
    settings: { attendance_enabled },
  } = useGlobalSettings()
  const permissions = useSelector(selectPermissions)
  const disableEdit = !permissions.includes(PermissionTypes.ChangeTimeOffPreferences)
  const canViewTimeOffPolicyCategory = permissions.includes(
    PermissionTypes.ViewTimeOffPolicyCategory,
  )
  const canViewPublicHolidays = permissions.includes(PermissionTypes.ViewPublicHolidays)
  const canViewTimeOffRegimes = permissions.includes(PermissionTypes.ViewTimeOffRegimes)
  const canViewAttendanceTrackingPreferences = permissions.includes(
    PermissionTypes.ViewAttendanceTrackingPreferences,
  )
  const attendanceTab = getAppOrTab(permissions, APPLICATIONS.attendance)
  const canViewAttendancePolicies = !!attendance_enabled && !!attendanceTab

  return (
    <>
      <PageHeader
        title="Time management settings"
        subtitle="Settings for the time off functionalities"
        backUrl={ROUTES.SETTINGS.ALL}
      />

      <PageBody>
        <Group>
          {canViewTimeOffPolicyCategory && (
            <NewSelectionCard
              icon="Controls"
              title="Time off categories"
              to={ROUTES.SETTINGS.TIME_OFF.CATEGORIES}
              subtitle={
                <FormattedMessage
                  id="settings.documents.categoriesSubtitle"
                  defaultMessage="Settings for managing leave categories"
                />
              }
              use={InternalLink}
            />
          )}
          {canViewPublicHolidays && (
            <NewSelectionCard
              icon="CalendarDate"
              title="Public holidays"
              to={ROUTES.SETTINGS.TIME_OFF.HOLIDAYS}
              subtitle={
                <FormattedMessage
                  id="settings.documents.holidaysSubtitle"
                  defaultMessage="Settings for managing public holidays by country & region"
                />
              }
              use={InternalLink}
            />
          )}
          {canViewTimeOffRegimes && (
            <NewSelectionCard
              icon="CalendarWeek"
              title="Regimes"
              to={ROUTES.SETTINGS.TIME_OFF.REGIMES}
              subtitle={
                <FormattedMessage
                  id="settings.documents.regimesSubtitle"
                  defaultMessage="Settings for managing when employees work"
                />
              }
              use={InternalLink}
            />
          )}
          {canViewAttendancePolicies && (
            <NewSelectionCard
              icon="CalendarWeek"
              title="Attendance policies"
              to={attendanceTab.url}
              subtitle={
                <FormattedMessage
                  id="settings.documents.attendancePoliciesSubtitle"
                  defaultMessage="Settings for managing attendance policies"
                />
              }
              use={InternalLink}
            />
          )}
          {canViewAttendanceTrackingPreferences && (
            <NewSelectionCard
              icon="CalendarWeek"
              title="Attendance settings"
              to={ROUTES.SETTINGS.ATTENDANCE}
              subtitle={
                <FormattedMessage
                  id="settings.documents.attendanceSettingsSubtitle"
                  defaultMessage="Settings for the attendance app"
                />
              }
              use={InternalLink}
            />
          )}
        </Group>
        <AutoStepper>
          <NewStepperTitle
            noAutoStep
            title="General"
            subtitle="Settings that apply for all time off functionalities"
          />
          <LapeNewSwitch
            itemTypeProps={{
              title: 'Enable the time off feature',
            }}
            name="enabled"
            disabled={disableEdit}
          />
        </AutoStepper>

        <PageActions>
          <NewSaveButtonWithPopup
            onAfterSubmit={() => {
              queryClient.invalidateQueries(API.TIME_OFF_SETTINGS)
            }}
            useValidator
            successText="Settings saved"
          />
        </PageActions>
      </PageBody>
    </>
  )
}

export const General = connect(() => (
  /** id: 1 is hardcoded on the BE */
  <Form api={timeOffSettingsRequests} forceParams={{ id: '1' }}>
    <GeneralForm />
  </Form>
))
