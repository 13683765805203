import { FilterByInterface } from '@src/interfaces/data'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'

export const getCommonFilters = ({
  reviewCycle,
}: {
  reviewCycle: ReviewCyclesInterface
}): FilterByInterface[] => {
  return [
    {
      filters: [{ id: String(reviewCycle.id), name: reviewCycle.name }],
      columnName: 'review_cycle__id',
      nonResettable: true,
    },
  ]
}
