import { useLapeContext } from '@src/features/Form/LapeForm'
import { useGoalFormCache } from '../GoalFormPage'
import { useFormObserver } from './Widgets/FormObserverProvider'
import { GoalsInterface } from '@src/interfaces/goals'
import { Statuses } from '@src/interfaces'
import { useSubmitFlowHelpers } from '../common/utils'

export const useGoalFormSubmit = () => {
  const goalForm = useLapeContext<GoalsInterface & { targets: unknown }>()
  const { values, submit } = goalForm
  const { getFormsGetters, unregisterForm } = useFormObserver()
  const { cleanCache } = useGoalFormCache()
  const { showLoading } = useSubmitFlowHelpers()

  // eslint-disable-next-line consistent-return
  const doSubmit = async (updateStatus: boolean) => {
    const targetFormsGetters = getFormsGetters()
    let targetsError: unknown = false

    const hideLoading = showLoading('Saving...')

    if (values.update_type?.id === 'target_based' && targetFormsGetters.length) {
      /*
          we need sequence of requests because BE blocks DB per request 
          error example: 
              deadlock detected
              DETAIL:  Process 2843751 waits for ShareLock on transaction 1046283; blocked by process 2843674.
              Process 2843674 waits for ShareLock on transaction 1046282; blocked by process 2843751.
              HINT:  See server log for query details.
              CONTEXT:  while updating tuple (115,42) in relation "kpi_target"
          */
      for (const getter of targetFormsGetters) {
        const { form, validator } = getter()
        const formId = form.values.id || form.values.tempId

        if (!form.dirty && !form.values.tempId) {
          // eslint-disable-next-line no-continue
          continue
        }

        form.apiErrors = {}
        const cb = validator?.validate
          ? // eslint-disable-next-line no-loop-func
            validator.validate(async () => {
              try {
                const result = await form.submit()
                const goalTarget = {
                  ...result,
                  status: { id: result.status, name: result.status },
                }
                formId && unregisterForm(formId)
                values.kpis = values.kpis.map(kpi =>
                  kpi.tempId === formId ? goalTarget : kpi,
                )
                return goalTarget
              } catch (err) {
                targetsError = err
                throw err
              }
            })
          : form.submit

        // eslint-disable-next-line no-await-in-loop
        await cb()
      }
    }

    if (!targetsError) {
      let statusUpdated = false
      goalForm.apiErrors = {}

      if (values.status.id === Statuses.draft && updateStatus) {
        // business requirement to transit from draft status on a first submit
        values.status = { id: Statuses.on_track, name: 'On track ' }
        statusUpdated = true
      }

      if (!values.name) {
        // only empty string will trigger BE validation error in case of empty
        values.name = ''
      }

      try {
        const result = await submit()
        cleanCache()
        return result
      } catch (err) {
        if (statusUpdated) {
          values.status = { id: Statuses.draft, name: 'Draft' }
        }
        throw err
      } finally {
        hideLoading()
      }
    } else {
      hideLoading()
      // eslint-disable-next-line no-throw-literal
      throw targetsError
    }
  }

  return { submit: doSubmit }
}
