import React, { useEffect, useRef } from 'react'
import {
  PerformanceReviewTypes,
  ReviewerRelation,
  ReviewScorecardInterface,
} from '@src/interfaces/performance'
import { connect } from 'lape'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { Card, CardField } from '@src/pages/Forms/EmployeePerformanceLayout/Card'
import {
  CardContentTypes,
  CommonCardProps,
  cultureOptionsNewGrades,
  getRoundedRating,
  GradeOption,
  onPrefillCultureValue,
  onPrefillWithGrade,
  shouldScrollToError,
  updateValueRating,
  usePrefillSkillsWithPreviousQuarter,
} from '@src/pages/Forms/EmployeePerformanceLayout/utils'
import { getNormalizedCards } from '@src/utils/performance'
import { get, set } from 'lodash'
import { StatusPopup, useStatusPopup } from '@revolut/ui-kit'
import { LoadingCard } from '@src/pages/Forms/EmployeePerformanceLayout/components/LoadingCard'
import { HelpTabs } from '@src/pages/Forms/EmployeePerformance/components/HelpSections/CombinedHelp'
import { Skills } from '@src/pages/Forms/EmployeePerformanceLayout/Sections/Skills'
import { useRecommendedGradesContext } from '@src/pages/Forms/EmployeePerformanceLayout/ScorecardContent'
import {
  getSkillsRatingOptions,
  getValuesRatingOptions,
} from '@src/pages/Forms/EmployeePerformanceLayout/Cards/utils'
import { AssessBehaviourButtonTypes } from '@components/AssessButtons/AssessBehaviourButtons'

interface SkillsCardInterface extends CommonCardProps {
  skillsMissingJustification?: number[]
}

export const SkillsCard = connect(
  ({ onHelpClick, gradesMap, skillsMissingJustification }: SkillsCardInterface) => {
    const { values, errors } = useLapeContext<ReviewScorecardInterface>()
    const ref = useRef<HTMLDivElement>(null)
    const statusPopup = useStatusPopup()
    const { grades } = useRecommendedGradesContext()

    const canViewSkills =
      !!values?.review_data?.functional_skills || !!values?.review_data?.manager_values

    useEffect(() => {
      const shouldScrollSkills = shouldScrollToError(
        errors,
        'review_data.functional_skills',
      )
      const shouldScrollValues = shouldScrollToError(errors, 'review_data.manager_values')
      if (shouldScrollSkills || shouldScrollValues) {
        ref?.current?.scrollIntoView({ behavior: 'smooth' })
      }
    }, [errors.review_data])

    const { prefillCompleted } = usePrefillSkillsWithPreviousQuarter(
      values,
      values?.review_data?.functional_skills?.cards,
    )

    // pre-filling manager values, if needed
    useEffect(() => {
      if (
        values.review_data.manager_values?.cards?.[0].sections[0] &&
        // if first found section has value, then all cards were already pre-filled
        !values.review_data.manager_values.cards[0].sections[0].value
      ) {
        values.review_data.manager_values?.cards?.forEach((card, cardIndex) => {
          card.sections.forEach(section => {
            section.value =
              section.previous_values?.[0]?.value || AssessBehaviourButtonTypes.neutral
          })
          updateValueRating({
            values,
            path: `review_data.manager_values.cards.${cardIndex}`,
          })
        })
      }
    }, [values.review_data.manager_values?.cards])

    if (!canViewSkills) {
      return null
    }

    const hasManagerValues = !!values.review_data?.manager_values

    const managerValuesCards = values?.review_data?.manager_values?.cards || []
    const managerValuesFields =
      managerValuesCards.map((card, ind) => ({
        field: `review_data.manager_values.cards.${ind}`,
        title: card.name,
        grades: getValuesRatingOptions(
          cultureOptionsNewGrades,
          ind,
          values.review_data.manager_values?.cards,
        ) as GradeOption[],
        gradeRecommendation: null,
        cardIndex: ind,
      })) || []
    const functionalSkillsCards = getNormalizedCards(
      values.review_data?.functional_skills?.cards || [],
    )
    const functionalSkillsFields = functionalSkillsCards.map((card, ind) => ({
      field: `review_data.functional_skills.cards.${ind}`,
      title: card.name,
      grades: getSkillsRatingOptions(ind, values.review_data?.functional_skills?.cards),
      gradeRecommendation: card.rating_expectation || null,
      cardIndex: ind,
    }))
    const skillsFields = hasManagerValues ? managerValuesFields : functionalSkillsFields

    const onSelectGrade = (grade: GradeOption, field: CardField) => {
      if (hasManagerValues) {
        onPrefillCultureValue(values, grade, field.field)
        updateValueRating({ values, path: field.field })
      } else {
        const currentRating = get(values, `${field.field}.rating`)
        set(values, `${field.field}.rating`, grade.key)
        try {
          onPrefillWithGrade(
            values,
            grade,
            PerformanceReviewTypes.skills,
            field.cardIndex,
          )
        } catch {
          set(values, `${field.field}.rating`, currentRating)
          statusPopup.show(
            <StatusPopup variant="error">
              <StatusPopup.Title>Could not recalculate rating</StatusPopup.Title>
            </StatusPopup>,
          )
        }
      }
    }

    if (!prefillCompleted) {
      return <LoadingCard />
    }

    const isSelfReview = values.reviewer_relation === ReviewerRelation.Self
    const finalRating =
      !isSelfReview && grades?.skillsGrade ? gradesMap[grades?.skillsGrade] : undefined

    return (
      <Card
        data={values}
        renderExpandedContent={expContentField => (
          <Skills selectedField={expContentField} skillsMissingJustification={null} />
        )}
        renderExceedingContent={excContentField => (
          <Skills
            selectedField={excContentField}
            justificationOnly
            skillsMissingJustification={skillsMissingJustification || null}
          />
        )}
        type={CardContentTypes.SKILLS}
        title={hasManagerValues ? 'Management values' : 'Skills'}
        finalRating={finalRating}
        icon="Palette"
        fields={skillsFields}
        isGradeSelectedRule={(field, grade) => {
          const ratingValue = get(values, field)?.rating
          if (!ratingValue) {
            return false
          }
          return hasManagerValues
            ? ratingValue === grade.key
            : getRoundedRating(ratingValue) === grade.key
        }}
        onSelectGrade={onSelectGrade}
        justification={
          values?.review_data?.manager_values?.skipped_section_justification ||
          values?.review_data?.functional_skills?.skipped_section_justification
        }
        headerRef={ref}
        onHelpClick={() => onHelpClick?.(HelpTabs.Skills)}
      />
    )
  },
)
