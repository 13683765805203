import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { MoreBar } from '@revolut/ui-kit'

import { API } from '@src/constants/api'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { PermissionTypes } from '@src/store/auth/types'
import { employeeRecordsConfig } from '@src/pages/OnboardingChecklistV2/common/checkpointsConfig'
import { OnboardingChecklistContent } from '@src/pages/OnboardingChecklistV2/components/OnboardingChecklistContent'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { SelectBulkUploadFlow } from '@src/apps/People/Employees/BulkUpload/SelectBulkUploadFlow'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { EditableContractsBulkSession } from '@src/features/EditableContractsTable/BulkSession'
import { EditableEmployeesTableDetailed } from '@src/features/EditableEmployeesTable/TableDetailed'
import { EmployeesBulkSessionDetailed } from '@src/features/EditableEmployeesTable/TableDetailed/BulkSession'
import { EmployeesBulkSessionDynamic } from '@src/features/EditableEmployeesTable/TableDynamic/BulkSession'
import { useQuery } from '@src/utils/queryParamsHooks'
import { EmployeeRecordsIntro } from './EmployeeRecordsIntro'

const routes = [
  {
    title: 'Intro',
    path: ROUTES.ONBOARDING_CHECKLIST_V2.EMPLOYEE_RECORDS.INTRO,
    url: ROUTES.ONBOARDING_CHECKLIST_V2.EMPLOYEE_RECORDS.INTRO,
    canView: [PermissionTypes.ViewEmployeePreferences],
    component: EmployeeRecordsIntro,
  },
  {
    title: 'Update',
    path: ROUTES.ONBOARDING_CHECKLIST_V2.EMPLOYEE_RECORDS.UPDATE,
    url: ROUTES.ONBOARDING_CHECKLIST_V2.EMPLOYEE_RECORDS.UPDATE,
    canView: [PermissionTypes.ViewEmployeePreferences],
    component: () => (
      <EditableEmployeesTableDetailed
        sessionRoute={
          ROUTES.ONBOARDING_CHECKLIST_V2.EMPLOYEE_RECORDS.UPDATE_EMPLOYEES.SESSION
        }
        renderEditActionsLeft={() => (
          <MoreBar.Action
            use={InternalLink}
            to={
              ROUTES.ONBOARDING_CHECKLIST_V2.EMPLOYEE_RECORDS.UPLOAD_EMPLOYEES.SELECT_FLOW
            }
            useIcon="Upload"
          >
            Import employees
          </MoreBar.Action>
        )}
      />
    ),
    isWide: true,
  },
]

export const EmployeeRecords = () => {
  const { query } = useQuery()

  return (
    <Switch>
      <Route path={ROUTES.ONBOARDING_CHECKLIST_V2.EMPLOYEE_RECORDS.UPDATE_EMPLOYEES.ANY}>
        <Route
          path={[
            ROUTES.ONBOARDING_CHECKLIST_V2.EMPLOYEE_RECORDS.UPDATE_EMPLOYEES.IMPORT,
            ROUTES.ONBOARDING_CHECKLIST_V2.EMPLOYEE_RECORDS.UPDATE_EMPLOYEES.SESSION,
          ]}
        >
          <EmployeesBulkSessionDetailed
            importRoute={
              ROUTES.ONBOARDING_CHECKLIST_V2.EMPLOYEE_RECORDS.UPDATE_EMPLOYEES.IMPORT
            }
            sessionRoute={
              ROUTES.ONBOARDING_CHECKLIST_V2.EMPLOYEE_RECORDS.UPDATE_EMPLOYEES.SESSION
            }
            anyRoute={
              ROUTES.ONBOARDING_CHECKLIST_V2.EMPLOYEE_RECORDS.UPDATE_EMPLOYEES.ANY
            }
            onAfterConfirmRoute={ROUTES.ONBOARDING_CHECKLIST_V2.EMPLOYEE_RECORDS.UPDATE}
            apiEndpoint={API.EMPLOYEE_UPLOADS}
            getHeader={() => (
              <PageHeader
                title="Update employees"
                backUrl={pathToUrl(
                  ROUTES.ONBOARDING_CHECKLIST_V2.EMPLOYEE_RECORDS.UPDATE,
                )}
              />
            )}
          />
        </Route>
      </Route>
      <Route path={ROUTES.ONBOARDING_CHECKLIST_V2.EMPLOYEE_RECORDS.UPLOAD_EMPLOYEES.ANY}>
        <Route
          path={
            ROUTES.ONBOARDING_CHECKLIST_V2.EMPLOYEE_RECORDS.UPLOAD_EMPLOYEES.SELECT_FLOW
          }
        >
          <SelectBulkUploadFlow
            importEmployeesRoute={
              ROUTES.ONBOARDING_CHECKLIST_V2.EMPLOYEE_RECORDS.UPLOAD_EMPLOYEES.IMPORT
            }
            importContractsRoute={
              ROUTES.ONBOARDING_CHECKLIST_V2.EMPLOYEE_RECORDS.UPLOAD_CONTRACTS.IMPORT
            }
          />
        </Route>
        <Route
          path={[
            ROUTES.ONBOARDING_CHECKLIST_V2.EMPLOYEE_RECORDS.UPLOAD_EMPLOYEES.IMPORT,
            ROUTES.ONBOARDING_CHECKLIST_V2.EMPLOYEE_RECORDS.UPLOAD_EMPLOYEES.SESSION,
          ]}
        >
          <EmployeesBulkSessionDynamic
            importRoute={
              ROUTES.ONBOARDING_CHECKLIST_V2.EMPLOYEE_RECORDS.UPLOAD_EMPLOYEES.IMPORT
            }
            sessionRoute={
              ROUTES.ONBOARDING_CHECKLIST_V2.EMPLOYEE_RECORDS.UPLOAD_EMPLOYEES.SESSION
            }
            anyRoute={
              ROUTES.ONBOARDING_CHECKLIST_V2.EMPLOYEE_RECORDS.UPLOAD_EMPLOYEES.ANY
            }
            onAfterConfirmRoute={ROUTES.ONBOARDING_CHECKLIST_V2.EMPLOYEE_RECORDS.UPDATE}
            apiEndpoint={API.EMPLOYEE_UPLOADS}
            getHeader={() => (
              <PageHeader
                title={
                  query.updateEmployees
                    ? 'Update existing employees’ information'
                    : 'Create multiple employees'
                }
                backUrl={pathToUrl(
                  ROUTES.ONBOARDING_CHECKLIST_V2.EMPLOYEE_RECORDS.UPDATE,
                )}
              />
            )}
          />
        </Route>
      </Route>
      <Route path={ROUTES.ONBOARDING_CHECKLIST_V2.EMPLOYEE_RECORDS.UPLOAD_CONTRACTS.ANY}>
        <Route
          path={[
            ROUTES.ONBOARDING_CHECKLIST_V2.EMPLOYEE_RECORDS.UPLOAD_CONTRACTS.IMPORT,
            ROUTES.ONBOARDING_CHECKLIST_V2.EMPLOYEE_RECORDS.UPLOAD_CONTRACTS.SESSION,
          ]}
        >
          <EditableContractsBulkSession
            importRoute={
              ROUTES.ONBOARDING_CHECKLIST_V2.EMPLOYEE_RECORDS.UPLOAD_CONTRACTS.IMPORT
            }
            sessionRoute={
              ROUTES.ONBOARDING_CHECKLIST_V2.EMPLOYEE_RECORDS.UPLOAD_CONTRACTS.SESSION
            }
            anyRoute={
              ROUTES.ONBOARDING_CHECKLIST_V2.EMPLOYEE_RECORDS.UPLOAD_CONTRACTS.ANY
            }
            onAfterConfirmRoute={ROUTES.ONBOARDING_CHECKLIST_V2.EMPLOYEE_RECORDS.UPDATE}
            apiEndpoint={API.CONTRACTS_UPLOAD}
            renderHeader={() => (
              <PageHeader
                title="Create new contracts"
                backUrl={pathToUrl(
                  ROUTES.ONBOARDING_CHECKLIST_V2.EMPLOYEE_RECORDS.UPDATE,
                )}
              />
            )}
          />
        </Route>
      </Route>
      <Route>
        <OnboardingChecklistContent config={employeeRecordsConfig} routes={routes} />
      </Route>
    </Switch>
  )
}
