import React, { useState } from 'react'
import { JobPostingInterface, PublishingStatuses } from '@src/interfaces/jobPosting'
import AdjustableTable from '@components/Table/AdjustableTable'
import {
  ColumnCellInterface,
  FilterByInterface,
  RowInterface,
  SORT_DIRECTION,
} from '@src/interfaces/data'
import { Statuses } from '@src/interfaces'
import { getColor } from '@src/styles/colors'
import {
  Action,
  Bar,
  Box,
  Color,
  FilterButton,
  Flex,
  MoreBar,
  Widget,
} from '@revolut/ui-kit'
import {
  jobDescriptionActionColumn,
  jobDescriptionGenericNameColumn,
  jobDescriptionCandidatePipelineColumn,
  jobDescriptionLocationsColumn,
  jobDescriptionPublishedStatusColumn,
  jobDescriptionRecruiterColumn,
  jobDescriptionSpecialisationColumn,
  jobDescriptionStatusColumn,
  jobDescriptionUsedInRequisitionsCountNameColumn,
} from '@src/constants/columns/jobDescription'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useTable } from '@components/Table/hooks'
import { jobPostingsRequests } from '@src/api/jobPosting'
import SearchTable from '@components/Table/SearchTable/SearchTable'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import { TableNames } from '@src/constants/table'
import { useNavigateToJobPostingPath } from '../JobPostingFlow/utils'
import { JobPostingStats } from './JobPostingStats'

type JobDescriptionActionInsertProps = {
  data: JobPostingInterface
  onView: () => void
}

const JobDescriptionActionInsert = ({
  data,
  onView,
}: JobDescriptionActionInsertProps) => {
  const navigateToJobPosting = useNavigateToJobPostingPath()
  return (
    <Flex>
      <Action
        onClick={() => {
          navigateToJobPosting({
            id: data?.id,
            specialisationId: data?.specialisation?.id,
          })
        }}
      >
        Edit
      </Action>
      <Box ml="s-8">
        <Action onClick={onView}>View</Action>
      </Box>
    </Flex>
  )
}

const Row = (
  setOpenJobPostingId?: (id: null | number) => void,
  specialisationId?: number,
): RowInterface<JobPostingInterface> => ({
  linkToForm: data => {
    setOpenJobPostingId
      ? setOpenJobPostingId(data.id)
      : navigateTo(
          pathToUrl(ROUTES.FORMS.JOB_POSTING.PREVIEW, {
            specId: data?.specialisation?.id,
            id: data?.id,
          }),
        )
  },
  highlight: (data, theme) =>
    data.status === PublishingStatuses.closed ? getColor(theme, Color.GREY_TONE_5) : '',
  cells: [
    {
      ...jobDescriptionGenericNameColumn,
      width: 250,
    },
    {
      ...jobDescriptionCandidatePipelineColumn,
      width: 200,
    },
    !specialisationId
      ? {
          ...jobDescriptionSpecialisationColumn,
          width: 250,
        }
      : undefined,
    {
      ...jobDescriptionRecruiterColumn,
      width: 200,
    },
    {
      ...jobDescriptionLocationsColumn,
      width: 250,
    },
    {
      ...jobDescriptionUsedInRequisitionsCountNameColumn,
      width: 200,
    },
    {
      ...jobDescriptionPublishedStatusColumn,
      width: 250,
    },
    {
      ...jobDescriptionStatusColumn,
      width: 250,
    },
    setOpenJobPostingId
      ? ({
          ...jobDescriptionActionColumn,
          width: 230,
          insert: ({ data }) => {
            return (
              <JobDescriptionActionInsert
                data={data}
                onView={() => setOpenJobPostingId(data.id)}
              />
            )
          },
        } as ColumnCellInterface<JobPostingInterface>)
      : undefined,
  ].filter(i => i) as ColumnCellInterface<JobPostingInterface>[],
})

type Props = {
  specialisationId?: number
  setOpenJobPostingId?: (id: number | null) => void
  actions?: React.ReactNode
}

export const JobPostingsTable = ({
  setOpenJobPostingId,
  specialisationId,
  actions,
}: Props) => {
  const user = useSelector(selectUser)
  const [showArchivedPostings, setShowArchivedPostings] = useState(false)
  const [showMyPostings, setShowMyPostings] = useState(false)

  const initialFilterBy = [
    specialisationId
      ? {
          columnName: 'specialisation__id',
          filters: [{ id: specialisationId, name: `${specialisationId}` }],
          nonResettable: true,
        }
      : undefined,
    {
      columnName: 'approval_status',
      filters: [
        { id: Statuses.pending, name: Statuses.pending },
        { id: Statuses.approved, name: Statuses.approved },
      ],
    },
  ].filter(i => i) as FilterByInterface[]

  const initialSortBy = [
    {
      sortBy: 'id',
      direction: SORT_DIRECTION.DESC,
    },
  ]

  const table = useTable<JobPostingInterface>(
    jobPostingsRequests,
    initialFilterBy,
    initialSortBy,
  )

  const onSwitchMyPostings = () => {
    table.onFilterChange([
      {
        columnName: 'recruiter__id',
        filters: showMyPostings
          ? []
          : [
              {
                id: user.id,
                name: String(user.id),
              },
            ],
      },
    ])

    setShowMyPostings(!showMyPostings)
  }

  const onSwitchArchived = () => {
    table.onFilterChange([
      {
        columnName: 'approval_status',
        filters: showArchivedPostings
          ? [
              {
                id: 'approved',
                name: 'approved',
              },
              {
                id: 'pending',
                name: 'pending',
              },
            ]
          : [
              {
                id: 'archived',
                name: 'archived',
              },
            ],
      },
    ])

    setShowArchivedPostings(!showArchivedPostings)
  }

  return (
    <Flex flexDirection="column" width="100%">
      <Flex flexWrap="wrap" justifyContent="space-between">
        <Flex mb="s-24">
          <JobPostingStats
            filters={table.filterBy}
            onFilterChange={table.onFilterChange}
          />
        </Flex>
        <SearchTable
          placeholder="Search by title or specialisation"
          ml={0}
          onFilter={table.onFilterChange}
        />
      </Flex>
      <Flex mb="s-16" justifyContent="space-between">
        <MoreBar maxCount={3}>{actions}</MoreBar>
        <Bar>
          <FilterButton active={showMyPostings} onClick={onSwitchMyPostings}>
            Assigned to me
          </FilterButton>
          <FilterButton active={showArchivedPostings} onClick={onSwitchArchived}>
            Archived postings
          </FilterButton>
        </Bar>
      </Flex>

      <Flex style={{ position: 'relative' }} flex="1 0">
        <AdjustableTable<JobPostingInterface>
          name={TableNames.JobPostings}
          useWindowScroll
          dataType="Job postings"
          row={Row(setOpenJobPostingId, specialisationId)}
          {...table}
          noDataMessage="Job postings will appear here."
        />
      </Flex>
    </Flex>
  )
}

export const JobPostingsTableWidget = (props: Props) => {
  return (
    <Widget p="s-16" width="100%">
      <JobPostingsTable {...props} />
    </Widget>
  )
}
