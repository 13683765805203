import React from 'react'
import { InputGroup } from '@revolut/ui-kit'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { JobPostingInterface } from '@src/interfaces/jobPosting'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import ApplicationFormEditQuestion from '@src/pages/Forms/JobPosting/ApplicationForm/ApplicationFormEditQuestion'
import { Button, Side } from '@revolut/ui-kit'
import { ApplicationFormEnumQuestionOptions } from '@src/interfaces/applicationForm'
import SideBar from '@components/SideBar/SideBar'

type Props = {
  idx: number | null
  onClose: () => void
}

const ApplicationFormEditSection = ({ idx, onClose }: Props) => {
  const { values } = useLapeContext<JobPostingInterface>()
  const section = idx === null ? undefined : values.application_form_sections?.[idx]

  if (idx === null || !section) {
    return null
  }

  const onClickAddQuestion = () => {
    section.questions.push({
      question_type: ApplicationFormEnumQuestionOptions[0],
      options: [],
    })
  }

  return (
    <SideBar variant="wide" isOpen onClose={onClose} title="Edit section">
      <InputGroup>
        <LapeNewInput
          name={`application_form_sections.${idx}.title`}
          data-testid={`application_form_sections.${idx}.title`}
          label="Section title"
          required
        />
        <LapeNewInput
          name={`application_form_sections.${idx}.description`}
          data-testid={`application_form_sections.${idx}.description`}
          label="Section description"
        />
      </InputGroup>
      {section.questions.map((_, questionIdx) => (
        <ApplicationFormEditQuestion
          key={questionIdx}
          sectionIdx={idx}
          questionIdx={questionIdx}
        />
      ))}
      <Button
        useIcon="Plus"
        variant="secondary"
        size="sm"
        onClick={onClickAddQuestion}
        mt="s-16"
        mb="s-40"
      >
        Add question
      </Button>
      <Side.Actions>
        <Button aria-label="Confirm section" onClick={onClose}>
          Confirm
        </Button>
      </Side.Actions>
    </SideBar>
  )
}

export default ApplicationFormEditSection
