import React, { useEffect, useMemo, useState } from 'react'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  NotCompletedReviewInterface,
  NotCompletedReviewsInterface,
  ReviewCategory,
  ReviewScorecardInterface,
  ReviewSummaryInterface,
} from '@src/interfaces/performance'
import { pathToUrl } from '@src/utils/router'
import { navigateReplace } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'
import { Flex, DetailsCell, Spinner, Button, chain, Token, Box } from '@revolut/ui-kit'
import { NavigationBack, NavigationForward, ViewListMedium } from '@revolut/icons'
import { useParams } from 'react-router-dom'
import { Statuses } from '@src/interfaces'
import { getLastTabLocalStorageKey, useHasNewScorecards } from '@src/utils/performance'
import { workspaceLocalStorage } from '@src/features/Workspaces/workspaceLocalStorage'
import { SwitchItemsSidebar } from '@components/SwitchItemsSidebar/SwitchItemsSidebar'

const commonButtonProps = {
  variant: 'text',
  size: 'sm',
  color: 'grey-tone-50',
  p: 0,
} as const

interface Props {
  reviews: NotCompletedReviewsInterface
  noMaxWidth?: boolean
}

export const PerformanceHeaderNavigationBar = ({
  reviews,
  noMaxWidth = false,
}: Props) => {
  const { values } = useLapeContext<ReviewScorecardInterface | ReviewSummaryInterface>()
  const [pending, setPending] = useState(false)
  const [sidebarOpened, setSidebarOpened] = useState(false)
  const { id } = useParams<{ id: string; employeeId: string }>()
  const showNewScorecards = useHasNewScorecards()
  const filteredReviews = reviews.results?.filter(
    review => review.status === Statuses.pending || review.status === Statuses.draft,
  )
  const currentReviewIndex = filteredReviews?.findIndex(
    review => review.id === Number(id),
  )

  useEffect(() => setPending(false), [values])

  const switchItemsSidebarOptions = useMemo(
    () =>
      filteredReviews?.map(item => ({
        id: item.id,
        avatar: {
          id: item.reviewed_employee?.id,
          name: item.reviewed_employee?.full_name,
          img: item.reviewed_employee?.avatar,
        },
        status: item.status,
        category: item.category,
      })),
    [filteredReviews],
  )

  if (currentReviewIndex === -1 || currentReviewIndex === undefined) {
    return null
  }

  const switchReview = (review: NotCompletedReviewInterface) => {
    const reviewLastTabRoute = workspaceLocalStorage.getItem(
      getLastTabLocalStorageKey(review.category, review.reviewed_employee.id, review.id),
    )
    switch (review?.category) {
      case ReviewCategory.Upwards:
        setPending(true)
        navigateReplace(
          showNewScorecards
            ? pathToUrl(ROUTES.FORMS.UPWARDS_REVIEW_LAYOUT, {
                employeeId: review.reviewed_employee.id,
                id: review.id,
              })
            : reviewLastTabRoute ||
                pathToUrl(ROUTES.FORMS.UPWARDS_REVIEW.SKILLS, {
                  employeeId: review.reviewed_employee.id,
                  id: review.id,
                }),
        )
        break
      case ReviewCategory.Performance:
        setPending(true)
        navigateReplace(
          showNewScorecards
            ? pathToUrl(ROUTES.FORMS.EMPLOYEE_PERFORMANCE_LAYOUT, {
                employeeId: review.reviewed_employee.id,
                id: review.id,
              })
            : reviewLastTabRoute ||
                pathToUrl(ROUTES.FORMS.EMPLOYEE_PERFORMANCE.GENERAL, {
                  employeeId: review.reviewed_employee.id,
                  id: review.id,
                }),
        )
        break
      case ReviewCategory.Probation:
        setPending(true)
        navigateReplace(
          showNewScorecards
            ? pathToUrl(ROUTES.FORMS.PROBATION_REVIEW_LAYOUT, {
                employeeId: review.reviewed_employee.id,
                id: review.id,
              })
            : reviewLastTabRoute ||
                pathToUrl(ROUTES.FORMS.PROBATION_REVIEW.DELIVERABLES, {
                  employeeId: review.reviewed_employee.id,
                  id: review.id,
                }),
        )
        break
    }
  }
  const handlePreviousClick = () => {
    const previousReview =
      currentReviewIndex === 0
        ? filteredReviews[filteredReviews?.length - 1]
        : filteredReviews[currentReviewIndex - 1]
    switchReview(previousReview)
  }

  const handleNextClick = () => {
    const nextReview =
      currentReviewIndex === filteredReviews?.length - 1
        ? filteredReviews[0]
        : filteredReviews[currentReviewIndex + 1]
    switchReview(nextReview)
  }

  return (
    <DetailsCell
      my="s-10"
      color="grey-tone-50"
      style={{ maxWidth: noMaxWidth ? undefined : '1055px' }}
    >
      <DetailsCell.Title
        variant="secondary"
        fontSize="15px"
        lineHeight="22px"
        fontWeight={500}
      >
        {chain(
          'Performance review scorecards',
          `${currentReviewIndex + 1}/${reviews.count}`,
        )}
      </DetailsCell.Title>
      <Flex alignItems="center" style={{ height: '22px', fontWeight: 'normal' }}>
        {pending && <Spinner mr="s-12" />}
        {!pending && (
          <>
            <Button
              {...commonButtonProps}
              onClick={handlePreviousClick}
              useIcon={NavigationBack}
              disabled={pending}
            />
            <Button
              {...commonButtonProps}
              onClick={handleNextClick}
              useEndIcon={NavigationForward}
              disabled={pending}
            />
            <Box ml="s-8" onClick={() => setSidebarOpened(true)}>
              <ViewListMedium color={Token.color.greyTone50} cursor="pointer" />
            </Box>
          </>
        )}
      </Flex>
      <SwitchItemsSidebar
        title="Performance review scorecards"
        subtitle="Select the employee you want to assess"
        items={switchItemsSidebarOptions}
        isOpen={sidebarOpened}
        onClose={() => setSidebarOpened(false)}
        selectedIndex={currentReviewIndex}
        onSelect={review => {
          const selectedReview = filteredReviews?.find(item => item.id === review.id)
          if (selectedReview) {
            switchReview(selectedReview)
          }
        }}
      />
    </DetailsCell>
  )
}
