import React from 'react'
import { PageBody } from '@components/Page/PageBody'
import { InputGroup } from '@revolut/ui-kit'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { selectorKeys } from '@src/constants/api'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import { PageActions } from '@components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { NewCandidateInterface } from '@src/interfaces/newCandidate'
import { connect } from 'lape'
import Form from '@src/features/Form/Form'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { ROUTES } from '@src/constants/routes'
import SenioritiesSelect from '@src/pages/Forms/RequisitionForm/General/SenioritiesSelect'
import { LocationInterface } from '@src/interfaces/requisitions'
import LapeNewMultiSelect from '@components/Inputs/LapeFields/LapeNewMultiSelect'
import AutoStepperTitle from '@components/Stepper/NewStepperTitle'
import { requisitionPostingSimpleRequests } from '@src/api/requisitions'
import NewStepperTitle from '@components/Stepper/NewStepperTitle'
import LapeHTMLEditor from '@components/Inputs/LapeFields/LapeHTMLEditor'
import { IdAndName } from '@src/interfaces'

const NewRequisitionFormComponent = () => {
  return (
    <>
      <PageHeader
        title="Add requisition"
        backUrl={ROUTES.ONBOARDING_CHECKLIST_V2.JOBS.IMPORT}
      />
      <PageBody>
        <AutoStepperTitle title="General details" />
        <InputGroup>
          <LapeRadioSelectInput
            name="specialisation"
            label="Role"
            selector={selectorKeys.specialisations}
          />
          <LapeNewInput
            required
            name="requisition_title"
            label="Requisition title"
            message="For example: “Android developer”"
          />
          <InputGroup variant="horizontal">
            <LapeRadioSelectInput<IdAndName>
              name="team"
              required
              label="Team"
              selector={selectorKeys.team}
              message="Candidate(s) will be hired to this team "
            />
            <LapeNewInput
              name="headcount"
              label="Headcount"
              type="number"
              required
              message="New hires amount"
            />
          </InputGroup>
          <SenioritiesSelect alwaysMultipleSeniorities />
          <LapeNewMultiSelect<LocationInterface>
            name="locations"
            placeholder="Locations"
            required
            selector={selectorKeys.location}
            clearable
          />
          <LapeRadioSelectInput
            name="recruiter"
            label="Recruiter"
            selector={selectorKeys.employee}
          />
        </InputGroup>

        <NewStepperTitle title="Job description" />
        <LapeHTMLEditor
          name="job_description"
          placeholder="Job description"
          height={350}
          required
          addMarginToParagraphs
        />
      </PageBody>

      <PageActions>
        <NewSaveButtonWithPopup<NewCandidateInterface>
          successText="New requisition added successfully"
          useValidator
          afterSubmitUrl={ROUTES.ONBOARDING_CHECKLIST_V2.JOBS.IMPORT}
        />
      </PageActions>
    </>
  )
}

export const NewRequisitionForm = connect(() => (
  <Form api={requisitionPostingSimpleRequests}>
    <NewRequisitionFormComponent />
  </Form>
))
