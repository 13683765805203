import React from 'react'
import { useTable } from '@components/Table/hooks'
import { RowInterface, SORT_DIRECTION } from '@src/interfaces/data'
import { DepartmentInterface } from '@src/interfaces/deparment'
import { TeamInterface } from '@src/interfaces/teams'
import { teamsRequests } from '@src/api/teams'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import {
  teamGenericNameColumn,
  circlesKriColumn,
  teamStatusColumn,
} from '@src/constants/columns/team'
import { teamOwnerColumn } from '@src/constants/columns/employee'
import {
  departmentBudgetColumn,
  departmentHeadcountColumn,
  departmentRequisitionsColumn,
  departmentRoadmapColumn,
  nipsColumn,
  departmentAuditColumn,
  departmentCommunicationColumn,
  departmentCultureColumn,
  departmentKPIColumn,
} from '@src/constants/columns/department'
import { filterSortPageIntoQuery } from '@src/utils/table'
import { Statuses } from '@src/interfaces'
import { Flex, MoreBar, Bar, FilterButton } from '@revolut/ui-kit'
import { Plus } from '@revolut/icons'
import AdjustableTable from '@components/Table/AdjustableTable'
import Stat from '@components/Stat/Stat'
import { TableNames } from '@src/constants/table'
import { TableWrapper } from '@components/Table/TableWrapper'
import {
  useGetRoadmapSettings,
  useGetOrganisationSettings,
  useGlobalSettings,
} from '@src/api/settings'
import { TalentType } from '@src/interfaces/talent/talent'
import { AllowedExportMenu } from '@src/features/ExportMenu/AllowedExportMenu'
import { useLocalStorage } from '@src/hooks/useLocalStorage'
import { LOCAL_STORAGE } from '@src/constants/api'
import { useGetSlackPreferences } from '@src/api/integrations'

interface StructureProps {
  data: DepartmentInterface
}

const ROW: RowInterface<TeamInterface> = {
  cells: [
    {
      ...teamGenericNameColumn,
      width: 220,
    },
    {
      ...teamOwnerColumn,
      width: 160,
    },
    {
      ...departmentHeadcountColumn,
      width: 110,
    },
    {
      ...departmentRequisitionsColumn,
      width: 80,
    },
    {
      ...departmentKPIColumn,
      width: 80,
    },
    {
      ...departmentRoadmapColumn,
      width: 80,
    },
    {
      ...nipsColumn,
      width: 80,
    },
    {
      ...departmentAuditColumn,
      width: 80,
    },
    {
      ...circlesKriColumn,
      width: 80,
    },
    {
      ...departmentBudgetColumn,
      width: 100,
    },
    {
      ...departmentCommunicationColumn,
      width: 100,
    },
    {
      ...teamStatusColumn,
      width: 100,
    },
  ],
}

const Teams = ({ data }: StructureProps) => {
  const { data: settings } = useGetOrganisationSettings()
  const { data: roadmapSettings } = useGetRoadmapSettings()
  const { data: slackSettings } = useGetSlackPreferences()
  const { settings: globalSettings } = useGlobalSettings()
  const [showArchivedTeams, setShowArchivedTeams] = useLocalStorage(
    LOCAL_STORAGE.SHOW_ARCHIVED_TEAMS,
    false,
  )

  const getFilterByStatus = (archived: boolean) => ({
    filters: archived
      ? [{ name: Statuses.archived, id: Statuses.archived }]
      : [{ name: Statuses.active, id: Statuses.active }],
    columnName: 'status',
    nonResettable: true,
  })

  const getInitialFilter = () => {
    const filter = [
      {
        filters: [{ name: data.name, id: data.id }],
        columnName: 'department__id',
        nonResettable: true,
      },
      {
        filters: [{ name: Statuses.active, id: Statuses.active }],
        columnName: 'status',
        nonResettable: true,
      },
    ]

    filter.push(getFilterByStatus(showArchivedTeams))

    return filter
  }

  const initialSortBy = [
    {
      sortBy: 'name',
      direction: SORT_DIRECTION.DESC,
    },
  ]
  const table = useTable<TeamInterface>(teamsRequests, getInitialFilter(), initialSortBy)

  const handleNewRow = () => {
    navigateTo(pathToUrl(ROUTES.FORMS.TEAM.SETTINGS, {}), {
      initialValues: { department: { name: data.name, id: data.id } },
    })
  }

  const handleRowEdit = (row: { id: number }) => {
    navigateTo(pathToUrl(ROUTES.FORMS.TEAM.SUMMARY, { id: row.id }))
  }

  const onToggleArchivedTeams = () => {
    setShowArchivedTeams(!showArchivedTeams)
    table.onFilterChange(getFilterByStatus(!showArchivedTeams))
  }

  const filterQuery = filterSortPageIntoQuery(table.sortBy, table.filterBy, 1)

  const roadmapEnabled = !!roadmapSettings?.enabled
  const findingsEnabled = !!settings?.findings?.enabled
  const riskEnabled = !!settings?.risk?.enabled
  const approvalsEnabled = !!settings?.enable_teams_approvals
  const budgetEnabled = !!settings?.enable_budget_management
  const slackEnabled = !!slackSettings?.enabled
  const engagementEnabled = !!globalSettings?.engagement_enabled

  return (
    <TableWrapper>
      <Flex mb="s-24">
        <Stat label="Teams" val={table?.count} mr="s-32" />
      </Flex>
      <Flex mb="s-16" justifyContent="space-between">
        <MoreBar>
          <MoreBar.Action onClick={handleNewRow} useIcon={Plus}>
            Create new Team
          </MoreBar.Action>
          <AllowedExportMenu
            request={teamsRequests.getExport}
            filterQuery={filterQuery}
            fileName="Teams"
            type={TalentType.Team}
          />
        </MoreBar>
        <Bar>
          <FilterButton onClick={onToggleArchivedTeams} active={showArchivedTeams}>
            Archived teams
          </FilterButton>
        </Bar>
      </Flex>
      <AdjustableTable<TeamInterface>
        name={TableNames.DepartmentTeams}
        useWindowScroll
        dataType="Team"
        row={ROW}
        {...table}
        onRowClick={handleRowEdit}
        noDataMessage="All teams will appear here"
        hiddenCells={{
          [departmentRoadmapColumn.idPoint]: !roadmapEnabled,
          [circlesKriColumn.idPoint]: !riskEnabled,
          [departmentCultureColumn.idPoint]: !engagementEnabled,
          [departmentAuditColumn.idPoint]: !findingsEnabled,
          [departmentBudgetColumn.idPoint]: !budgetEnabled,
          [departmentCommunicationColumn.idPoint]: !slackEnabled,
          [teamStatusColumn.idPoint]: !approvalsEnabled,
        }}
      />
    </TableWrapper>
  )
}

export default Teams
