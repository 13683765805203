import React from 'react'
import { MoreBar } from '@revolut/ui-kit'

import { TableNames } from '@src/constants/table'
import { BulkDataUploadV2 } from '@src/features/BulkDataUpload'
import {
  BulkDeleteButton,
  BulkEditAction,
} from '@src/features/GenericEditableTable/components'
import { selectorKeys } from '@src/constants/api'
import {
  useHiddenColumnsByQuery,
  useVisibleActionsByQuery,
} from '@src/features/GenericEditableTable/helpers'
import { optionalBulkSessionFields, row } from './common'

type Props = {
  renderHeader: (title?: string) => React.ReactNode
  importRoute: string
  sessionRoute: string
  anyRoute: string
  apiEndpoint: string
  onAfterConfirmRoute?: string
  category?: 'employee_upload' | 'contract_bulk_upload'
}
export const EditableContractsBulkSession = ({
  renderHeader,
  importRoute,
  sessionRoute,
  anyRoute,
  apiEndpoint,
  onAfterConfirmRoute,
  category = 'contract_bulk_upload',
}: Props) => {
  const hiddenColumns = useHiddenColumnsByQuery(optionalBulkSessionFields)
  const visibleActions = useVisibleActionsByQuery()

  return (
    <>
      <BulkDataUploadV2
        row={row}
        hiddenColumns={hiddenColumns}
        importRoute={importRoute}
        sessionRoute={sessionRoute}
        anyRoute={anyRoute}
        apiEndpoint={apiEndpoint}
        category={category}
        name="contract"
        tableName={TableNames.ImportContractsV2}
        entity="employee"
        templateParams={{ version: '2' }}
        header={renderHeader()}
        onAfterConfirmRoute={onAfterConfirmRoute}
        ignoreQueryParams={['cols', 'template']}
        tableActions={tableActionsProps => (
          <MoreBar>
            {visibleActions.contract_type && (
              <BulkEditAction
                buttonIcon="Suitcase"
                field="contract_type"
                selector={selectorKeys.contract_types}
                {...tableActionsProps}
                label="contract type"
              />
            )}
            {visibleActions.contract_term && (
              <BulkEditAction
                buttonIcon="RepairTool"
                field="contract_term"
                selector={selectorKeys.contract_terms}
                {...tableActionsProps}
                label="contract term"
              />
            )}
            {visibleActions.specialisation && (
              <BulkEditAction
                buttonIcon="RepairTool"
                field="specialisation"
                selector={selectorKeys.specialisations}
                {...tableActionsProps}
              />
            )}
            {visibleActions.seniority && (
              <BulkEditAction
                buttonIcon="ArrowRightLeft"
                field="seniority"
                selector={selectorKeys.seniority}
                {...tableActionsProps}
              />
            )}
            {visibleActions.location && (
              <BulkEditAction
                buttonIcon="MapPoint"
                field="location"
                selector={selectorKeys.location}
                {...tableActionsProps}
              />
            )}
            {visibleActions.salary_currency && (
              <BulkEditAction
                buttonIcon="DollarsAndShares"
                field="salary_currency"
                label="salary currency"
                selector={selectorKeys.currencies}
                selectorField="iso_code"
                {...tableActionsProps}
              />
            )}
            {visibleActions.salary_time_unit && (
              <BulkEditAction
                buttonIcon="TimeAndMoney"
                field="salary_time_unit"
                label="salary time unit"
                selector={selectorKeys.contract_salary_time_units}
                {...tableActionsProps}
              />
            )}
            {visibleActions.salary_payment_frequency && (
              <BulkEditAction
                buttonIcon="CalendarCoins"
                field="salary_payment_frequency"
                label="salary payment frequency"
                selector={selectorKeys.contract_salary_payment_frequencies}
                {...tableActionsProps}
              />
            )}
            <BulkDeleteButton {...tableActionsProps} />
          </MoreBar>
        )}
      />
    </>
  )
}
