import React, { useEffect, useState } from 'react'
import {
  CompetencyPerformanceWeightType,
  RoleInterface,
  SpecialisationInterface,
} from '@src/interfaces/roles'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { connect } from 'lape'
import { Action, DetailsCell, Flex, Group, Subheader, Widget } from '@revolut/ui-kit'
import Loader from '@components/CommonSC/Loader'
import { ROUTES } from '@src/constants/routes'
import { RowInterface } from '@src/interfaces/data'
import { pathToUrl } from '@src/utils/router'
import { silentDeleteSpecialisation, rolesRequests } from '@src/api/roles'
import { Statuses } from '@src/interfaces'
import { HiringProcessInterface } from '@src/interfaces/hiringProccess'
import AdjustableTable from '@components/Table/AdjustableTable'
import {
  hiringProcessEligibleInterviewsLinkColumn,
  hiringProcessPlaybookColumn,
  hiringProcessScorecardLinkColumn,
  hiringProcessSkillsColumn,
  hiringProcessTitleWithLockColumn,
} from '@src/constants/columns/hiringProcess'
import { Queries } from '@src/constants/api'
import { useParams } from 'react-router-dom'
import { useQuery } from '@src/utils/queryParamsHooks'
import PreviewStageSidebar from '@src/pages/Forms/RoleForm/Preview/PreviewStageSidebar/index'
import RoleSaveDraftButton from '@src/pages/Forms/RoleForm/Buttons/RoleSaveDraftButton'
import SpecialisationSubmitButton from '@src/pages/Forms/SpecialisationForm/Buttons/SpecialisationSubmitButton'
import StickyContainerLape from '@src/features/Form/Containers/StickyContainer'
import CompetencyMatrixTable from '@src/features/CompetencyMatrixTable/CompetencyMatrixTable'
import { goBack, navigateTo } from '@src/actions/RouterActions'
import SettingsButtons, {
  ApproveButton,
  ArchiveButton,
  CopyButton,
  RejectButton,
} from '@src/features/SettingsButtons'
import ApprovalFlow from '@src/features/ApprovalFlow/ApprovalFlow'
import { parseLegacyApprovalSteps } from '@src/utils/approvalFlow'
import { PageBody } from '@components/Page/PageBody'
import { FormPreview } from '@components/FormPreview/FormPreview'
import PostingsViewer from '@src/pages/Forms/SpecialisationForm/Postings/PostingsViewer'
import { TableNames } from '@src/constants/table'
import { useGetOrganisationSettings, useGlobalSettings } from '@src/api/settings'
import { useGetSpecialisationPreferredHiringLocations } from '@src/api/specialisations'
import { PermissionTypes } from '@src/store/auth/types'
import LapeDeleteOrgUnitButton from '@src/features/SettingsButtons/DeleteOrgUnitButton/LapeDeleteOrgUnitButton'
import HideIfCommercial from '@src/components/HideIfCommercial/HideIfCommercial'

export const HiringProcessRow: RowInterface<HiringProcessInterface> = {
  cells: [
    {
      ...hiringProcessTitleWithLockColumn,
      width: 188,
    },
    {
      ...hiringProcessSkillsColumn,
      width: 144,
    },
    {
      ...hiringProcessPlaybookColumn,
      width: 100,
    },
    {
      ...hiringProcessScorecardLinkColumn,
      width: 134,
    },
    {
      ...hiringProcessEligibleInterviewsLinkColumn,
      width: 154,
    },
  ],
}

const Preview = () => {
  const context = useLapeContext<SpecialisationInterface>()
  const { values } = context
  const { changeQueryParam } = useQuery()
  const params = useParams()
  const [role, setRole] = useState<RoleInterface>()

  const {
    settings: { job_postings_enabled, candidates_enabled },
  } = useGlobalSettings()

  const { data: settings } = useGetOrganisationSettings()
  const { data: preferredLocations } = useGetSpecialisationPreferredHiringLocations(
    values.id,
  )
  const approvalsEnabled = !!settings?.enable_specialisations_approvals

  const isNew = !values.id || values.status === Statuses.draft

  const permissions = context.values.field_options.permissions || []

  const canEdit = permissions.includes(PermissionTypes.ChangeSpecialisation)
  const canDelete = permissions.includes(PermissionTypes.DeleteSpecialisation)
  const canApprove = permissions.includes(PermissionTypes.ApproveSpecialisation)
  const canArchive = permissions.includes(PermissionTypes.ArchiveSpecialisation)
  const canCopy = permissions.includes(PermissionTypes.AddSpecialisation)
  const showActionsTab =
    !isNew &&
    !![canEdit, canDelete, canApprove, canArchive, canCopy].filter(Boolean).length

  useEffect(() => {
    fetchRole()
  }, [values.role])

  const fetchRole = async () => {
    if (values?.role?.id) {
      try {
        context.loading = true
        const result = await rolesRequests.getItem(values.role.id)
        if (result.data) {
          setRole(result.data)
          if (!values.seniority_max) {
            values.seniority_max = result.data.seniority_max
          }

          if (!values.seniority_min) {
            values.seniority_min = result.data.seniority_min
          }
        }
      } finally {
        context.loading = false
      }
    }
  }

  if (context.loading) {
    return (
      <Flex>
        <Loader />
      </Flex>
    )
  }

  const handleRowEdit = (data: HiringProcessInterface) => {
    if (data.id) {
      changeQueryParam(Queries.StageId, `${data.id}`)
    }
  }

  const renderEditLInk = (url: string, label = 'Edit') => {
    return canEdit ? (
      <Subheader.Side>
        <Action
          onClick={() =>
            navigateTo(
              pathToUrl(url, {
                id: values.id,
              }),
            )
          }
        >
          {label}
        </Action>
      </Subheader.Side>
    ) : null
  }

  return (
    <>
      <PreviewStageSidebar isSpecialisation />
      <PageBody maxWidth={782}>
        {showActionsTab && (
          <SettingsButtons mb="s-32">
            {approvalsEnabled ? (
              <>
                <ApproveButton
                  isVisible={context.initialValues.status === Statuses.pending}
                  globalPermissions={[PermissionTypes.ApproveSpecialisation]}
                  notification={{
                    updateMsg: 'Specialisation successfully approved.',
                  }}
                  showErrorPopup
                />
                <RejectButton
                  isVisible={context.initialValues.status === Statuses.pending}
                  globalPermissions={[PermissionTypes.ApproveSpecialisation]}
                  notification={{
                    updateMsg: 'Specialisation successfully rejected.',
                  }}
                  dialog={{
                    title: 'Reasons for rejection',
                    placeholder: 'Please provide reasons for rejection',
                    fieldName: 'description',
                  }}
                />
              </>
            ) : null}
            <CopyButton
              isVisible={!!values.id}
              cleanFields={[
                'cv_criterias',
                'hiring_process_rounds',
                'performance_weights',
              ]}
              afterSubmitUrl={pathToUrl(ROUTES.FORMS.SPECIALISATIONS.GENERAL, {})}
              globalPermissions={[PermissionTypes.AddSpecialisation]}
            />
            {!!values?.id && (
              <LapeDeleteOrgUnitButton
                onAfterDelete={() => {
                  goBack(
                    values?.role?.id
                      ? pathToUrl(ROUTES.FORMS.ROLE.SPECIALISATIONS, {
                          id: values?.role?.id,
                        })
                      : ROUTES.ORGANISATION.ROLES.SPECIALISATIONS,
                  )
                }}
                deleteApi={silentDeleteSpecialisation}
                prefix="specialisation"
              />
            )}
            <ArchiveButton
              isVisible={!!values.id}
              notification={{
                updateMsg: 'Specialisation successfully archived.',
              }}
            />
          </SettingsButtons>
        )}
        {approvalsEnabled && values.status !== Statuses.draft && (
          <ApprovalFlow
            isLoading={!values.approval_flow_status}
            steps={parseLegacyApprovalSteps(values.approval_flow_status?.approval_steps)}
          />
        )}
        <FormPreview<SpecialisationInterface>
          data={values}
          title="About the specialisation"
          onEdit={
            canEdit
              ? () => navigateTo(pathToUrl(ROUTES.FORMS.SPECIALISATIONS.GENERAL, params))
              : undefined
          }
        >
          <Group>
            <FormPreview.Item title="Specialisation name" field="name" />
            <FormPreview.Item
              title="Specialisation owner"
              type="employee"
              field="owner"
            />
            <FormPreview.Item
              title="Parent Role"
              field="role.name"
              to={() =>
                pathToUrl(ROUTES.FORMS.ROLE.PREVIEW, {
                  id: values.role?.id,
                })
              }
            />
            <HideIfCommercial>
              {values.hiring_playbook_url && (
                <FormPreview.Item
                  title="Playbook"
                  field="hiring_playbook_url"
                  type="link"
                />
              )}
            </HideIfCommercial>
            <FormPreview.Item<SpecialisationInterface>
              title="Locations"
              field="locations"
              insert={data =>
                data?.locations?.length
                  ? data.locations?.map(loc => loc.name).join(', ')
                  : 'All'
              }
            />
            <FormPreview.Item<SpecialisationInterface>
              title="Preferred locations"
              insert={() => preferredLocations?.map(loc => loc.name).join(', ') || '-'}
            />
            <FormPreview.Details title="Specialisation mission" field="mission" />
            <FormPreview.Details
              title="Specialisation goals"
              field="goals"
              insert={() => (
                <ul style={{ paddingInlineStart: 16 }}>
                  {values.goals?.map((goal, id) => {
                    return <li key={id}>{goal.text}</li>
                  })}
                </ul>
              )}
            />
          </Group>
        </FormPreview>
        <Subheader variant="nested">
          <Subheader.Title>Skills competency matrix</Subheader.Title>
          {renderEditLInk(ROUTES.FORMS.SPECIALISATIONS.COMPETENCY_MATRIX)}
        </Subheader>
        <DetailsCell>
          <DetailsCell.Title>Allowed seniorities</DetailsCell.Title>
          <DetailsCell.Content whiteSpace="pre-line">
            {values.seniority_min?.name || ''} - {values.seniority_max?.name || ''}
          </DetailsCell.Content>
        </DetailsCell>
        <Widget mt="s-16" p="s-16">
          <CompetencyMatrixTable
            competencyMatrices={[
              {
                sectionTitle: 'Deliverables',
                children: [
                  {
                    skill: {
                      id: null,
                      name: 'Deliverables',
                    },
                    competencies: values.deliverables_competencies || [],
                    weight: values.performance_weights?.find(
                      w => w.weight_type === CompetencyPerformanceWeightType.Deliverables,
                    )?.weight,
                  },
                ],
                disabled: true,
                hideActionsColumn: true,
                disableWeights: true,
              },
              {
                sectionTitle: 'Parent role skill',
                children: role?.functional_competency_matrix?.map(item => ({
                  ...item,
                  weight: values.performance_weights?.find(
                    w =>
                      w.weight_type === CompetencyPerformanceWeightType.Skill &&
                      w.skill_id === item.skill?.id,
                  )?.weight,
                })),
                hideActionsColumn: true,
                disableWeights: true,
                disabled: true,
              },
              {
                sectionTitle: 'Specialisation skill',
                children: values.functional_competency_matrix?.map(item => ({
                  ...item,
                  weight: values.performance_weights?.find(
                    w =>
                      w.weight_type === CompetencyPerformanceWeightType.Skill &&
                      w.skill_id === item.skill?.id,
                  )?.weight,
                })),
                disabled: true,
                hideActionsColumn: true,
                disableWeights: true,
              },
            ]}
            minSeniority={values.seniority_min}
            maxSeniority={values.seniority_max}
            firstRowTitle="Skills"
            isAdjustable
            withWeightColumn={!!values.performance_weights}
          />
        </Widget>
        {candidates_enabled && (
          <>
            <Subheader variant="nested">
              <Subheader.Title>Hiring process</Subheader.Title>
              {renderEditLInk(ROUTES.FORMS.SPECIALISATIONS.HIRING_PROCESS)}
            </Subheader>
            <Widget p="s-16">
              <AdjustableTable<HiringProcessInterface>
                name={TableNames.SpecialisationHiringProcess}
                dataType="Hiring stage"
                row={HiringProcessRow}
                data={values?.hiring_process_rounds}
                count={values?.hiring_process_rounds?.length || 0}
                onRowClick={handleRowEdit}
                noDataMessage="Hiring stages will appear here."
              />
            </Widget>
          </>
        )}
        {job_postings_enabled && (
          <>
            <Subheader variant="nested">
              <Subheader.Title>Job posting</Subheader.Title>
              {renderEditLInk(ROUTES.FORMS.SPECIALISATIONS.POSTINGS, 'View details')}
            </Subheader>
            <PostingsViewer noButtons noEditing noTable />
          </>
        )}
        <StickyContainerLape sticky isCenter maxWidth={624}>
          {(!values?.id || values.status === Statuses.draft) && (
            <>
              <RoleSaveDraftButton
                title="specialisation"
                pathInLocalStorage={pathToUrl(ROUTES.FORMS.SPECIALISATIONS.GENERAL, {})}
                pathAfterSave={ROUTES.FORMS.SPECIALISATIONS.PREVIEW}
                isNew
                notification={{
                  path: ROUTES.FORMS.SPECIALISATIONS.GENERAL,
                  updateMsg: 'Specialisation draft successfully updated.',
                  createMsg: 'Specialisation draft successfully created.',
                }}
              />
              <SpecialisationSubmitButton
                isNew
                notification={{
                  path: ROUTES.FORMS.SPECIALISATIONS.GENERAL,
                  updateMsg: 'Specialisation successfully updated.',
                  createMsg: 'Specialisation successfully created.',
                }}
                dialog={{
                  title: 'Why do we need this specialisation?',
                  placeholder: 'a couple of words about the reason',
                  fieldName: 'description',
                }}
              />
            </>
          )}
        </StickyContainerLape>
      </PageBody>
    </>
  )
}

export default connect(Preview)
