import React, { useEffect, useState } from 'react'
import {
  BottomSheet,
  Button,
  Header,
  InputGroup,
  StatusPopup,
  useStatusPopup,
} from '@revolut/ui-kit'
import { useSelector } from 'react-redux'

import { IdAndName } from '@src/interfaces'
import LapeForm, { useLapeContext } from '@src/features/Form/LapeForm'
import { CreateTeamSimpleInterface } from '@src/interfaces/teams'
import { createTeamSimple } from '@src/api/teams'
import {
  FormValidatorProvider,
  useSafeFormValidator,
} from '@src/features/Form/FormValidator'
import { selectUser } from '@src/store/auth/selectors'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { selectorKeys } from '@src/constants/api'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { CreateDepartmentInterface } from '@src/interfaces/deparment'
import { createDepartment } from '@src/api/department'

/** Create team */

interface CreateTeamPopupProps {
  open: boolean
  onSuccess: (entity: IdAndName) => void
  onClose: () => void
  showDepartmentField?: boolean
}

export const CreateTeamPopup = ({
  open,
  onSuccess,
  onClose,
  showDepartmentField,
}: CreateTeamPopupProps) => {
  const [pending, setPending] = useState(false)

  return (
    <BottomSheet open={open} onClose={onClose} preventUserClose={pending}>
      <Header>
        <Header.Title>Create new team</Header.Title>
      </Header>

      <LapeForm<CreateTeamSimpleInterface>
        onSubmit={form => {
          setPending(true)

          return createTeamSimple(form.values)
            .then(response => {
              onSuccess({ id: response.data.id, name: response.data.name })
              return form.values
            })
            .finally(() => {
              setPending(false)
            })
        }}
      >
        <FormValidatorProvider>
          <CreateTeamForm showDepartmentField={showDepartmentField} />
        </FormValidatorProvider>
      </LapeForm>
    </BottomSheet>
  )
}

interface CreateTeamFormProps {
  showDepartmentField?: boolean
}

const CreateTeamForm = ({ showDepartmentField }: CreateTeamFormProps) => {
  const { isSubmitting, submit, values } = useLapeContext<CreateTeamSimpleInterface>()
  const { validate } = useSafeFormValidator()

  const statusPopup = useStatusPopup()

  const currentUser = useSelector(selectUser)

  useEffect(() => {
    values.team_owner = { id: currentUser.id, name: currentUser.full_name }
  }, [])

  return (
    <>
      <InputGroup>
        <LapeNewInput name="name" label="Name" required />
        <LapeRadioSelectInput
          name="team_owner"
          label="Owner"
          selector={selectorKeys.employee}
        />
        {showDepartmentField && (
          <LapeRadioSelectInput
            name="department"
            label="Department"
            selector={selectorKeys.department}
            optional
          />
        )}
      </InputGroup>
      <BottomSheet.Actions>
        <Button
          onClick={validate(
            () => submit(),
            error => {
              statusPopup.show(
                <StatusPopup variant="error">
                  <StatusPopup.Title>Failed to create a team</StatusPopup.Title>
                  <StatusPopup.Description>
                    {getStringMessageFromError(error)}
                  </StatusPopup.Description>
                </StatusPopup>,
              )
            },
          )}
          pending={isSubmitting}
          elevated
        >
          Confirm
        </Button>
      </BottomSheet.Actions>
    </>
  )
}

/** Create department */

interface CreateDepartmentPopupProps {
  open: boolean
  onSuccess: (entity: IdAndName) => void
  onClose: () => void
}

export const CreateDepartmentPopup = ({
  open,
  onSuccess,
  onClose,
}: CreateDepartmentPopupProps) => {
  const [pending, setPending] = useState(false)

  return (
    <BottomSheet open={open} onClose={onClose} preventUserClose={pending}>
      <Header>
        <Header.Title>Add new department</Header.Title>
      </Header>

      <LapeForm<CreateDepartmentInterface>
        onSubmit={form => {
          setPending(true)

          return createDepartment(form.values)
            .then(response => {
              onSuccess({ id: response.data.id, name: response.data.name })
              return form.values
            })
            .finally(() => {
              setPending(false)
            })
        }}
      >
        <FormValidatorProvider>
          <CreateDepartmentForm />
        </FormValidatorProvider>
      </LapeForm>
    </BottomSheet>
  )
}

const CreateDepartmentForm = () => {
  const { isSubmitting, submit, values } = useLapeContext<CreateDepartmentInterface>()
  const { validate } = useSafeFormValidator()

  const statusPopup = useStatusPopup()

  const currentUser = useSelector(selectUser)

  useEffect(() => {
    values.owner = { id: currentUser.id, name: currentUser.full_name }
    values.mission = '-'
  }, [])

  return (
    <>
      <InputGroup>
        <LapeNewInput name="name" label="Name" required />
        <LapeRadioSelectInput
          name="owner"
          label="Owner"
          selector={selectorKeys.employee}
        />
      </InputGroup>
      <BottomSheet.Actions>
        <Button
          onClick={validate(
            () => submit(),
            error => {
              statusPopup.show(
                <StatusPopup variant="error">
                  <StatusPopup.Title>Failed to create department</StatusPopup.Title>
                  <StatusPopup.Description>
                    {getStringMessageFromError(error)}
                  </StatusPopup.Description>
                </StatusPopup>,
              )
            },
          )}
          pending={isSubmitting}
          elevated
        >
          Confirm
        </Button>
      </BottomSheet.Actions>
    </>
  )
}
