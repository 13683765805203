import React from 'react'
import { useParams } from 'react-router-dom'

import { selectorKeys } from '@src/constants/api'
import { BulkEditAction } from '@src/features/GenericEditableTable/components'
import {
  getHiddenSessionColumnsBySessionField,
  getVisibleSessionActionsBySessionField,
} from '@src/features/GenericEditableTable/helpers'
import { BulkEmployeeUploadFlow } from '../common/BulkSession'
import {
  BaseFieldsForBulkEdit,
  BulkSessionTableWrapperProps,
  getBulkSessionHeaderTitle,
} from '../common'
import {
  bulkFieldsDependencies,
  FieldsForBulkEdit,
  fieldsForBulkEdit,
  row,
} from './common'

export const EmployeesBulkSessionSimple = (props: BulkSessionTableWrapperProps) => {
  const params = useParams<{ type?: FieldsForBulkEdit }>()

  const headerTitle =
    params.type === 'access_groups'
      ? 'Review bulk assigned access group'
      : getBulkSessionHeaderTitle(params?.type as BaseFieldsForBulkEdit)

  const hiddenColumnsBySessionField =
    getHiddenSessionColumnsBySessionField<FieldsForBulkEdit>(
      params.type as FieldsForBulkEdit,
      fieldsForBulkEdit,
      bulkFieldsDependencies,
    )
  const visibleActionsBySessionField =
    getVisibleSessionActionsBySessionField<FieldsForBulkEdit>(
      params.type as FieldsForBulkEdit,
      bulkFieldsDependencies,
    )

  return (
    <BulkEmployeeUploadFlow
      row={row}
      hiddenColumns={hiddenColumnsBySessionField}
      headerTitle={headerTitle}
      visibleActions={visibleActionsBySessionField}
      renderMoreActions={tableActionsProps =>
        visibleActionsBySessionField.access_level ? (
          <BulkEditAction
            buttonIcon="Switches"
            field="access_level"
            label="access group"
            selector={selectorKeys.groups}
            {...tableActionsProps}
          />
        ) : null
      }
      {...props}
    />
  )
}
