import React from 'react'

import {
  GenericEditableTableOnChange,
  RadioSelectInputCell,
  SelectCell,
  SeniorityValue,
  TextCell,
} from '@src/features/GenericEditableTable/components'
import { selectorKeys } from '../api'
import { CellTypes, ColumnInterface, FilterType } from '@src/interfaces/data'
import { SpecialisationInterface } from '@src/interfaces/roles'
import UserWithAvatar from '@src/components/UserWithAvatar/UserWithAvatar'
import { ImportInterface } from '@src/interfaces/bulkDataImport'

type OnboardingRolesColumn = (
  onChange: GenericEditableTableOnChange,
  onSenioritiesClick?: (data: SpecialisationInterface) => void,
) => ColumnInterface<ImportInterface<SpecialisationInterface>>

export const onboardingRoleNameColumn: OnboardingRolesColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'name',
  dataPoint: 'name',
  sortKey: 'name',
  filterKey: 'id',
  selectorsKey: selectorKeys.specialisations,
  title: 'Role',
  insert: ({ data }) => <TextCell data={data} onChange={onChange} field="name" />,
})

export const onboardingRoleHeadcountColumn: OnboardingRolesColumn = () => ({
  type: CellTypes.text,
  idPoint: 'data.headcount',
  dataPoint: 'data.headcount',
  sortKey: 'headcount',
  filterKey: 'headcount',
  filterType: FilterType.range,
  selectorsKey: selectorKeys.none,
  title: 'Headcount',
})

export const onboardingRoleSeniorityColumn: OnboardingRolesColumn = (_, onClick) => ({
  type: CellTypes.insert,
  idPoint: 'id',
  dataPoint: 'name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Seniorities',
  insert: ({ data }) => {
    return (
      <SelectCell open={false} setOpen={() => onClick?.(data.data)}>
        <SeniorityValue
          minSeniority={data.data.seniority_min?.name}
          maxSeniority={data.data.seniority_max?.name}
        />
      </SelectCell>
    )
  },
})

export const onboardingRoleOwnerColumn: OnboardingRolesColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'owner.id',
  dataPoint: 'owner.full_name',
  sortKey: 'owner__full_name',
  filterKey: 'owner__id',
  selectorsKey: selectorKeys.employee,
  title: 'Owner',
  insert: ({ data }) => {
    return (
      <RadioSelectInputCell
        data={data}
        onChange={onChange}
        field="owner"
        selector={selectorKeys.employee}
        customLabel={
          data.data.owner ? <UserWithAvatar {...data.data.owner} disableLink /> : '-'
        }
      />
    )
  },
})
