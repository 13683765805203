import React from 'react'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'
import { PageBody } from '@src/components/Page/PageBody'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import CandidateBulkEmails from '@src/pages/Recruitment/RecruitmentSubTabs/BulkActions/CandidateBulkEmails'
import CandidateBulkArchive from '@src/pages/Recruitment/RecruitmentSubTabs/BulkActions/CandidateBulkArchive'
import CandidateBulkEdit from '@src/pages/Recruitment/RecruitmentSubTabs/BulkActions/CandidateBulkEdit'
import CandidateBulkSendOnlineTest from '@src/pages/Recruitment/RecruitmentSubTabs/BulkActions/CandidateBulkSendOnlineTest'
import CandidateBulkMoveStage from '@src/pages/Recruitment/RecruitmentSubTabs/BulkActions/CandidateBulkMoveStage'
import CandidateBulkSnooze from '@src/pages/Recruitment/RecruitmentSubTabs/BulkActions/CandidateBulkSnooze'
import CandidateBulkUnsnooze from '@src/pages/Recruitment/RecruitmentSubTabs/BulkActions/CandidateBulkUnsnooze'
import { BREAKPOINTS, Box } from '@revolut/ui-kit'
import { Route, Switch } from 'react-router-dom'

export const BulkActionsPage = () => {
  const tabs = [
    {
      id: 'candidate_emails',
      path: ROUTES.FORMS.CANDIDATE_BULK_ACTIONS.CANDIDATE_EMAILS,
      to: ROUTES.FORMS.CANDIDATE_BULK_ACTIONS.CANDIDATE_EMAILS,
      title: 'Candidate emails',
      component: CandidateBulkEmails,
    },
    {
      id: 'candidate_archive',
      path: ROUTES.FORMS.CANDIDATE_BULK_ACTIONS.CANDIDATE_ARCHIVE,
      to: ROUTES.FORMS.CANDIDATE_BULK_ACTIONS.CANDIDATE_ARCHIVE,
      title: 'Candidate archive',
      component: CandidateBulkArchive,
    },
    {
      id: 'candidate_edit',
      path: ROUTES.FORMS.CANDIDATE_BULK_ACTIONS.CANDIDATE_EDIT,
      to: ROUTES.FORMS.CANDIDATE_BULK_ACTIONS.CANDIDATE_EDIT,
      title: 'Candidate edit',
      component: CandidateBulkEdit,
    },
    {
      id: 'candidate_send_online_test',
      path: ROUTES.FORMS.CANDIDATE_BULK_ACTIONS.CANDIDATE_SEND_ONLINE_TEST,
      to: ROUTES.FORMS.CANDIDATE_BULK_ACTIONS.CANDIDATE_SEND_ONLINE_TEST,
      title: 'Candidate send online test',
      component: CandidateBulkSendOnlineTest,
    },
    {
      id: 'candidate_move_stage',
      path: ROUTES.FORMS.CANDIDATE_BULK_ACTIONS.CANDIDATE_MOVE_STAGE,
      to: ROUTES.FORMS.CANDIDATE_BULK_ACTIONS.CANDIDATE_MOVE_STAGE,
      title: 'Candidate move to stage',
      component: CandidateBulkMoveStage,
    },
    {
      id: 'candidate_snooze',
      path: ROUTES.FORMS.CANDIDATE_BULK_ACTIONS.CANDIDATE_SNOOZE,
      to: ROUTES.FORMS.CANDIDATE_BULK_ACTIONS.CANDIDATE_SNOOZE,
      title: 'Candidate snooze',
      component: CandidateBulkSnooze,
    },
    {
      id: 'candidate_unsnooze',
      path: ROUTES.FORMS.CANDIDATE_BULK_ACTIONS.CANDIDATE_UNSNOOZE,
      to: ROUTES.FORMS.CANDIDATE_BULK_ACTIONS.CANDIDATE_UNSNOOZE,
      title: 'Candidate unsnooze',
      component: CandidateBulkUnsnooze,
    },
  ]
  return (
    <PageWrapper>
      <PageHeader title="Bulk actions" backUrl={pathToUrl(ROUTES.RECRUITMENT.CANDIDATES)}>
        <Box pb="s-8" maxWidth={BREAKPOINTS.xl}>
          <TabBarNavigation tabs={tabs} />
        </Box>
      </PageHeader>
      <PageBody maxWidth="100%">
        <Switch>
          {tabs.map(tab => (
            <Route exact path={tab.path} key={tab.path}>
              <tab.component />
            </Route>
          ))}
        </Switch>
      </PageBody>
    </PageWrapper>
  )
}
