import React, { useEffect } from 'react'
import { Group, InputGroup } from '@revolut/ui-kit'
import { ROUTES } from '@src/constants/routes'
import { connect } from 'lape'
import { PageBody } from '@components/Page/PageBody'
import NewStepperTitle from '@components/Stepper/NewStepperTitle'
import AutoStepper from '@components/Stepper/AutoStepper'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { OfferPlaceholderInterface } from '@src/interfaces/offerTemplates'
import { PageActions } from '@components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { selectorKeys } from '@src/constants/api'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import { useLocation } from 'react-router-dom'
import LapeNewSwitch from '@components/Inputs/LapeFields/LapeNewSwitch'
import LapeMultiInput from '@components/Inputs/LapeFields/LapeMultiInput'

const OfferPlaceholder = () => {
  const location = useLocation<{ placeholder?: string }>()
  const user = useSelector(selectUser)
  const { values, submit } = useLapeContext<OfferPlaceholderInterface>()

  useEffect(() => {
    if (!values.owner) {
      values.owner = { id: user.id, name: user.full_name }
    }

    if (values.edit_requires_reapproval === undefined) {
      values.edit_requires_reapproval = false
    }

    if (values.include_in_all_forms === undefined) {
      values.include_in_all_forms = false
    }

    if (values.is_mandatory === undefined) {
      values.is_mandatory = false
    }
  }, [])

  useEffect(() => {
    if (!values.id && location.state?.placeholder) {
      values.placeholder = location.state.placeholder
    }
  }, [location.state?.placeholder, values.id])

  const onSubmit = async () => {
    if (values.choice_options) {
      values.choice_options = values.choice_options.filter(i => i)
    }

    await submit()
  }

  return (
    <>
      <PageBody>
        <AutoStepper>
          <NewStepperTitle title="Details" />
          <InputGroup>
            <LapeNewInput
              name="placeholder"
              label="Placeholder eg {first_name}"
              required
            />
            <LapeNewInput name="label" label="Label in form" required />
            <LapeNewInput name="tooltip" label="Help text" />
            <LapeRadioSelectInput
              name="owner"
              label="Owner"
              selector={selectorKeys.employee}
            />
          </InputGroup>

          <NewStepperTitle title="Field settings" />
          <InputGroup>
            <LapeRadioSelectInput
              name="field_type"
              label="Type"
              selector={selectorKeys.offer_form_placeholder_field_types}
              searchable={false}
            />
            {values.field_type?.id === 'dropdown' && (
              <LapeMultiInput valueFieldName={undefined} name="choice_options" />
            )}
          </InputGroup>

          <NewStepperTitle title="Field settings" />
          <InputGroup>
            <LapeRadioSelectInput
              name="offer_section"
              label="Section"
              selector={selectorKeys.offer_form_placeholder_offer_sections}
              searchable={false}
            />
            <Group>
              <LapeNewSwitch
                name="include_in_all_forms"
                itemTypeProps={{ title: 'Include in all offer forms' }}
              />
              <LapeNewSwitch
                name="is_mandatory"
                itemTypeProps={{ title: 'Field is mandatory' }}
              />
              <LapeNewSwitch
                name="edit_requires_reapproval"
                itemTypeProps={{ title: 'Editing this field requires re approval' }}
              />
            </Group>
          </InputGroup>
        </AutoStepper>
      </PageBody>

      <PageActions>
        <NewSaveButtonWithPopup<OfferPlaceholderInterface>
          onClick={onSubmit}
          successText="Email placeholder saved successfully"
          afterSubmitUrl={ROUTES.APPS.OFFERS.OFFER_PLACEHOLDERS}
          useValidator
        />
      </PageActions>
    </>
  )
}

export default connect(OfferPlaceholder)
