import { BulkUploadConfigurableField } from '@src/interfaces/bulkDataImport'

export const getAddFieldButtonProps = (
  entityName: string,
  field: BulkUploadConfigurableField,
  addedFields: BulkUploadConfigurableField[],
) => {
  if (entityName === 'contract' && field.column_name === 'subseniority') {
    return {
      disabled: !addedFields.some(f => f.column_name === 'seniority'),
    }
  }
  return { disabled: false }
}

export const getAddedFieldsAfterDeletion = (
  entityName: string,
  field: BulkUploadConfigurableField,
  addedFields: BulkUploadConfigurableField[],
) => {
  if (entityName === 'contract' && field.column_name === 'seniority') {
    return addedFields.filter(
      f => f.column_name !== 'seniority' && f.column_name !== 'subseniority',
    )
  }
  return addedFields.filter(f => f.column_name !== field.column_name)
}
