import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { ROUTES } from '@src/constants/routes'
import { PermissionTypes } from '@src/store/auth/types'
import { ImportEmployeesIntro } from './ImportEmployeesIntro'
import { EditableEmployeesTableSimple } from '@src/features/EditableEmployeesTable/TableSimple'
import { EmployeesBulkSessionSimple } from '@src/features/EditableEmployeesTable/TableSimple/BulkSession'
import { pathToUrl } from '@src/utils/router'
import { API } from '@src/constants/api'

import { importEmployeesConfig } from '../common/checkpointsConfig'
import { OnboardingChecklistHeader } from '../components/OnboardingChecklistHeader'
import { OnboardingChecklistContent } from '../components/OnboardingChecklistContent'

const routes = [
  {
    title: 'Intro',
    path: ROUTES.ONBOARDING_CHECKLIST_V2.IMPORT_EMPLOYEES.INTRO,
    url: ROUTES.ONBOARDING_CHECKLIST_V2.IMPORT_EMPLOYEES.INTRO,
    canView: [PermissionTypes.ViewEmployeePreferences],
    component: ImportEmployeesIntro,
  },
  {
    title: 'Import',
    path: ROUTES.ONBOARDING_CHECKLIST_V2.IMPORT_EMPLOYEES.IMPORT,
    url: ROUTES.ONBOARDING_CHECKLIST_V2.IMPORT_EMPLOYEES.IMPORT,
    canView: [PermissionTypes.ViewEmployeePreferences],
    component: () => (
      <EditableEmployeesTableSimple
        sessionRoute={ROUTES.ONBOARDING_CHECKLIST_V2.IMPORT_EMPLOYEES.UPLOAD.SESSION}
      />
    ),
    isWide: true,
  },
]

export const ImportEmployees = () => {
  return (
    <Switch>
      <Route path={ROUTES.ONBOARDING_CHECKLIST_V2.IMPORT_EMPLOYEES.UPLOAD.ANY}>
        <EmployeesBulkSessionSimple
          importRoute={ROUTES.ONBOARDING_CHECKLIST_V2.IMPORT_EMPLOYEES.UPLOAD.IMPORT}
          sessionRoute={ROUTES.ONBOARDING_CHECKLIST_V2.IMPORT_EMPLOYEES.UPLOAD.SESSION}
          anyRoute={ROUTES.ONBOARDING_CHECKLIST_V2.IMPORT_EMPLOYEES.UPLOAD.ANY}
          apiEndpoint={API.EMPLOYEE_UPLOADS}
          getHeader={title => (
            <OnboardingChecklistHeader
              title={title}
              backUrl={pathToUrl(ROUTES.ONBOARDING_CHECKLIST_V2.IMPORT_EMPLOYEES.IMPORT)}
            />
          )}
        />
      </Route>
      <Route>
        <OnboardingChecklistContent config={importEmployeesConfig} routes={routes} />
      </Route>
    </Switch>
  )
}
