import { AxiosPromise } from 'axios'

import { FetchDataQueryInterface } from '@src/interfaces/data'
import { ApiVersion, GetRequestInterface } from '@src/interfaces'
import { ImportInterface } from '@src/interfaces/bulkDataImport'
import { apiWithoutHandling } from '@src/api'
import { filterSortPageIntoQuery } from '@src/utils/table'

export const getEditableTable =
  <T>(apiEndpoint: string, apiVersion?: ApiVersion) =>
  ({
    sortBy,
    filters,
    page,
  }: FetchDataQueryInterface): AxiosPromise<GetRequestInterface<ImportInterface<T>>> => {
    return apiWithoutHandling
      .get<GetRequestInterface<{ id: number }>>(
        apiEndpoint,
        {
          params: filterSortPageIntoQuery(sortBy, filters, page),
        },
        apiVersion,
      )
      .then(response => ({
        ...response,
        data: {
          ...response.data,
          results: response.data.results.map(row => ({
            id: row.id,
            data: row as T,
            errors: {},
            state: { id: 'pending', name: 'Pending' },
            error_message: null,
            loading: {},
          })),
        },
      }))
  }

export const editEditableTableCell = <T>(
  apiEndpoint: string,
  entityId: number,
  data: T,
  apiVersion?: ApiVersion,
) =>
  apiWithoutHandling.patch<T>(`${apiEndpoint}/${entityId}`, data, undefined, apiVersion)

export const deleteEditableTableRow = (
  apiEndpoint: string,
  rowId: number,
  apiVersion?: ApiVersion,
) => apiWithoutHandling.delete(`${apiEndpoint}/${rowId}`, undefined, apiVersion)
