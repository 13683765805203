import React from 'react'

import { ROUTES } from '@src/constants/routes'
import { API, selectorKeys } from '@src/constants/api'
import { TableNames } from '@src/constants/table'
import { CommonGenericEditableTableRowOptions } from '@src/features/GenericEditableTable/GenericEditableTable'
import { RowInterface } from '@src/interfaces/data'
import { ImportInterface } from '@src/interfaces/bulkDataImport'
import { MoreBar } from '@revolut/ui-kit'
import {
  BulkDeleteButton,
  BulkEditAction,
  BulkEditMultiSelectAction,
  BulkEditSeniorityRangeAction,
} from '@src/features/GenericEditableTable/components'
import {
  importJobsV2DescriptionColumn,
  importJobsV2HeadcountColumn,
  importJobsV2LocationsColumn,
  importJobsV2RecruiterColumn,
  importJobsV2RoleColumn,
  importJobsV2SeniorityColumn,
  importJobsV2TeamColumn,
  importJobsV2TitleColumn,
} from '@src/constants/columns/importJobsV2'
import { BulkDataUploadV2 } from '@src/features/BulkDataUpload'
import { pathToUrl } from '@src/utils/router'
import { OnboardingChecklistHeader } from '@src/pages/OnboardingChecklistV2/components/OnboardingChecklistHeader'
import { ImportJobsInterface } from '@src/interfaces/importJobs'

const row = (
  options: CommonGenericEditableTableRowOptions,
): RowInterface<ImportInterface<ImportJobsInterface>> => ({
  cells: [
    {
      ...importJobsV2TitleColumn(options.onChange),
      width: 200,
    },
    {
      ...importJobsV2TeamColumn(options.onChange),
      width: 150,
    },
    {
      ...importJobsV2RoleColumn(options.onChange),
      width: 150,
    },
    {
      ...importJobsV2HeadcountColumn(options.onChange),
      width: 150,
    },
    {
      ...importJobsV2SeniorityColumn(options.onChange),
      width: 150,
    },
    {
      ...importJobsV2LocationsColumn(options.onChange),
      width: 150,
    },
    {
      ...importJobsV2RecruiterColumn(options.onChange),
      width: 200,
    },
    {
      ...importJobsV2DescriptionColumn(options.onChange),
      width: 200,
    },
  ],
})

export const JobsFlow = () => {
  return (
    <BulkDataUploadV2
      importRoute={ROUTES.ONBOARDING_CHECKLIST_V2.JOBS.UPLOAD.IMPORT}
      sessionRoute={ROUTES.ONBOARDING_CHECKLIST_V2.JOBS.UPLOAD.SESSION}
      anyRoute={ROUTES.ONBOARDING_CHECKLIST_V2.JOBS.UPLOAD.ANY}
      apiEndpoint={API.JOBS_UPLOAD}
      category="requisition_posting_bulk_upload"
      name="jobs"
      tableName={TableNames.ImportJobsV2}
      row={row}
      entity="job"
      header={
        <OnboardingChecklistHeader
          title="Import jobs"
          backUrl={pathToUrl(ROUTES.ONBOARDING_CHECKLIST_V2.JOBS.IMPORT)}
        />
      }
      tableActions={props => (
        <MoreBar>
          <BulkEditAction
            buttonIcon="People"
            field="team"
            selector={selectorKeys.team}
            {...props}
            apiEndpoint={API.JOBS_UPLOAD}
          />
          <BulkEditAction
            buttonIcon="RepairTool"
            field="specialisation"
            selector={selectorKeys.specialisations}
            {...props}
            apiEndpoint={API.JOBS_UPLOAD}
            label="role"
          />
          <BulkEditAction
            buttonIcon="Profile"
            field="recruiter"
            selector={selectorKeys.all_employees_avatar_email}
            selectorField="email"
            {...props}
            apiEndpoint={API.JOBS_UPLOAD}
          />
          <BulkEditSeniorityRangeAction {...props} apiEndpoint={API.JOBS_UPLOAD} />
          <BulkEditMultiSelectAction
            buttonIcon="MapPoint"
            field="locations"
            selector={selectorKeys.location}
            {...props}
            apiEndpoint={API.JOBS_UPLOAD}
          />
          <BulkDeleteButton {...props} />
        </MoreBar>
      )}
    />
  )
}
