import React from 'react'
import { ROUTES } from '@src/constants/routes'
import {
  CareerPageGeneral,
  CareerPageOnboardingGeneral,
} from '@src/pages/Settings/CareerPage/General'
import { PermissionTypes } from '@src/store/auth/types'
import { careerPageSettings } from '@src/api/settings'
import { SettingsForm } from '../common/SettingsForm'

const routes = [
  {
    title: 'Careers page settings',
    path: ROUTES.SETTINGS.CAREER_PAGE,
    url: ROUTES.SETTINGS.CAREER_PAGE,
    canView: [
      PermissionTypes.ViewCareerPagePreferences,
      PermissionTypes.ChangeCareerPagePreferences,
    ],
    component: CareerPageGeneral,
  },
]

const onboardingRoutes = [
  {
    title: 'Careers page settings',
    path: ROUTES.SETTINGS.CAREER_PAGE_ONBOARDING,
    url: ROUTES.SETTINGS.CAREER_PAGE_ONBOARDING,
    canView: [
      PermissionTypes.ViewCareerPagePreferences,
      PermissionTypes.ChangeCareerPagePreferences,
    ],
    component: CareerPageOnboardingGeneral,
  },
]

export const CareerPageSettings = () => {
  return <SettingsForm routes={routes} api={careerPageSettings} />
}

export const CareerPageOnboardingSettings = () => {
  return <SettingsForm routes={onboardingRoutes} api={careerPageSettings} />
}
