import React, { useState } from 'react'
import PublicPageLayout from '@components/CommonSC/PublicPageLayout'
import CareersJobList from '@src/pages/Careers/CareersJobList'
import Header, { PageType } from '@src/pages/Careers/Header'
import Footer from '@src/pages/Careers/Footer'
import { Box, Flex, MoreBar, Popup } from '@revolut/ui-kit'
import { connect } from 'lape'
import SettingsButtons from '@src/features/SettingsButtons'

const Preview = () => {
  const [openPreview, setOpenPreview] = useState(false)
  return (
    <Box>
      <SettingsButtons>
        <MoreBar.Action
          onClick={e => {
            e.stopPropagation()
            setOpenPreview(true)
          }}
        >
          Preview
        </MoreBar.Action>
      </SettingsButtons>
      {openPreview && (
        <Popup
          open
          size="lg"
          onClose={() => {
            setOpenPreview(false)
          }}
        >
          <Flex flexDirection="column" minHeight="100%">
            <Header type={PageType.jobList} />
            <PublicPageLayout
              noPadding
              maxWidth="1000px"
              m="auto"
              px="s-8"
              style={{ flexGrow: 1 }}
            >
              <CareersJobList />
            </PublicPageLayout>
            <Footer />
          </Flex>
        </Popup>
      )}
    </Box>
  )
}

export default connect(Preview)
