import { Icon, TableWidget, TextButton, Token } from '@revolut/ui-kit'
import AdjustableTable from '@src/components/Table/AdjustableTable'
import { selectorKeys } from '@src/constants/api'
import {
  roadmapDueDate,
  roadmapGenericNameColumn,
  roadmapPriorityColumn,
  roadmapProgressColumn,
  roadmapStatusColumn,
  roadmapStartDate,
  roadmapResolutionAtColumn,
  roadmapOwnerColumn,
  roadmapJiraLabelColumn,
  roadmapEpicUrlColumn,
  getDeleteRoadmapColumn,
} from '@src/constants/columns/roadmap'
import { TableNames } from '@src/constants/table'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { CellTypes, ColumnCellInterface } from '@src/interfaces/data'
import { GoalsInterface } from '@src/interfaces/goals'
import { RoadmapInterface } from '@src/interfaces/roadmaps'
import React, { useState } from 'react'
import { useSubmitFlowHelpers } from '../../../common/utils'
import { captureException } from '@sentry/react'
import { roadmapsRequests } from '@src/api/roadmaps'
import { useTableReturnType } from '@src/components/Table/hooks'

export const RoadmapsList = ({
  viewMode,
  table,
}: {
  viewMode: boolean
  table?: useTableReturnType<RoadmapInterface>
}) => {
  const { values } = useLapeContext<GoalsInterface>()
  const [pendingDeleteId, setPendingDeleteId] = useState<number>()
  const { confirmationDialog, confirm, showError } = useSubmitFlowHelpers({
    confirmProps: { variant: 'compact' },
  })

  const cells: ColumnCellInterface<RoadmapInterface>[] = viewMode
    ? [
        {
          ...roadmapGenericNameColumn,
          width: 200,
        },
        {
          ...roadmapPriorityColumn,
          width: 80,
        },
        {
          ...roadmapProgressColumn,
          width: 100,
        },
        {
          ...roadmapStatusColumn,
          width: 80,
        },
        {
          ...roadmapStartDate,
          width: 80,
        },
        {
          ...roadmapDueDate,
          width: 80,
        },
        {
          ...roadmapResolutionAtColumn,
          width: 80,
        },
        {
          ...roadmapOwnerColumn,
          width: 80,
        },
        {
          ...roadmapJiraLabelColumn,
          width: 80,
        },
        {
          ...roadmapEpicUrlColumn,
          width: 80,
        },
        {
          ...getDeleteRoadmapColumn({
            handleDelete: async id => {
              const confirmed = await confirm({
                label: 'Confirm',
                body: 'Are you sure you want to delete roadmap?',
                yesBtnVariant: 'negative',
                yesMessage: 'Delete',
              })

              if (confirmed.status !== 'confirmed') {
                return
              }

              try {
                await roadmapsRequests.deleteItem(id)
                values.roadmaps = values.roadmaps.filter(r => r.id !== id)
              } catch (err) {
                captureException(err)
                showError('Failed to delete roadmap', 'Please, try again')
              }
            },
          }),
          width: 80,
        },
      ]
    : [
        {
          type: CellTypes.text,
          idPoint: 'review_cycle.id',
          dataPoint: 'review_cycle.name',
          sortKey: null,
          filterKey: null,
          selectorsKey: selectorKeys.none,
          title: 'Cycle',
          width: 100,
        },
        {
          ...roadmapGenericNameColumn,
          sortKey: null,
          filterKey: null,
          width: 200,
        },
        {
          ...roadmapPriorityColumn,
          sortKey: null,
          filterKey: null,
          width: 80,
        },
        {
          ...roadmapDueDate,
          sortKey: null,
          filterKey: null,
          width: 80,
        },
        {
          ...roadmapEpicUrlColumn,
          sortKey: null,
          filterKey: null,
          width: 80,
        },
        {
          type: CellTypes.insert,
          idPoint: 'actions_column',
          dataPoint: 'actions_column',
          sortKey: null,
          filterKey: null,
          selectorsKey: selectorKeys.none,
          textAlign: 'right',
          insert: ({ data }) => {
            return (
              <TextButton
                disabled={!!pendingDeleteId}
                onClick={async () => {
                  try {
                    const confirmed = await confirm({
                      body: `Are you sure you want to delete ${data.name} roadmap`,
                      yesBtnVariant: 'negative',
                      yesMessage: 'Delete',
                    })

                    if (confirmed.status === 'confirmed') {
                      setPendingDeleteId(data.id)
                      await roadmapsRequests.deleteItem(data.id)
                      values.roadmaps = values.roadmaps.filter(r => r.id !== data.id)
                    }
                  } catch (err) {
                    captureException(err)
                    showError('Failed to delete roadmap')
                  } finally {
                    setPendingDeleteId(undefined)
                  }
                }}
              >
                <Icon
                  name={pendingDeleteId === data.id ? 'Loading' : 'Delete'}
                  size={16}
                  color={Token.color.greyTone50}
                />
              </TextButton>
            )
          },
          title: '',
          width: 50,
        },
      ]

  return values.roadmaps?.length ? (
    <TableWidget style={{ padding: 0 }}>
      <TableWidget.Table>
        <AdjustableTable<RoadmapInterface & { actions_column?: never }>
          hideCountAndButtonSection
          name={TableNames.RoadmapsMain}
          {...(table || {})}
          data={values.roadmaps}
          count={values.roadmaps.length}
          dataType="Roadmap"
          row={{
            cells,
          }}
        />
      </TableWidget.Table>
      {confirmationDialog}
    </TableWidget>
  ) : null
}
