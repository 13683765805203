import { ButtonSkeleton, MoreBar, StatusPopup, useStatusPopup } from '@revolut/ui-kit'
import { captureException } from '@sentry/react'
import { createDraftGoal } from '@src/api/goals'
import { useGetSelectors } from '@src/api/selectors'
import { EntityTypes, selectorKeys } from '@src/constants/api'
import { ROUTES } from '@src/constants/routes'
import { useOrgEntity } from '@src/features/OrgEntityProvider/OrgEntityProvider'
import { GoalContentType } from '@src/interfaces/goals'
import { selectUser } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { pathToUrl } from '@src/utils/router'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

const useDefaultWriteAccess = () => {
  const { entity } = useOrgEntity()

  const canAddGoals = !!entity?.data.field_options?.permissions?.includes(
    PermissionTypes.CanAddGoals,
  )

  return canAddGoals
}

export const AddGoalAction = () => {
  const location = useLocation()
  const { navigateWithEntity, entity } = useOrgEntity()
  const { data: contentTypes, isLoading } = useGetSelectors<GoalContentType>(
    selectorKeys.goal_content_types,
  )
  const canAdd = useDefaultWriteAccess()
  const [pending, setPending] = useState(false)
  const user = useSelector(selectUser)
  const statusPopup = useStatusPopup()

  if (isLoading) {
    return <ButtonSkeleton />
  }

  const handleNew = async () => {
    setPending(true)

    try {
      const response = await createDraftGoal({
        owner: { id: user.id },
        is_company: entity?.type === EntityTypes.company,
        content_type:
          entity?.type === EntityTypes.company
            ? undefined
            : contentTypes?.find(({ model }) => model === entity?.type),
        object_id: entity?.data.id,
      })
      navigateWithEntity(pathToUrl(ROUTES.FORMS.GOAL.EDIT, { id: response.data.id }), {
        reviewCycleId: new URLSearchParams(location.search).get('cycle__id'),
        isNew: true,
      })
    } catch (err) {
      captureException(err)
      const description =
        err.response?.data?.detail || 'Something went wrong. Please try again.' // ToDo: agree on possible error from BE

      statusPopup.show(
        <StatusPopup variant="error">
          <StatusPopup.Title>Failed to create goal</StatusPopup.Title>
          <StatusPopup.Description>{description}</StatusPopup.Description>
        </StatusPopup>,
      )
      setPending(false)
    }
  }

  return canAdd ? (
    <MoreBar.Action onClick={handleNew} useIcon="Plus" pending={pending}>
      Add new goal
    </MoreBar.Action>
  ) : null
}
