import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { Box, Layout } from '@revolut/ui-kit'

import { ROUTES } from '@src/constants/routes'
import Page404 from '@src/pages/Page404/Page404'

import { LandingPage } from './LandingPage'
import { Candidates } from './Candidates'
import { Documents } from '@src/pages/OnboardingChecklistV2/Documents'
import { EmployeeRecords } from '@src/pages/OnboardingChecklistV2/EmployeeRecords'
import { Goals } from '@src/pages/OnboardingChecklistV2/Goals'
import { ImportEmployees } from '@src/pages/OnboardingChecklistV2/ImportEmployees'
import { Interviews } from '@src/pages/OnboardingChecklistV2/Interviews'
import { Payroll } from '@src/pages/OnboardingChecklistV2/Payroll'
import { PerformanceReviews } from '@src/pages/OnboardingChecklistV2/PerformanceReviews'
import { TimeManagement } from '@src/pages/OnboardingChecklistV2/TimeManagement'
import { Jobs } from '@src/pages/OnboardingChecklistV2/Jobs'
import { Teams } from '@src/pages/OnboardingChecklistV2/Teams'
import { Roles } from '@src/pages/OnboardingChecklistV2/Roles'
import { InviteTeamPage } from './InviteTeam'
import { JobsEdit } from '@src/pages/OnboardingChecklistV2/Jobs/JobsEdit'

export const OnboardingChecklistV2 = () => {
  return (
    <Layout variant="container(wide) main(wide) side(wide)">
      <Layout.Menu />
      <Layout.Main>
        <Switch>
          <Route
            exact
            path={ROUTES.ONBOARDING_CHECKLIST_V2.ALL}
            component={LandingPage}
          />
          <Route
            exact
            path={ROUTES.ONBOARDING_CHECKLIST_V2.INVITE_ADMINS.ANY}
            component={InviteTeamPage}
          />
          <Route
            exact
            path={ROUTES.ONBOARDING_CHECKLIST_V2.CANDIDATES.ANY}
            component={Candidates}
          />
          <Route
            exact
            path={ROUTES.ONBOARDING_CHECKLIST_V2.DOCUMENTS.ANY}
            component={Documents}
          />
          <Route
            exact
            path={ROUTES.ONBOARDING_CHECKLIST_V2.EMPLOYEE_RECORDS.ANY}
            component={EmployeeRecords}
          />
          <Route
            exact
            path={ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.ANY}
            component={Goals}
          />
          <Route
            exact
            path={ROUTES.ONBOARDING_CHECKLIST_V2.IMPORT_EMPLOYEES.ANY}
            component={ImportEmployees}
          />
          <Route
            exact
            path={ROUTES.ONBOARDING_CHECKLIST_V2.INTERVIEWS.ANY}
            component={Interviews}
          />
          <Route
            exact
            path={ROUTES.ONBOARDING_CHECKLIST_V2.PAYROLL.ANY}
            component={Payroll}
          />
          <Route
            exact
            path={ROUTES.ONBOARDING_CHECKLIST_V2.REVIEWS.ANY}
            component={PerformanceReviews}
          />
          <Route
            exact
            path={ROUTES.ONBOARDING_CHECKLIST_V2.TIME_MANAGEMENT.ANY}
            component={TimeManagement}
          />
          <Route exact path={ROUTES.ONBOARDING_CHECKLIST_V2.JOBS.ANY} component={Jobs} />
          <Route
            exact
            path={ROUTES.ONBOARDING_CHECKLIST_V2.JOBS_EDIT}
            component={JobsEdit}
          />
          <Route
            exact
            path={ROUTES.ONBOARDING_CHECKLIST_V2.TEAMS.ANY}
            component={Teams}
          />
          <Route
            exact
            path={ROUTES.ONBOARDING_CHECKLIST_V2.ROLES.ANY}
            component={Roles}
          />
          <Route>
            <Page404 />
          </Route>
        </Switch>
        <Box id="approval-sidebar-portal" />
      </Layout.Main>
      <Layout.Side />
    </Layout>
  )
}
