import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { Bullet, Group } from '@revolut/ui-kit'
import { ROUTES } from '@src/constants/routes'
import NewSelectionCard from '@components/NewSelectionCard/NewSelectionCard'
import { PageBody } from '@src/components/Page/PageBody'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'

export const GeneralSettings = () => {
  const permissions = useSelector(selectPermissions)
  const canViewCustomField = permissions.includes(PermissionTypes.ViewCustomField)
  const canViewTotalCompensationPreferences = permissions.includes(
    PermissionTypes.ViewTotalCompensationPreferences,
  )
  const canViewLifecycleSettings = permissions.includes(
    PermissionTypes.ViewLifecycleSettings,
  )

  return (
    <>
      <PageHeader
        backUrl={ROUTES.SETTINGS.ALL}
        subtitle="All settings for employee work and personal profiles, employee groups and custom fields"
        title="Employee app settings"
      />
      <PageBody>
        <Group mb="s-24">
          <NewSelectionCard
            icon="Profile"
            title="Profile"
            to={ROUTES.SETTINGS.EMPLOYEES.PROFILE}
            subtitle={
              <FormattedMessage
                id="settings.employees.profileSubtitle"
                defaultMessage="Settings related to the work and personal profiles of employees"
              />
            }
            use={InternalLink}
          />
          {canViewCustomField && (
            <NewSelectionCard
              icon={() => (
                <Bullet bg="accent" variant="filled">
                  C
                </Bullet>
              )}
              title="Employee attributes"
              to={ROUTES.SETTINGS.EMPLOYEES.ATTRIBUTES}
              subtitle={
                <FormattedMessage
                  id="settings.employees.attributesSubtitle"
                  defaultMessage="Settings related to the custom fields functionality"
                />
              }
              use={InternalLink}
            />
          )}
          {canViewTotalCompensationPreferences && (
            <NewSelectionCard
              icon="CreditBag"
              title="Compensation settings"
              to={ROUTES.SETTINGS.COMPENSATION.GENERAL}
              subtitle={
                <FormattedMessage
                  id="settings.employees.compensationSubtitle"
                  defaultMessage="Settings for the compensation functionalities"
                />
              }
              use={InternalLink}
            />
          )}
          {canViewLifecycleSettings && (
            <NewSelectionCard
              icon="ArrowExchange"
              title="Lifecycle settings"
              to={ROUTES.SETTINGS.LIFECYCLE}
              subtitle={
                <FormattedMessage
                  id="settings.employees.lifecycleSubtitle"
                  defaultMessage="Settings for onboarding and offboarding"
                />
              }
              use={InternalLink}
            />
          )}
        </Group>
      </PageBody>
    </>
  )
}
