import React from 'react'
import { SORT_DIRECTION } from '@src/interfaces/data'
import AdjustableTable from '@components/Table/AdjustableTable'
import { useTable } from '@components/Table/hooks'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { Flex, MoreBar, Bar, FilterButton } from '@revolut/ui-kit'
import { Plus, Pencil } from '@revolut/icons'
import Stat from '@components/Stat/Stat'
import { PermissionTypes } from '@src/store/auth/types'
import { useSelector } from 'react-redux'
import { selectPermissions, selectUser } from '@src/store/auth/selectors'
import {
  departmentAuditColumn,
  departmentBudgetColumn,
  departmentCommunicationColumn,
  departmentCultureColumn,
  departmentGenericNameColumn,
  departmentHeadcountColumn,
  departmentRequisitionsColumn,
  departmentRoadmapColumn,
  departmentStatusColumn,
  nipsColumn,
  departmentKPIColumn,
} from '@src/constants/columns/department'
import { circlesKriColumn } from '@src/constants/columns/team'
import { ownerNameColumn } from '@src/constants/columns/employee'
import { Statuses } from '@src/interfaces'
import { karmaPercentColumn } from '@src/constants/columns/karma'
import { DepartmentInterface } from '@src/interfaces/deparment'
import { departmentRequests } from '@src/api/department'
import ExportMenu from '@src/features/ExportMenu/ExportMenu'
import { filterSortPageIntoQuery } from '@src/utils/table'
import SearchTable from '@src/components/Table/SearchTable/SearchTable'
import { useLocalStorage } from '@src/hooks/useLocalStorage'
import { LOCAL_STORAGE } from '@src/constants/api'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { TableNames } from '@src/constants/table'
import {
  useGetOrganisationSettings,
  useGetRoadmapSettings,
  useGlobalSettings,
} from '@src/api/settings'
import { withFavourites } from '@src/features/FavouritesFilter/withFavourites'
import { useFavouritesFilter } from '@src/features/FavouritesFilter/useFavouritesFilter'
import { useGetSlackPreferences } from '@src/api/integrations'
import { SettingsButton } from '@src/features/SettingsButtons/SettingsButton/SettingsButton'
import { settingsConfig } from '@src/pages/Settings/SettingsLandingPage/constants'

const row = {
  linkToForm: ({ id }: { id: number }) => {
    if (id) {
      navigateTo(pathToUrl(ROUTES.FORMS.DEPARTMENT.SUMMARY, { id }))
    } else {
      navigateTo(pathToUrl(ROUTES.FORMS.DEPARTMENT.SETTINGS, { id }))
    }
  },
  cells: [
    {
      ...departmentGenericNameColumn,
      width: 180,
    },
    {
      ...ownerNameColumn,
      width: 130,
    },
    {
      ...departmentHeadcountColumn,
      width: 130,
    },
    {
      ...departmentRequisitionsColumn,
      width: 100,
    },
    {
      ...departmentKPIColumn,
      width: 100,
    },
    {
      ...departmentRoadmapColumn,
      width: 130,
    },
    {
      ...nipsColumn,
      width: 80,
    },
    {
      ...departmentAuditColumn,
      width: 120,
    },
    {
      ...circlesKriColumn,
      width: 130,
    },
    {
      ...karmaPercentColumn,
      width: 100,
    },
    {
      ...departmentBudgetColumn,
      width: 152,
    },
    {
      ...departmentCommunicationColumn,
      width: 160,
    },
    {
      ...departmentStatusColumn,
      width: 130,
    },
  ],
}

const DepartmentsTable = () => {
  const user = useSelector(selectUser)
  const [showMyDepartments, setShowMyDepartments] = useLocalStorage(
    LOCAL_STORAGE.SHOW_MY_DEPARTMENTS,
    false,
  )

  const { data: settings } = useGetOrganisationSettings()
  const { data: roadmapSettings } = useGetRoadmapSettings()
  const { data: slackSettings } = useGetSlackPreferences()
  const { settings: globalSettings } = useGlobalSettings()

  const roadmapEnabled = !!roadmapSettings?.enabled
  const findingsEnabled = !!settings?.findings?.enabled
  const riskEnabled = !!settings?.risk?.enabled
  const karmaEnabled = !!settings?.karma?.enabled
  const budgetEnabled = !!settings?.enable_budget_management
  const approvalsEnabled = !!settings?.enable_departments_approvals
  const slackEnabled = !!slackSettings?.enabled
  const engagementEnabled = !!globalSettings?.engagement_enabled

  const { FavouritesFilter, initialFilter } = useFavouritesFilter('department')

  const getFilterByOwner = (setFilter: boolean) => ({
    filters: setFilter ? [{ name: user.display_name, id: user.id }] : [],
    columnName: 'owner__id',
    nonResettable: true,
  })

  const getInitialFilter = () => {
    const filter = []

    filter.push({
      filters: [{ name: Statuses.active, id: Statuses.active }],
      columnName: 'status',
      nonResettable: true,
    })

    filter.push(getFilterByOwner(showMyDepartments))

    if (initialFilter) {
      filter.push(initialFilter)
    }

    return filter
  }

  const sortBy = [
    {
      sortBy: 'name',
      direction: SORT_DIRECTION.DESC,
    },
  ]
  const filterBy = getInitialFilter()
  const table = useTable<DepartmentInterface>(departmentRequests, filterBy, sortBy)
  const permissions = useSelector(selectPermissions)

  const canAdd = permissions.includes(PermissionTypes.AddDepartment)
  const canManage = permissions.includes(PermissionTypes.ManageDepartments)

  const filterQuery = filterSortPageIntoQuery(table.sortBy, table.filterBy, 1)

  const onToggleMyDepartments = () => {
    setShowMyDepartments(!showMyDepartments)
    table.onFilterChange(getFilterByOwner(!showMyDepartments))
  }

  return (
    <>
      <Flex flexDirection="column" width="100%">
        <Flex flexWrap="wrap" justifyContent="space-between">
          <Flex mb="s-24">
            <Stat
              label="Total"
              val={table?.loading ? undefined : table?.count}
              mr="s-32"
            />
          </Flex>
          <SearchTable
            placeholder="Search by department name"
            mb="s-24"
            onFilter={table.onFilterChange}
          />
        </Flex>
        <Flex mb="s-16" justifyContent="space-between">
          <MoreBar maxCount={3}>
            {canAdd && (
              <MoreBar.Action
                use={InternalLink}
                to={pathToUrl(ROUTES.FORMS.DEPARTMENT.SETTINGS)}
                useIcon={Plus}
              >
                Add Department
              </MoreBar.Action>
            )}
            {canManage && (
              <MoreBar.Action
                use={InternalLink}
                to={pathToUrl(ROUTES.FORMS.UPDATE_ORGANISATION_STRUCTURE.DEPARTMENT)}
                useIcon={Pencil}
              >
                Edit
              </MoreBar.Action>
            )}
            <SettingsButton
              path={ROUTES.SETTINGS.ORGANISATION.LIST}
              canView={settingsConfig.teams.canView}
            />
            <ExportMenu
              fileName="Departments"
              request={departmentRequests.getExport}
              filterQuery={filterQuery}
            />
          </MoreBar>
          <Bar>
            <FilterButton onClick={onToggleMyDepartments} active={showMyDepartments}>
              My departments
            </FilterButton>
            <FavouritesFilter table={table} />
          </Bar>
        </Flex>
        <Flex style={{ position: 'relative' }} flex="1 0">
          <AdjustableTable<DepartmentInterface>
            name={TableNames.Departments}
            useWindowScroll
            dataType="Department"
            row={row}
            {...table}
            noDataMessage="Departments will appear here."
            hiddenCells={{
              [departmentRoadmapColumn.idPoint]: !roadmapEnabled,
              [circlesKriColumn.idPoint]: !riskEnabled,
              [departmentCultureColumn.idPoint]: !engagementEnabled,
              [departmentAuditColumn.idPoint]: !findingsEnabled,
              [karmaPercentColumn.idPoint]: !karmaEnabled,
              [departmentBudgetColumn.idPoint]: !budgetEnabled,
              [departmentCommunicationColumn.idPoint]: !slackEnabled,
              [departmentStatusColumn.idPoint]: !approvalsEnabled,
            }}
          />
        </Flex>
      </Flex>
    </>
  )
}

export default withFavourites(DepartmentsTable)
