import React from 'react'

import { API, selectorKeys } from '@src/constants/api'
import { BulkEditExistingEntitiesAction } from '@src/features/GenericEditableTable/components'
import { getBulkSessionFieldsMap } from '@src/features/GenericEditableTable/helpers'
import { TableView } from '../common/TableView'
import { EditableEmployeesTableProps } from '../common'
import { row, mandatoryBulkSessionFields, bulkFieldsDependencies } from './common'

type Props = {
  sessionRoute: string
} & Omit<
  EditableEmployeesTableProps,
  'row' | 'renderEditActionsRight' | 'bulkSessionFieldsMap'
>
export const EditableEmployeesTableDetailed = ({
  sessionRoute,
  previewActions,
  renderEditActionsLeft,
  useMainAddForm,
  isPreview,
}: Props) => {
  const bulkSessionFieldsMap = getBulkSessionFieldsMap(
    mandatoryBulkSessionFields,
    bulkFieldsDependencies,
  )
  return (
    <TableView
      row={row}
      isPreview={isPreview}
      sessionRoute={sessionRoute}
      previewActions={previewActions}
      useMainAddForm={useMainAddForm}
      renderEditActionsLeft={renderEditActionsLeft}
      bulkSessionFieldsMap={getBulkSessionFieldsMap(
        mandatoryBulkSessionFields,
        bulkFieldsDependencies,
      )}
      renderEditActionsRight={props => (
        <>
          <BulkEditExistingEntitiesAction
            sessionRoute={sessionRoute}
            buttonIcon="LocationPin"
            field="location"
            selector={selectorKeys.location}
            {...props}
            apiEndpoint={API.EMPLOYEE_UPLOADS}
            fieldsForBulkEdit={bulkSessionFieldsMap.location}
          />
          <BulkEditExistingEntitiesAction
            sessionRoute={sessionRoute}
            buttonIcon="Profile"
            field="line_manager"
            label="manager"
            selector={selectorKeys.all_employees_avatar_email}
            selectorField="email"
            {...props}
            apiEndpoint={API.EMPLOYEE_UPLOADS}
            fieldsForBulkEdit={bulkSessionFieldsMap.line_manager}
          />
        </>
      )}
    />
  )
}
