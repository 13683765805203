import React from 'react'
import styled from 'styled-components'
import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { selectorKeys } from '@src/constants/api'
import {
  ContributorType,
  TalentPerformanceInterface,
} from '@src/interfaces/talent/performance'
import EmployeePerformanceChart from '@components/Charts/EmployeePerformanceChart/EmployeePerformanceChart'
import { getEmployeeGradesGraph } from '@src/api/employees'
import { Color, Flex, Text } from '@revolut/ui-kit'
import GradeLockIcon from '@src/features/Calibration/GradeLockIcon'
import PerformanceRatingLabelTag from '@components/PerformanceRatingLabelTag/PerformanceRatingLabelTag'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import { RevolutTheme } from '@src/styles/theme'
import { rankingScoreToColor, ratingToColor } from '@src/utils/performance'
import { TalentPerformanceGradeColumn } from '@components/TalentPerformanceGradeColumn/TalentPerformanceGradeColumn'
import { BudgetInterface } from '@src/interfaces/budgets'
import { Colored } from '@src/components/ColumnInserts/ColoredPercent/ColoredPercent'
import { getColor } from '@src/styles/colors'
import { BarChart } from '@revolut/icons'
import { TalentPerformanceObsoleteGradeColumn } from '@components/TalentPerformanceGradeColumn/TalentPerformanceObsoleteGradeColumn'
import isNumber from 'lodash/isNumber'
import round from 'lodash/round'

const Cell = styled.div``

const TooltipContainer = styled.div`
  padding: 11px;
  color: ${props => props.theme.colors.background};
  width: 180px;
`

export const employeeNameColumn: ColumnInterface<TalentPerformanceInterface> = {
  type: CellTypes.insert,
  idPoint: 'employee.id',
  dataPoint: 'employee.full_name',
  sortKey: 'employee__full_name',
  filterKey: 'employee__id',
  selectorsKey: selectorKeys.all_employees,
  title: 'Employee',
  insert: ({ data }) => (
    <Flex alignItems="center">
      <UserWithAvatar
        disableLink
        full_name={data.employee.full_name}
        avatar={data.employee.avatar}
        id={data.employee.id}
      />
    </Flex>
  ),
}

export const positionColumn: ColumnInterface<TalentPerformanceInterface> = {
  type: CellTypes.text,
  idPoint: 'specialisation.id',
  dataPoint: 'specialisation.name',
  sortKey: 'specialisation__name',
  filterKey: 'specialisation__id',
  selectorsKey: selectorKeys.specialisations,
  title: 'Role (Specialisation)',
}

export const seniorityColumn: ColumnInterface<TalentPerformanceInterface> = {
  type: CellTypes.text,
  idPoint: 'seniority.id',
  dataPoint: 'seniority.name',
  sortKey: 'seniority__level',
  filterKey: 'seniority__id',
  selectorsKey: selectorKeys.seniority,
  title: 'Seniority',
}

export const teamColumn: ColumnInterface<TalentPerformanceInterface> = {
  type: CellTypes.text,
  idPoint: 'team__id',
  dataPoint: 'team__name',
  sortKey: 'team__name',
  filterKey: 'team__id',
  selectorsKey: selectorKeys.team,
  title: 'Team',
}

export const lineManagerColumn: ColumnInterface<TalentPerformanceInterface> = {
  type: CellTypes.insert,
  idPoint: 'line_manager.id',
  dataPoint: 'line_manager.full_name',
  sortKey: null,
  filterKey: 'line_manager__id',
  selectorsKey: selectorKeys.all_employees,
  title: 'LM',
  insert: ({ data }) =>
    data.line_manager ? (
      <UserWithAvatar
        full_name={data.line_manager.full_name}
        status={data.line_manager.status}
        id={data.line_manager.id}
        avatar={data.line_manager.avatar}
        compact
      />
    ) : (
      '-'
    ),
}

export const functionalManagerColumn: ColumnInterface<TalentPerformanceInterface> = {
  type: CellTypes.insert,
  idPoint: 'functional_manager.id',
  dataPoint: 'functional_manager.full_name',
  sortKey: null,
  filterKey: 'functional_manager__id',
  selectorsKey: selectorKeys.all_employees,
  title: 'FM',
  insert: ({ data }) => (
    <Cell>
      {data.functional_manager ? (
        <UserWithAvatar
          full_name={data.functional_manager.full_name}
          status={data.functional_manager.status}
          id={data.functional_manager.id}
          avatar={data.functional_manager.avatar}
          compact
        />
      ) : (
        '-'
      )}
    </Cell>
  ),
}

export const calibratorColumn = (
  field: 'function_grade_calibrator' | 'department_grade_calibrator',
): ColumnInterface<TalentPerformanceInterface> => {
  return {
    type: CellTypes.insert,
    idPoint: `${field}.id`,
    dataPoint: `${field}.full_name`,
    sortKey: null,
    filterKey: `${field}__id`,
    selectorsKey: selectorKeys.all_employees,
    title: 'Delegated calibrator',
    insert: ({ data }) => (
      <Cell>
        {data[field] ? (
          <UserWithAvatar
            full_name={data[field]?.full_name}
            status={data[field]?.status}
            id={data[field]?.id}
            avatar={data[field]?.avatar}
            compact
          />
        ) : (
          '-'
        )}
      </Cell>
    ),
  }
}

export const hofGradeColumn: ColumnInterface<TalentPerformanceInterface> = {
  type: CellTypes.insert,
  idPoint: 'function_owner_grade_suggestion',
  dataPoint: 'function_owner_grade_suggestion',
  sortKey: 'function_owner_grade_suggestion__value',
  filterKey: 'function_owner_grade_suggestion',
  selectorsKey: selectorKeys.performance_grades,
  title: 'HoF Grade',
  masked: false,
  maskTooltip: {
    isShown: <TooltipContainer>Click to hide grade.</TooltipContainer>,
    isHidden: <TooltipContainer>Click to show grade.</TooltipContainer>,
  },
}

export const rankingScoreColumn: ColumnInterface<TalentPerformanceInterface> = {
  type: CellTypes.insert,
  idPoint: 'ranking_score',
  dataPoint: 'ranking_score',
  sortKey: 'ranking_score',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  textAlign: 'right',
  background: data =>
    isNumber(data.ranking_score) ? rankingScoreToColor(round(data.ranking_score, 2)) : '',
  title: 'Ranking score',
}

export const hodGradeColumn: ColumnInterface<TalentPerformanceInterface> = {
  type: CellTypes.insert,
  idPoint: 'department_owner_grade_suggestion',
  dataPoint: 'department_owner_grade_suggestion',
  sortKey: 'department_owner_grade_suggestion__value',
  filterKey: 'department_owner_grade_suggestion',
  selectorsKey: selectorKeys.performance_grades,
  title: 'HoD Grade',
  masked: false,
  maskTooltip: {
    isShown: <TooltipContainer>Click to hide grade.</TooltipContainer>,
    isHidden: <TooltipContainer>Click to show grade.</TooltipContainer>,
  },
}

export const commentsColumn: ColumnInterface<TalentPerformanceInterface> = {
  type: CellTypes.insert,
  idPoint: 'comments',
  dataPoint: 'comments',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Comments',
}

export const finalGradeColumn: ColumnInterface<TalentPerformanceInterface> = {
  type: CellTypes.insert,
  idPoint: 'display_grade',
  dataPoint: 'display_grade',
  sortKey: 'display_grade__value',
  filterKey: 'display_grade',
  selectorsKey: selectorKeys.performance_grades,
  title: 'Final grade',
  masked: false,
  maskTooltip: {
    isShown: <TooltipContainer>Click to hide grade.</TooltipContainer>,
    isHidden: <TooltipContainer>Click to show grade.</TooltipContainer>,
  },
}

export const lmGradeColumn: ColumnInterface<TalentPerformanceInterface> = {
  type: CellTypes.insert,
  idPoint: 'line_manager_grade_suggestion',
  dataPoint: 'line_manager_grade_suggestion',
  sortKey: 'line_manager_grade_suggestion__value',
  filterKey: 'line_manager_grade_suggestion',
  selectorsKey: selectorKeys.performance_grades,
  title: 'LM grade',
  masked: false,
  maskTooltip: {
    isShown: <TooltipContainer>Click to hide grade.</TooltipContainer>,
    isHidden: <TooltipContainer>Click to show grade.</TooltipContainer>,
  },
  insert: ({ data, theme }) => {
    return (
      <EmployeePerformanceChart
        id={data.id}
        fetchKpi={getEmployeeGradesGraph('line_manager_grade_suggestion')}
        vertical="left"
      >
        <Flex justifyContent="space-between" width={96} style={{ fontSize: '13px' }}>
          <TalentPerformanceGradeColumn
            id={data.id}
            name="lm_grade"
            grade={data.line_manager_grade_suggestion}
            locked={data.self_employee_locked}
          />
          <Colored color={getColor(theme, Color.GREY_20_OPAQUE_90)}>
            <BarChart size={16} />
          </Colored>
        </Flex>
      </EmployeePerformanceChart>
    )
  },
}

export const lmGradePlainColumn: ColumnInterface<TalentPerformanceInterface> = {
  ...lmGradeColumn,
  insert: ({ data }) => {
    return (
      <Flex justifyContent="space-between" width={96}>
        <TalentPerformanceGradeColumn
          id={data.id}
          name="lm_grade"
          grade={data.line_manager_grade_suggestion}
          locked={data.self_employee_locked}
        />
      </Flex>
    )
  },
}

export const fmGradeColumn: ColumnInterface<TalentPerformanceInterface> = {
  type: CellTypes.insert,
  idPoint: 'functional_manager_grade_suggestion',
  dataPoint: 'functional_manager_grade_suggestion',
  sortKey: 'functional_manager_grade_suggestion__value',
  filterKey: 'functional_manager_grade_suggestion',
  selectorsKey: selectorKeys.performance_grades,
  title: 'FM grade',
  masked: false,
  maskTooltip: {
    isShown: <TooltipContainer>Click to hide grade.</TooltipContainer>,
    isHidden: <TooltipContainer>Click to show grade.</TooltipContainer>,
  },
  insert: ({ data, theme }) => {
    return (
      <EmployeePerformanceChart
        id={data.id}
        fetchKpi={getEmployeeGradesGraph('functional_manager_grade_suggestion')}
        vertical="left"
      >
        <Flex justifyContent="space-between" width={96} style={{ fontSize: '13px' }}>
          <TalentPerformanceGradeColumn
            id={data.id}
            name="fm_grade"
            grade={data.functional_manager_grade_suggestion}
            locked={data.self_employee_locked}
          />
          <Colored color={getColor(theme, Color.GREY_20_OPAQUE_90)}>
            <BarChart size={16} />
          </Colored>
        </Flex>
      </EmployeePerformanceChart>
    )
  },
}

export const fmGradePlainColumn: ColumnInterface<TalentPerformanceInterface> = {
  ...fmGradeColumn,
  insert: ({ data }) => {
    return (
      <Flex justifyContent="space-between" width={96}>
        <TalentPerformanceGradeColumn
          id={data.id}
          name="fm_grade"
          grade={data.functional_manager_grade_suggestion}
          locked={data.self_employee_locked}
        />
      </Flex>
    )
  },
}

export const systemGradeColumn: ColumnInterface<TalentPerformanceInterface> = {
  type: CellTypes.insert,
  idPoint: 'calculated_grade',
  dataPoint: 'calculated_grade',
  sortKey: 'calculated_grade__value',
  filterKey: 'calculated_grade',
  selectorsKey: selectorKeys.performance_grades,
  title: 'System grade',
  masked: false,
  maskTooltip: {
    isShown: <TooltipContainer>Click to hide grade.</TooltipContainer>,
    isHidden: <TooltipContainer>Click to show grade.</TooltipContainer>,
  },
  insert: ({ data }) => {
    return (
      <TalentPerformanceObsoleteGradeColumn
        id={data.id}
        name="system_grade"
        grade={data.calculated_grade}
        locked={data.self_employee_locked}
      />
    )
  },
}

export const systemRecommendedGradeColumn: ColumnInterface<TalentPerformanceInterface> = {
  type: CellTypes.insert,
  idPoint: 'performance_extra_grade_suggestion',
  dataPoint: 'performance_extra_grade_suggestion',
  sortKey: 'performance_extra_grade_suggestion',
  filterKey: 'performance_extra_grade_suggestion',
  selectorsKey: selectorKeys.performance_grades,
  title: 'System grade',
  masked: false,
  maskTooltip: {
    isShown: <TooltipContainer>Click to hide grade.</TooltipContainer>,
    isHidden: <TooltipContainer>Click to show grade.</TooltipContainer>,
  },
  insert: ({ data }) => {
    return (
      <TalentPerformanceGradeColumn
        id={data.id}
        name="system_recommended_grade"
        grade={data.performance_extra_grade_suggestion}
        locked={data.self_employee_locked}
      />
    )
  },
}

export const contributorTypeColumn: ColumnInterface<TalentPerformanceInterface> = {
  type: CellTypes.insert,
  idPoint: 'contributor_type',
  dataPoint: 'contributor_type',
  sortKey: 'reviewed_employee_type',
  filterKey: 'reviewed_employee_type',
  selectorsKey: selectorKeys.performance_reviewed_employee_type,
  title: 'Type',
  insert: ({ data }) => {
    let text = '-'
    switch (data.reviewed_employee_type) {
      case ContributorType.IC:
        text = 'Individual Contributors'
        break

      case ContributorType.Mgr:
        text = 'Managers'
        break

      case ContributorType.PersonalKPI:
        text = 'Individual with personal KPI'
        break
    }

    return <Text>{text}</Text>
  },
}

export const deliverablesRatingColumn: ColumnInterface<TalentPerformanceInterface> = {
  type: CellTypes.insert,
  idPoint: 'deliverables_rating_label',
  dataPoint: 'deliverables_rating_label',
  sortKey: 'deliverables_rating_score__value',
  filterKey: 'deliverables_rating_label',
  selectorsKey: selectorKeys.performance_ratings,
  title: 'Delivery',
  masked: false,
  maskTooltip: {
    isShown: <TooltipContainer>Click to hide rating.</TooltipContainer>,
    isHidden: <TooltipContainer>Click to show rating.</TooltipContainer>,
  },
  insert: ({ data }) =>
    data.self_employee_locked ? (
      <GradeLockIcon />
    ) : (
      <PerformanceRatingLabelTag
        rating={data.deliverables_rating_label}
        fontWeight={400}
        emptyMessage="-"
        data-testid={`deliverables_rating_label__${data.id}`}
      />
    ),
}

export const skillsRatingColumn: ColumnInterface<TalentPerformanceInterface> = {
  type: CellTypes.insert,
  idPoint: 'functional_skills_label',
  dataPoint: 'functional_skills_label',
  sortKey: 'functional_skills__ordering_value',
  filterKey: 'functional_skills_label',
  selectorsKey: selectorKeys.performance_ratings,
  title: 'Skills',
  masked: false,
  maskTooltip: {
    isShown: <TooltipContainer>Click to hide rating.</TooltipContainer>,
    isHidden: <TooltipContainer>Click to show rating.</TooltipContainer>,
  },
  insert: ({ data }) =>
    data.self_employee_locked ? (
      <GradeLockIcon />
    ) : (
      <PerformanceRatingLabelTag
        rating={data.functional_skills_label}
        fontWeight={400}
        emptyMessage="-"
        data-testid={`functional_skills_label__${data.id}`}
      />
    ),
}

export const cultureRatingColumn: ColumnInterface<TalentPerformanceInterface> = {
  type: CellTypes.insert,
  idPoint: 'culture_rating_label',
  dataPoint: 'culture_rating_label',
  sortKey: 'culture_rating_score__value',
  filterKey: 'culture_rating_label',
  selectorsKey: selectorKeys.performance_ratings,
  title: 'Culture',
  masked: false,
  maskTooltip: {
    isShown: <TooltipContainer>Click to hide rating.</TooltipContainer>,
    isHidden: <TooltipContainer>Click to show rating.</TooltipContainer>,
  },
  insert: ({ data }) =>
    data.self_employee_locked ? (
      <GradeLockIcon />
    ) : (
      <PerformanceRatingLabelTag
        rating={data.culture_rating_label}
        fontWeight={400}
        emptyMessage="-"
        data-testid={`culture_skills_label__${data.id}`}
      />
    ),
}

export const systemRatingColumn: ColumnInterface<TalentPerformanceInterface> = {
  type: CellTypes.insert,
  idPoint: 'calculated_rating_label',
  dataPoint: 'calculated_rating_label',
  sortKey: 'calculated_rating_score__value',
  filterKey: 'calculated_rating_label',
  selectorsKey: selectorKeys.performance_ratings,
  title: 'System rating',
  masked: false,
  maskTooltip: {
    isShown: <TooltipContainer>Click to hide rating.</TooltipContainer>,
    isHidden: <TooltipContainer>Click to show rating.</TooltipContainer>,
  },
  background: (data: TalentPerformanceInterface, theme: RevolutTheme) =>
    data.calculated_rating_label && !data.self_employee_locked
      ? ratingToColor(theme, data.calculated_rating_label) || ''
      : '',
  insert: ({ data }) =>
    data.self_employee_locked ? (
      <GradeLockIcon />
    ) : (
      <PerformanceRatingLabelTag
        rating={data.calculated_rating_label}
        fontWeight={400}
        emptyMessage="-"
        data-testid={`system_rating_label__${data.id}`}
      />
    ),
}

export const hofRatingColumn: ColumnInterface<TalentPerformanceInterface> = {
  type: CellTypes.insert,
  idPoint: 'function_owner_rating_label',
  dataPoint: 'function_owner_rating_label',
  sortKey: 'function_owner_rating_score__value',
  filterKey: 'function_owner_rating_label',
  selectorsKey: selectorKeys.performance_ratings,
  title: 'HoF rating',
  masked: false,
  maskTooltip: {
    isShown: <TooltipContainer>Click to hide rating.</TooltipContainer>,
    isHidden: <TooltipContainer>Click to show rating.</TooltipContainer>,
  },
}

export const hodRatingColumn: ColumnInterface<TalentPerformanceInterface> = {
  type: CellTypes.insert,
  idPoint: 'department_owner_rating_label',
  dataPoint: 'department_owner_rating_label',
  sortKey: 'department_owner_rating_score__value',
  filterKey: 'department_owner_rating_label',
  selectorsKey: selectorKeys.performance_ratings,
  title: 'HoD rating',
  masked: false,
  maskTooltip: {
    isShown: <TooltipContainer>Click to hide rating.</TooltipContainer>,
    isHidden: <TooltipContainer>Click to show rating.</TooltipContainer>,
  },
  background: (data: TalentPerformanceInterface, theme: RevolutTheme) =>
    data.department_owner_rating_label && !data.self_employee_locked
      ? ratingToColor(theme, data.department_owner_rating_label) || ''
      : '',
  insert: ({ data }) =>
    data.self_employee_locked ? (
      <GradeLockIcon />
    ) : (
      <PerformanceRatingLabelTag
        rating={data.department_owner_rating_label}
        fontWeight={400}
        emptyMessage="-"
        data-testid={`label_rating_hod__${data.id}`}
      />
    ),
}

export const finalRatingColumn: ColumnInterface<TalentPerformanceInterface> = {
  type: CellTypes.insert,
  idPoint: 'absolute_rating_label',
  dataPoint: 'absolute_rating_label',
  sortKey: 'absolute_rating_score__value',
  filterKey: 'absolute_rating_label',
  selectorsKey: selectorKeys.performance_ratings,
  title: 'Final rating',
  masked: false,
  maskTooltip: {
    isShown: <TooltipContainer>Click to hide rating.</TooltipContainer>,
    isHidden: <TooltipContainer>Click to show rating.</TooltipContainer>,
  },
  background: (data: TalentPerformanceInterface, theme: RevolutTheme) =>
    data.absolute_rating_label && !data.self_employee_locked
      ? ratingToColor(theme, data.absolute_rating_label) || ''
      : '',
  insert: ({ data }) =>
    data.self_employee_locked ? (
      <GradeLockIcon />
    ) : (
      <PerformanceRatingLabelTag
        rating={data.absolute_rating_label}
        fontWeight={400}
        emptyMessage="-"
        data-testid={`final_rating__${data.id}`}
      />
    ),
}

export const deliverablesTalentColumn: ColumnInterface<BudgetInterface> = {
  type: CellTypes.insert,
  idPoint: 'latest_calibrated_grade__deliverables_rating_label',
  dataPoint: 'latest_calibrated_grade__deliverables_rating_label',
  sortKey: 'latest_calibrated_grade__deliverables_rating_score__value',
  filterKey: 'latest_calibrated_grade__deliverables_rating_label',
  selectorsKey: selectorKeys.none,
  title: 'Delivery',
  masked: false,
  maskTooltip: {
    isShown: <TooltipContainer>Click to hide rating.</TooltipContainer>,
    isHidden: <TooltipContainer>Click to show rating.</TooltipContainer>,
  },
  insert: ({ data }) => {
    return (
      <PerformanceRatingLabelTag
        rating={data?.latest_calibrated_grade?.deliverables_rating_label}
        fontWeight={400}
        emptyMessage="-"
        data-testid={`deliverables_rating_label__${data.id}`}
      />
    )
  },
}

export const skillsTalentColumn: ColumnInterface<BudgetInterface> = {
  type: CellTypes.insert,
  idPoint: 'latest_calibrated_grade__functional_skills_label',
  dataPoint: 'latest_calibrated_grade__functional_skills_label',
  sortKey: 'latest_calibrated_grade__functional_skills__ordering_value',
  filterKey: 'latest_calibrated_grade__functional_skills_label',
  selectorsKey: selectorKeys.none,
  title: 'Skills',
  masked: false,
  maskTooltip: {
    isShown: <TooltipContainer>Click to hide rating.</TooltipContainer>,
    isHidden: <TooltipContainer>Click to show rating.</TooltipContainer>,
  },
  insert: ({ data }) => {
    return (
      <PerformanceRatingLabelTag
        rating={data?.latest_calibrated_grade?.functional_skills_label}
        fontWeight={400}
        emptyMessage="-"
        data-testid={`functional_skills_label__${data.id}`}
      />
    )
  },
}

export const cultureTalentColumn: ColumnInterface<BudgetInterface> = {
  type: CellTypes.insert,
  idPoint: 'latest_calibrated_grade__culture_rating_label',
  dataPoint: 'latest_calibrated_grade__culture_rating_label',
  sortKey: 'latest_calibrated_grade__culture_rating_score__value',
  filterKey: 'latest_calibrated_grade__culture_rating_label',
  selectorsKey: selectorKeys.none,
  title: 'Culture',
  masked: false,
  maskTooltip: {
    isShown: <TooltipContainer>Click to hide rating.</TooltipContainer>,
    isHidden: <TooltipContainer>Click to show rating.</TooltipContainer>,
  },
  insert: ({ data }) => (
    <PerformanceRatingLabelTag
      rating={data?.latest_calibrated_grade?.culture_rating_label}
      fontWeight={400}
      emptyMessage="-"
      data-testid={`culture_skills_label__${data.id}`}
    />
  ),
}
