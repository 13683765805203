import React from 'react'
import {
  ActionButton,
  Avatar,
  DetailsCell,
  Group,
  Item,
  ItemSkeleton,
  Link,
  Separator,
  Text,
  Token,
  VStack,
} from '@revolut/ui-kit'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { JobPostingInterface, LinkedInPosting } from '@src/interfaces/jobPosting'
import { useGetLinkedInPostings } from '@src/api/jobPosting'
import TextWithMoreCount from '@src/components/TextWithMoreCount/TextWithMoreCount'
import {
  isRejected,
  isPending,
  isPromoted,
  isBasic,
  isClosed,
} from '@src/pages/Forms/JobPosting/LinkedInPostings/utils'
import {
  LinkedInPostingStatusProps,
  LinkedInPostingStatus,
} from '@src/pages/Forms/JobPosting/LinkedInPostings/components/LinkedInPostingStatus'
import { isPublished } from '@src/pages/Forms/JobPosting/utils'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { FormattedMessage } from 'react-intl'
import { LINKEDIN_TC } from '@src/constants/externalLinks'

type LinkedInPostingDetailsProps = LinkedInPostingStatusProps & {
  locations: string[]
}

const LinkedInPostingDetails = ({ locations, ...props }: LinkedInPostingDetailsProps) => {
  if (!locations.length) {
    return null
  }
  return (
    <DetailsCell>
      <DetailsCell.Title>
        <TextWithMoreCount expandable hideSuffix items={locations} />
      </DetailsCell.Title>
      <DetailsCell.Content>
        <LinkedInPostingStatus {...props} />
      </DetailsCell.Content>
    </DetailsCell>
  )
}

export const LinkedInWidget = () => {
  const { values } = useLapeContext<JobPostingInterface>()
  const { data, isLoading } = useGetLinkedInPostings(values.id)
  if (isLoading) {
    return (
      <Group>
        <ItemSkeleton />
      </Group>
    )
  }
  if (!isPublished(values) && !data?.results?.length) {
    return (
      <Item>
        <Item.Prefix>
          <Avatar useIcon="LogoLinkedIn" />
        </Item.Prefix>
        <Item.Content>
          <Item.Title>LinkedIn postings</Item.Title>
          <Item.Description>
            Publish your job posting in Revolut People to open the LinkedIn job postings
            associated with it
          </Item.Description>
        </Item.Content>
      </Item>
    )
  }
  const linkedInPostings = data?.results ?? []
  const filterLocations = (filter: (linkedInPosting: LinkedInPosting) => boolean) =>
    linkedInPostings
      .filter(filter)
      .map(({ location }: LinkedInPosting) => location.location_name)
  return (
    <Group>
      <Item>
        <Item.Prefix>
          <Avatar useIcon="LogoLinkedIn" />
        </Item.Prefix>
        <Item.Content>
          <Item.Title>LinkedIn postings</Item.Title>
        </Item.Content>
        <Item.Side>
          <ActionButton
            use={InternalLink}
            to={pathToUrl(ROUTES.FORMS.JOB_POSTING.LINKEDIN_POSTINGS, {
              specId: values.specialisation?.id,
              id: values.id,
            })}
          >
            Manage
          </ActionButton>
        </Item.Side>
      </Item>
      <LinkedInPostingDetails
        description="Rejected"
        iconColor={Token.color.red}
        iconName="ExclamationTriangle"
        locations={filterLocations(isRejected)}
        tooltipLabel={
          <VStack gap="s-8">
            {linkedInPostings
              .filter(isRejected)
              .map(({ id, location, status_detail }) => (
                <Text key={id}>
                  {location.location_name}: {status_detail}
                </Text>
              ))}
          </VStack>
        }
      />
      <LinkedInPostingDetails
        description="Pending"
        locations={filterLocations(isPending)}
      />
      <LinkedInPostingDetails
        description="Promoted"
        iconColor={Token.color.greyTone50}
        iconName="StarFilled"
        locations={filterLocations(isPromoted)}
      />
      <LinkedInPostingDetails description="Basic" locations={filterLocations(isBasic)} />
      <LinkedInPostingDetails
        description="Closed"
        locations={filterLocations(isClosed)}
      />
      <Separator />
      <DetailsCell>
        <Text style={{ fontStyle: 'italic' }} fontWeight="bold">
          <FormattedMessage
            id="recruitment.job_postings.linkedin.tc"
            defaultMessage="By posting your job to LinkedIn, you agree to LinkedIn's Jobs {link}"
            values={{
              link: (
                <Link target="_blank" rel="noopener noreferrer" href={LINKEDIN_TC}>
                  <FormattedMessage
                    id="recruitment.job_postings.linkedin.tc_link"
                    defaultMessage="Terms & Conditions"
                  />
                </Link>
              ),
            }}
          />
        </Text>
      </DetailsCell>
    </Group>
  )
}
