import React, { useEffect, useState } from 'react'
import { connect } from 'lape'
import {
  ActionButton,
  Input,
  InputGroup,
  Text,
  Token,
  StatusWidget,
  Widget,
} from '@revolut/ui-kit'
import { paygroupsBulkEdit, paygroupRequests, usePaygroups } from '@src/api/payroll'
import { useGetSelectors } from '@src/api/selectors'
import { selectorKeys } from '@src/constants/api'
import { IdAndName } from '@src/interfaces'
import { PaygroupInterface } from '@src/interfaces/payroll'
import { PageBody } from '@components/Page/PageBody'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import SideBar from '@src/components/SideBar/SideBar'
import AutoStepper from '@components/Stepper/AutoStepper'
import NewStepperTitle from '@components/Stepper/NewStepperTitle'
import Form from '@src/features/Form/Form'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { payrollConfig } from '@src/pages/OnboardingChecklistV2/common/checkpointsConfig'
import OnboardingActions from '../components/OnboardingActions'
import { PaycyclesPreview } from '@src/apps/People/Payroll/Paygroup/General/General'

const PAY_AUTHORITIES_FREQUENCY_DEFAULT = {
  id: 'monthly' as const,
  name: 'Monthly',
}
const PAY_AUTHORITIES_DATE_DAY_OF_MONTH_DEFAULT = 1
const REPORT_SUBMISSION_DATE_DAYS_AFTER_CYCLE_ENDS_DEFAULT = 0
const REPORT_SUBMISSION_DATE_TRIGGER_DEFAULT = {
  id: 'before_pay_date' as const,
  name: 'Before the pay date',
}

const BulkEditForm = () => {
  const { values } = useLapeContext<PaygroupInterface>()
  const { data: paygroups } = usePaygroups()

  const [showPreviewSidebar, setShowPreviewSidebar] = useState(true)

  const monthDayOptions = useGetSelectors<IdAndName>(selectorKeys.month_days)
  const weekDayOptions = useGetSelectors<IdAndName>(selectorKeys.days_of_the_week)

  const isTwice = ['bi_monthly', 'bi_weekly'].includes(values?.pay_frequency?.id)
  const isWeekly = ['weekly', 'bi_weekly'].includes(values?.pay_frequency?.id)

  const findWeekDayById = (value: number | null) =>
    weekDayOptions.data?.find(option => option.id === value) || null
  const findMonthDayById = (value: number | null) =>
    monthDayOptions.data?.find(option => option.id === value) || null

  const canShowPreview = [
    values.pay_frequency,
    values.pay_period_start_day,
    values.pay_date_day,
    values.pay_date_lands_on_weekend,
    values.pay_date_schedule,
    values.cut_off_date_trigger_number_of_days,
    values.cut_off_date_trigger,
  ].every(Boolean)

  useEffect(() => {
    if (!values.pay_authorities_frequency) {
      values.pay_authorities_frequency = PAY_AUTHORITIES_FREQUENCY_DEFAULT
    }

    if (!values.pay_authorities_date_day_of_month) {
      values.pay_authorities_date_day_of_month = PAY_AUTHORITIES_DATE_DAY_OF_MONTH_DEFAULT
    }

    if (!values.report_submission_date_days_after_cycle_ends) {
      values.report_submission_date_days_after_cycle_ends =
        REPORT_SUBMISSION_DATE_DAYS_AFTER_CYCLE_ENDS_DEFAULT
    }

    if (!values.report_submission_date_trigger) {
      values.report_submission_date_trigger = REPORT_SUBMISSION_DATE_TRIGGER_DEFAULT
    }
  }, [])

  const prepareData = () => {
    if (!paygroups) {
      return []
    }

    return paygroups.results.map(paygroup => {
      return {
        ...values,
        id: paygroup.id,
      }
    })
  }

  const renderContent = () => {
    return (
      <AutoStepper>
        <Widget pb="s-16" pt="s-4" px="s-16" mt="s-16">
          <NewStepperTitle title="Schedule" />
          <InputGroup>
            <LapeRadioSelectInput
              label="Pay frequency"
              name="pay_frequency"
              selector={selectorKeys.pay_frequencies}
            />
            {isWeekly ? (
              <LapeRadioSelectInput
                label="When does the pay cycle begin (day of week)"
                name="pay_period_start_day"
                onChange={value => {
                  values.pay_period_start_day = value?.id || null
                }}
                selector={selectorKeys.days_of_the_week}
                value={findWeekDayById(values.pay_period_start_day)}
              />
            ) : (
              <LapeRadioSelectInput
                label="When does the pay cycle begin (day of month)"
                name="pay_period_start_day"
                selector={selectorKeys.month_days}
                onChange={value => {
                  values.pay_period_start_day = value?.id || null
                }}
                value={findMonthDayById(values.pay_period_start_day)}
              />
            )}
            {!isWeekly && isTwice && (
              <LapeRadioSelectInput
                label="Second pay period start (day of month)"
                name="pay_period_start_day"
                selector={selectorKeys.month_days}
                onChange={value => {
                  values.pay_period_second_start_day = value?.id || null
                }}
                value={findMonthDayById(values.pay_period_second_start_day)}
              />
            )}
          </InputGroup>
        </Widget>

        <Widget pb="s-16" pt="s-4" px="s-16" mt="s-16">
          <NewStepperTitle title="Deadlines" />
          <Text color={Token.color.greyTone50} my="s-16" variant="h6" use="div">
            Pay date
          </Text>
          <InputGroup>
            <InputGroup data-testid="pay_date_section" variant="horizontal">
              {isWeekly ? (
                <LapeRadioSelectInput
                  label="Pay date (day of week)"
                  name="pay_date_day"
                  onChange={value => {
                    values.pay_date_day = value?.id || null
                  }}
                  selector={selectorKeys.days_of_the_week}
                  value={findWeekDayById(values.pay_date_day)}
                />
              ) : (
                <LapeRadioSelectInput
                  label="Pay date"
                  name="pay_date_day"
                  onChange={value => {
                    values.pay_date_day = value?.id || null
                  }}
                  selector={selectorKeys.month_days}
                  value={findMonthDayById(values.pay_date_day)}
                />
              )}
              <LapeRadioSelectInput
                label="If pay date lands on weekend or holiday"
                name="pay_date_lands_on_weekend"
                selector={selectorKeys.pay_date_lands_on_weekend_choices}
              />
            </InputGroup>
            <LapeRadioSelectInput
              label="Which month is being paid"
              name="pay_date_schedule"
              selector={selectorKeys.pay_date_schedules}
            />
          </InputGroup>
          <Text color={Token.color.greyTone50} my="s-16" variant="h6" use="div">
            Cut off dates for employee changes
          </Text>
          <InputGroup data-testid="cut_off_date_section" variant="horizontal">
            <LapeNewInput
              label="Number of business days"
              name="cut_off_date_trigger_number_of_days"
              required
              type="number"
            />
            <Input disabled value="days" />
            <LapeRadioSelectInput
              label="Trigger"
              name="cut_off_date_trigger"
              selector={selectorKeys.pay_group_triggers}
            />
          </InputGroup>
        </Widget>
      </AutoStepper>
    )
  }

  return (
    <>
      <ActionButton
        onClick={() => setShowPreviewSidebar(prev => !prev)}
        useIcon="EyeShow"
      >
        Preview Paycycle
      </ActionButton>
      <PageBody>{renderContent()}</PageBody>
      <OnboardingActions
        config={payrollConfig}
        currentStep="Settings"
        disableNext={false}
        onBeforeSubmit={() => paygroupsBulkEdit(prepareData())}
        pendingNext={false}
        nextButtonLabel="Finish"
        nextRoute=""
        isForm={false}
        isLastStep
        updateSteps
      />

      <SideBar
        isOpen={showPreviewSidebar}
        onClose={() => setShowPreviewSidebar(false)}
        title="Preview Paycycle"
        variant="wide"
      >
        {canShowPreview ? (
          <PaycyclesPreview hiddenTimelineSteps={['cut_off_date', 'submission_date']} />
        ) : (
          <StatusWidget>
            <StatusWidget.Image
              image={{
                default: 'https://assets.revolut.com/assets/3d-images/3D026.png',
                '2x': 'https://assets.revolut.com/assets/3d-images/3D026@2x.png',
                '3x': 'https://assets.revolut.com/assets/3d-images/3D026@3x.png',
              }}
            />
            <StatusWidget.Title>Previews will show up here</StatusWidget.Title>
            <StatusWidget.Description>
              You need to complete the Schedule and Deadlines sections before previews can
              be generated.
            </StatusWidget.Description>
          </StatusWidget>
        )}
      </SideBar>
    </>
  )
}

export const PayrollSettings = connect(() => (
  <Form api={paygroupRequests} disableLocalStorageCaching>
    <BulkEditForm />
  </Form>
))
