import React, { useState } from 'react'
import { Portal, Input } from '@revolut/ui-kit'
import { deleteKPIsUploadSession } from '@src/api/importData'
import { successNotification } from '@src/store/notifications/actions'
import { NotificationsInterface } from '@src/interfaces/notifications'
import { approveOrRejectFromDashboard } from '@src/api/employeeChangeRequest'
import { AccessRequestType } from '../GenericNotificationTable'
import {
  accessRequests,
  actionsLookerAccessRequest,
  actionsLookerFolderAccessRequest,
  formLookerAccessRequest,
  formLookerFolderAccessRequest,
  teamAccessRequests,
} from '@src/api/accessRequests'
import { Statuses } from '@src/interfaces'
import { requisitionsRequests } from '@src/api/requisitions'
import { jobDescriptionFormRequest } from '@src/api/jobPosting'
import { ApprovalStatuses } from '@src/interfaces/approvalFlow'
import {
  completeCourseEnrolmentNotification,
  completeTaskNotification,
  notificationsMoverArchive,
  notificationsOwnershipTransferArchive,
  notificationsUpwardRequestDelete,
} from '@src/api/notifications'
import { signEmbedded } from '@src/api/documents'
import { rejectReview } from '@src/api/performanceReview'
import { rolesRequests } from '@src/api/roles'
import { skillRequests } from '@src/api/skills'
import { kpiTargetsRequestsNew } from '@src/api/kpis'
import { linkNotificationToForm } from './utils'
import LoadingAction from '@src/components/Button/LoadingAction'
import { onCreateGoogleEvent } from '@src/pages/Forms/MeetingsWith/common'
import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'
import { TableActionButton } from '@src/components/Button/TableActionButton'
import { approveTimeOffRequest, rejectTimeOffRequest } from '@src/api/timeOff'
import { PerformanceCalibrationActions } from './PerformanceCalibrationActions'
import { useIsNewLayout } from '@src/pages/EmployeeProfile/Layout/helpers'
import { useHasNewScorecards } from '@src/utils/performance'
import { GoalsNotificationActions } from './GoalsActions'

interface NotificationActionProps {
  data: NotificationsInterface
  onSuccess: (id: string) => void
}

export const NotificationAction = ({ data, onSuccess }: NotificationActionProps) => {
  const [openReject, setOpenReject] = useState<null | {
    data: NotificationsInterface
    request: (
      data: NotificationsInterface,
      reject?: boolean,
      rejectReason?: string,
    ) => Promise<any>
    confirmLabel?: string
    yesMessage?: string
  }>()
  const isNewLayout = useIsNewLayout()
  const showNewScorecards = useHasNewScorecards()

  const [confirmationState, setConfirmationState] = useState<{
    data: NotificationsInterface
    loading?: boolean
  } | null>(null)

  const [isRejectPending, setIsRejectPending] = useState(false)
  const [rejectionComment, setRejectionComment] = useState('')

  const takeActions = async (notification: NotificationsInterface) => {
    switch (notification.category) {
      case 'document':
        if (notification.category_extra === 'embedded') {
          return signEmbedded(notification.item_id)
        }
        if (notification.category_extra === 'docusign') {
          return window.open(
            'https://mail.google.com/#search/from%3A*%40docusign.net',
            '_blank',
          )
        }
        return null
      case 'training':
      case 'course_enrollment':
      case 'review_calibration':
      case 'tpsl_process':
        if (notification?.action_url) {
          window.open(notification?.action_url, '__blank')
        }
        return null
      default:
        return null
    }
  }

  const handleRejectReview = async (notification: NotificationsInterface) => {
    await rejectReview(notification?.generated_by?.id!, notification.item_id.toString())
    onSuccess(notification.id)
  }

  const handleApproveRejectRole = async (
    notification: NotificationsInterface,
    reject?: boolean,
    rejectReason?: string,
  ) => {
    const { data: role } = await rolesRequests.getItem(notification.item_id)
    if (role) {
      if (reject) {
        await rolesRequests.patchItem(
          {
            status: Statuses.rejected,
            description: rejectReason,
          },
          role.id,
        )
        onSuccess(notification.id)
      } else if (role.status === Statuses.pending) {
        await rolesRequests.patchItem({ status: Statuses.approved }, role.id)
        onSuccess(notification.id)
      }
    }

    return null
  }

  const handleApproveRejectSkill = async (
    notification: NotificationsInterface,
    reject?: boolean,
    rejectReason?: string,
  ) => {
    const { data: skill } = await skillRequests.getItem(notification.item_id)
    if (skill) {
      if (reject) {
        await skillRequests.patchItem(
          {
            status: Statuses.rejected,
            description: rejectReason,
          },
          skill.id,
        )
        onSuccess(notification.id)
      } else if (skill.status === Statuses.pending) {
        await skillRequests.patchItem({ status: Statuses.approved }, skill.id)
        onSuccess(notification.id)
      }
    }

    return null
  }

  const handleApproveRejectKPI = async (
    notification: NotificationsInterface,
    reject?: boolean,
    rejectReason?: string,
  ) => {
    if (notification.item_uuid) {
      if (reject) {
        await kpiTargetsRequestsNew.update(
          {
            status: Statuses.requires_changes,
            rejection_reason: rejectReason,
          },
          { id: notification.item_uuid },
        )
        onSuccess(notification.id)
      } else {
        await kpiTargetsRequestsNew.update(
          { status: Statuses.approved },
          { id: notification.item_uuid },
        )
        onSuccess(notification.id)
      }
    }

    return null
  }

  const handleApproveRejectDataAccess = async (
    notification: NotificationsInterface,
    reject?: boolean,
    rejectReason?: string,
  ) => {
    const req = (() => {
      switch (notification.category_extra) {
        case AccessRequestType.Team:
          return teamAccessRequests
        case AccessRequestType.User:
          return accessRequests
        case AccessRequestType.LookerFolder:
          return { ...actionsLookerFolderAccessRequest, ...formLookerFolderAccessRequest }
        case AccessRequestType.LookerModel:
          return { ...actionsLookerAccessRequest, ...formLookerAccessRequest }
        default:
          return null
      }
    })()

    if (!req) {
      return null
    }

    if ('getItem' in req) {
      const { data: accessRequest } = await req.getItem(notification.item_id)
      if (accessRequest) {
        if (reject) {
          await req.patchItem(
            {
              status: Statuses.rejected,
              rejection_reason: rejectReason,
            },
            accessRequest.id,
          )
          onSuccess(notification.id)
        } else if (accessRequest.status === Statuses.pending) {
          await req.patchItem({ status: Statuses.approved }, accessRequest.id)
          onSuccess(notification.id)
        }
      }
    } else {
      const { data: accessRequest } = await req.get({ id: `${notification.item_id}` })
      if (accessRequest) {
        if (reject) {
          await req.reject({ ...accessRequest, rejection_reason: rejectReason })
          onSuccess(notification.id)
        } else if (accessRequest.status.id === Statuses.pending) {
          await req.approve(accessRequest)
          onSuccess(notification.id)
        }
      }
    }

    return null
  }

  const handleApproveRejectRequisition = async (
    notification: NotificationsInterface,
    reject?: boolean,
    rejectReason?: string,
  ) => {
    const { data: requisition } = await requisitionsRequests.getItem(notification.item_id)
    if (requisition) {
      if (reject) {
        await requisitionsRequests.patchItem(
          {
            status: Statuses.rejected,
            rejection_comment: rejectReason,
          },
          requisition.id,
        )
        onSuccess(notification.id)
      } else {
        await requisitionsRequests.patchItem(
          { status: Statuses.approved },
          requisition.id,
        )
        onSuccess(notification.id)
      }
    }

    return null
  }

  const handleApproveRejectJobPosting = async (
    notification: NotificationsInterface,
    reject?: boolean,
    rejectReason?: string,
  ) => {
    const { data: jobPosting } = await jobDescriptionFormRequest.get({
      id: String(notification.item_id),
    })
    if (jobPosting) {
      if (reject) {
        await jobDescriptionFormRequest.update(
          {
            approval_status: ApprovalStatuses.Rejected,
            rejection_reason: rejectReason,
          },
          { id: String(jobPosting.id) },
        )
        onSuccess(notification.id)
      } else {
        await jobDescriptionFormRequest.update(
          { approval_status: ApprovalStatuses.Approved },
          { id: String(jobPosting.id) },
        )
        onSuccess(notification.id)
      }
    }

    return null
  }

  const handleApproveRejectChangeRequest = async (
    notification: NotificationsInterface,
    action: 'approve' | 'reject',
  ) => {
    const url = `${notification.action_url}/${action}`
    await approveOrRejectFromDashboard(url)
    onSuccess(notification.id)
  }

  const handleDeleteUpwardReview = async (item_id: number, id: string) => {
    await notificationsUpwardRequestDelete(item_id)
    onSuccess(id)
  }

  const handleApproveRejectTimeOffRequest = async (
    notification: NotificationsInterface,
    reject?: boolean,
    rejectReason?: string,
  ) => {
    if (!notification.generated_by) {
      return null
    }

    const id = notification.item_id
    const employeeId = notification.generated_by.id.toString()

    if (reject) {
      await rejectTimeOffRequest(employeeId, id, rejectReason || '')
    } else {
      await approveTimeOffRequest(employeeId, id)
    }

    onSuccess(notification.id)
    return null
  }

  const renderApproveRequestChange = (
    notification: NotificationsInterface,
    onSubmit: (data: NotificationsInterface, reject?: boolean) => Promise<any>,
  ) => {
    return (
      <>
        <LoadingAction onSubmit={() => onSubmit(notification)}>Approve</LoadingAction>
        <TableActionButton
          onClick={e => {
            e.stopPropagation()
            setOpenReject({
              data: notification,
              request: onSubmit,
              confirmLabel: 'Reason for requesting changes',
              yesMessage: 'Confirm',
            })
          }}
        >
          Request change
        </TableActionButton>
      </>
    )
  }

  const renderApproveReject = (
    notification: NotificationsInterface,
    onSubmit: (data: NotificationsInterface, reject?: boolean) => Promise<any>,
  ) => {
    return (
      <>
        <LoadingAction onSubmit={() => onSubmit(notification)}>Approve</LoadingAction>
        <TableActionButton
          onClick={e => {
            e.stopPropagation()
            setOpenReject({ data: notification, request: onSubmit })
          }}
        >
          Reject
        </TableActionButton>
      </>
    )
  }

  const handleOwnershipItemArchive = async (item_id: number, id: string) => {
    await notificationsOwnershipTransferArchive(item_id)
    onSuccess(id)
  }

  const handleMoverItemArchive = async (item_id: number, id: string) => {
    await notificationsMoverArchive(item_id)
    onSuccess(id)
  }

  const renderButtons = () => {
    switch (data.category) {
      case 'kpi_to_assign':
        return (
          <LoadingAction onSubmit={() => linkNotificationToForm(data)}>
            Set KPIs
          </LoadingAction>
        )
      case 'kpi_to_approve':
        return (
          <LoadingAction onSubmit={() => linkNotificationToForm(data)}>
            Review
          </LoadingAction>
        )
      case 'goal_to_approve':
      case 'goal_to_calibrate':
      case 'goal_to_set':
        return <GoalsNotificationActions data={data} onSuccess={onSuccess} />
      case 'bulk_upload':
        return (
          <>
            <LoadingAction onSubmit={() => linkNotificationToForm(data)}>
              Open
            </LoadingAction>
            <TableActionButton
              onClick={e => {
                e.stopPropagation()
                setConfirmationState({ data })
              }}
            >
              Mark as done
            </TableActionButton>
          </>
        )
      case 'kpi_bulk_upload':
        return (
          <>
            <LoadingAction onSubmit={() => linkNotificationToForm(data)}>
              Check status
            </LoadingAction>
            <TableActionButton
              onClick={e => {
                e.stopPropagation()
                deleteKPIsUploadSession(data.item_id).then(() =>
                  successNotification(`This notification will be removed`),
                )
              }}
            >
              Remove
            </TableActionButton>
          </>
        )
      case 'role':
        return renderApproveReject(data, handleApproveRejectRole)
      case 'requisition':
        return renderApproveReject(data, handleApproveRejectRequisition)
      case 'job_posting':
        return renderApproveReject(data, handleApproveRejectJobPosting)
      case 'skill':
        return renderApproveReject(data, handleApproveRejectSkill)
      case 'data_access':
        return renderApproveReject(data, handleApproveRejectDataAccess)
      case 'kpi':
        return renderApproveRequestChange(data, handleApproveRejectKPI)
      case 'time_off':
        return renderApproveReject(data, handleApproveRejectTimeOffRequest)
      case 'comment':
        return data.category_extra === 'performance_calibration' ? (
          <PerformanceCalibrationActions notification={data} onResolved={onSuccess} />
        ) : (
          <LoadingAction onSubmit={() => linkNotificationToForm(data)}>
            View
          </LoadingAction>
        )
      case 'one_to_one_meeting':
        return (
          <LoadingAction
            onSubmit={() => {
              onCreateGoogleEvent(data.action_url)
              return Promise.resolve()
            }}
          >
            Create new meeting
          </LoadingAction>
        )
      case 'upwards_review':
        return (
          <>
            <LoadingAction
              onSubmit={() =>
                linkNotificationToForm(data, isNewLayout, showNewScorecards)
              }
            >
              Review
            </LoadingAction>
            <TableActionButton
              onClick={e => {
                e.stopPropagation()
                handleDeleteUpwardReview(data.item_id, data.id)
              }}
            >
              Reject
            </TableActionButton>
          </>
        )
      case 'review':
        return (
          <>
            <LoadingAction
              onSubmit={() => linkNotificationToForm(data, undefined, showNewScorecards)}
            >
              Review
            </LoadingAction>
            {data?.generated_by?.id && (
              <TableActionButton
                onClick={e => {
                  e.stopPropagation()
                  handleRejectReview(data)
                }}
              >
                Reject
              </TableActionButton>
            )}
          </>
        )
      case 'probation':
        return (
          <LoadingAction
            onSubmit={() => linkNotificationToForm(data, undefined, showNewScorecards)}
          >
            Review
          </LoadingAction>
        )
      case 'pip':
        return (
          <LoadingAction
            onSubmit={() => linkNotificationToForm(data, undefined, showNewScorecards)}
          >
            Review
          </LoadingAction>
        )
      case 'pending_employee_review':
      case 'deliverable_review':
        return (
          <LoadingAction onSubmit={() => linkNotificationToForm(data)}>
            Review
          </LoadingAction>
        )
      case 'training':
        return (
          <>
            <LoadingAction onSubmit={() => takeActions(data)}>
              Go to Training
            </LoadingAction>
            <TableActionButton
              onClick={e => {
                e.stopPropagation()
                setConfirmationState({ data })
              }}
            >
              Mark as done
            </TableActionButton>
          </>
        )
      case 'course_enrollment':
        return (
          <>
            <LoadingAction onSubmit={() => takeActions(data)}>Go to course</LoadingAction>
            <TableActionButton
              onClick={e => {
                e.stopPropagation()
                setConfirmationState({ data })
              }}
            >
              Mark as done
            </TableActionButton>
          </>
        )
      case 'review_calibration':
        return (
          <>
            <LoadingAction onSubmit={() => takeActions(data)}>Go to Talent</LoadingAction>
            <TableActionButton
              onClick={e => {
                e.stopPropagation()
                setConfirmationState({ data })
              }}
            >
              Mark as done
            </TableActionButton>
          </>
        )
      case 'service_desk':
        return (
          <>
            <LoadingAction onSubmit={() => linkNotificationToForm(data)}>
              Take action
            </LoadingAction>
            <TableActionButton
              onClick={e => {
                e.stopPropagation()
                setConfirmationState({ data })
              }}
            >
              Remove
            </TableActionButton>
          </>
        )

      case 'interview':
        return (
          <LoadingAction onSubmit={() => linkNotificationToForm(data)}>
            Add feedback
          </LoadingAction>
        )
      case 'candidate':
      case 'adhoc_interview':
        return (
          <LoadingAction onSubmit={() => linkNotificationToForm(data)}>
            Open
          </LoadingAction>
        )
      case 'succession':
        return (
          <LoadingAction onSubmit={() => linkNotificationToForm(data)}>
            Add successor
          </LoadingAction>
        )
      case 'document':
        if (data.category_extra === 'embedded') {
          return (
            <LoadingAction onSubmit={() => takeActions(data)}>
              Read and sign
            </LoadingAction>
          )
        }
        if (data.category_extra === 'docusign') {
          return (
            <LoadingAction onSubmit={() => takeActions(data)}>Go to emails</LoadingAction>
          )
        }
        if (data.category_extra === 'uploaded') {
          return (
            <LoadingAction onSubmit={() => linkNotificationToForm(data)}>
              View and upload
            </LoadingAction>
          )
        }
        return (
          <LoadingAction onSubmit={() => linkNotificationToForm(data)}>
            Take action
          </LoadingAction>
        )
      case 'tpsl_process':
        return (
          <LoadingAction onSubmit={() => takeActions(data)}>Take Action</LoadingAction>
        )
      case 'change_request':
        return (
          <>
            <LoadingAction
              type="text"
              onSubmit={() => handleApproveRejectChangeRequest(data, 'approve')}
            >
              Approve
            </LoadingAction>
            <TableActionButton
              onClick={e => {
                e.stopPropagation()
                handleApproveRejectChangeRequest(data, 'reject')
              }}
            >
              Reject
            </TableActionButton>
          </>
        )
      case 'ownership_transfer':
        return (
          <>
            <LoadingAction onSubmit={() => linkNotificationToForm(data)}>
              Take action
            </LoadingAction>
            <TableActionButton
              onClick={e => {
                e.stopPropagation()
                handleOwnershipItemArchive(data.item_id, data.id)
              }}
            >
              Mark as done
            </TableActionButton>
          </>
        )
      case 'movers':
        return (
          <>
            <LoadingAction onSubmit={() => linkNotificationToForm(data)}>
              Take action
            </LoadingAction>
            <TableActionButton
              onClick={e => {
                e.stopPropagation()
                handleMoverItemArchive(data.item_id, data.id)
              }}
            >
              Mark as done
            </TableActionButton>
          </>
        )
      case 'promotion':
        return (
          <LoadingAction onSubmit={() => linkNotificationToForm(data)}>
            Review
          </LoadingAction>
        )
      case 'group':
        return (
          <LoadingAction onSubmit={() => linkNotificationToForm(data)}>
            Recertify group
          </LoadingAction>
        )
      case 'succession_plans':
        return (
          <LoadingAction onSubmit={() => linkNotificationToForm(data)}>
            Take Action
          </LoadingAction>
        )
      default:
        return (
          <LoadingAction onSubmit={() => linkNotificationToForm(data)}>
            Take Action
          </LoadingAction>
        )
    }
  }

  const onCloseReject = () => {
    setRejectionComment('')
    setOpenReject(null)
  }

  const onReject = async () => {
    setIsRejectPending(true)

    try {
      openReject && (await openReject.request(openReject.data, true, rejectionComment))
    } finally {
      setIsRejectPending(false)
      onCloseReject()
    }
  }

  const handleCompleteTask = async () => {
    if (confirmationState?.data.task_id) {
      setConfirmationState({ ...confirmationState, loading: true })
      try {
        if (confirmationState.data.category === 'course_enrollment') {
          await completeCourseEnrolmentNotification(
            confirmationState.data.receiver_id,
            confirmationState.data.item_id,
          )
        } else {
          await completeTaskNotification(confirmationState.data.task_id)
        }
        onSuccess(confirmationState.data.id)
        setConfirmationState(null)
      } catch {
        setConfirmationState({ ...confirmationState, loading: false })
      }
    }
  }

  return (
    <>
      <Portal>
        <div onClick={e => e.stopPropagation()}>
          <ConfirmationDialog
            open={confirmationState != null}
            onClose={() => setConfirmationState(null)}
            onConfirm={handleCompleteTask}
            loading={confirmationState?.loading}
            onReject={() => setConfirmationState(null)}
            noMessage="Cancel"
            yesMessage="Confirm"
            label="Remove to do"
            body={
              confirmationState?.data.category === 'bulk_upload'
                ? 'This task will be removed from your to do list.'
                : 'If you have completed this task already it will be removed from your to do list, but if the task is not completed it will appear again in your to do list later on.'
            }
          />

          <ConfirmationDialog
            open={openReject != null}
            onClose={onCloseReject}
            onConfirm={onReject}
            loading={isRejectPending}
            onReject={onCloseReject}
            label={(openReject && openReject.confirmLabel) || 'Reasons for rejection'}
            body={
              <Input
                label="Add reason here"
                value={rejectionComment}
                onChange={e => setRejectionComment(e.currentTarget.value)}
              />
            }
            submitDisabled={!rejectionComment.length}
            yesMessage={(openReject && openReject.yesMessage) || 'Reject'}
            noMessage="Cancel"
          />
        </div>
      </Portal>
      {renderButtons()}
    </>
  )
}
