import { GetRequestData, GetRequestInterface, RequestInterfaceNew } from '@src/interfaces'
import { api, apiWithoutHandling } from '@src/api/index'
import { FetchDataQueryInterface } from '@src/interfaces/data'
import { AxiosPromise } from 'axios'
import { filterSortPageIntoQuery } from '@src/utils/table'
import {
  DocusignPreferencesInterface,
  GoogleCalendarPreferencesInterface,
  JiraConnectionTestInterface,
  JiraConnectionTestResult,
  JiraPreferencesInterface,
  SlackBotInterface,
  SlackPreferencesInterface,
} from '@src/interfaces/integrations'
import { useFetch, useUpdate } from '@src/utils/reactQuery'
import { API } from '@src/constants/api'

export const useGetSlackPreferences = () =>
  useFetch<SlackPreferencesInterface>(
    API.SLACK_INTEGRATION_PREFERENCES,
    undefined,
    undefined,
    true,
    {
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  )

export const useUpdatePreferences = () =>
  useUpdate<
    GetRequestData<SlackPreferencesInterface>,
    Partial<SlackPreferencesInterface>
  >(
    API.SLACK_INTEGRATION_PREFERENCES,
    undefined,
    undefined,
    false,
    (newData, oldData) => ({
      ...oldData,
      ...newData,
    }),
    undefined,
    false,
  )

export const getSlackIntegrationBots = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface): AxiosPromise<GetRequestInterface<SlackBotInterface>> =>
  api.get(`/slackIntegrationBots`, {
    params: filterSortPageIntoQuery(sortBy, filters, page),
  })

export const slackBotRequests: RequestInterfaceNew<SlackBotInterface> = {
  get: async ({ id }) => {
    const resp = await api.get<SlackBotInterface>(`/slackIntegrationBots/${id}`)
    const data = resp.data || {}
    return {
      ...resp,
      data: {
        ...data,
        access_token: '**********',
      },
    }
  },
  update: async (data, { id }) =>
    apiWithoutHandling.patch(`/slackIntegrationBots/${id}`, data),
  submit: async data => apiWithoutHandling.post('/slackIntegrationBots', data),
}

export const deleteSlackBot = (id: number) => api.delete(`/slackIntegrationBots/${id}`)

export const useGetJiraPreferences = () =>
  useFetch<JiraPreferencesInterface>(
    API.JIRA_INTEGRATION_PREFERENCES,
    undefined,
    undefined,
    true,
    {
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  )

export const jiraIntegrationTest = (data: JiraConnectionTestInterface) => {
  return apiWithoutHandling.post<JiraConnectionTestResult>(
    `${API.JIRA_INTEGRATION_PREFERENCES}/testConnection`,
    data,
  )
}

// show "fake" data in secret inputs
export const maskedJiraCredentials = {
  username: '**********',
  api_key: '**********',
}

export const jiraPreferencesRequests: RequestInterfaceNew<JiraPreferencesInterface> = {
  get: async ({ id }) => {
    const resp = await api.get<JiraPreferencesInterface>(
      `${API.JIRA_INTEGRATION_PREFERENCES}/${id}`,
    )
    const data = resp.data || {}
    return {
      ...resp,
      data: {
        ...data,
        ...maskedJiraCredentials,
      },
    }
  },
  update: async (data, { id }) => {
    const resp = await apiWithoutHandling.patch(
      `${API.JIRA_INTEGRATION_PREFERENCES}/${id}`,
      data,
    )
    const respData = resp.data || {}
    return {
      ...resp,
      data: {
        ...respData,
        ...maskedJiraCredentials,
      },
    }
  },
  submit: async data => apiWithoutHandling.post(API.JIRA_INTEGRATION_PREFERENCES, data),
}

export const googleCalendarRequests: RequestInterfaceNew<GoogleCalendarPreferencesInterface> =
  {
    get: async ({ id }) => {
      const resp = await api.get<GoogleCalendarPreferencesInterface>(
        `${API.GOOGLE_CALENDAR_INTEGRATION_PREFERENCES}/${id}`,
      )

      return {
        ...resp,
        data: { ...resp.data, credentials: '**********' },
      }
    },
    update: async (data, { id }) => {
      const resp = await apiWithoutHandling.patch(
        `${API.GOOGLE_CALENDAR_INTEGRATION_PREFERENCES}/${id}`,
        data,
      )
      return {
        ...resp,
        data: { ...resp.data, credentials: '**********' },
      }
    },
    submit: async data =>
      apiWithoutHandling.post(API.GOOGLE_CALENDAR_INTEGRATION_PREFERENCES, data),
  }

export const docuSignPreferencesRequests: RequestInterfaceNew<DocusignPreferencesInterface> =
  {
    get: async ({ id }) =>
      api.get<DocusignPreferencesInterface>(
        `${API.DOCUSIGN_INTEGRATION_PREFERENCES}/${id}`,
      ),
    update: async (data, { id }) =>
      apiWithoutHandling.patch(`${API.DOCUSIGN_INTEGRATION_PREFERENCES}/${id}`, data),
    submit: async data =>
      apiWithoutHandling.post(API.DOCUSIGN_INTEGRATION_PREFERENCES, data),
  }

export const useGetDocuSignPreferences = () =>
  useFetch<DocusignPreferencesInterface>(
    API.DOCUSIGN_INTEGRATION_PREFERENCES,
    undefined,
    undefined,
    true,
    {
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  )

export const getDocusignOAuthUrl = (data: DocusignPreferencesInterface) =>
  apiWithoutHandling.post<{ url: string | null }>(
    `${API.DOCUSIGN_INTEGRATION_PREFERENCES}/oauth`,
    data,
  )

export const useGetDocusignConsent = () =>
  useFetch<DocusignPreferencesInterface>(
    `${API.DOCUSIGN_INTEGRATION_PREFERENCES}/oauth/me`,
  )
